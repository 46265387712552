import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SecurityService } from '../security.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private securityService: SecurityService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.securityService.IsAuthorized) {
      return true;
    }
    //if (route.url[0].path == "restorepsw")
    //  return true;
    //else
    //{
      if (route.url[0].path == "")
        this.router.navigate(['/login']);
    //}
    return false;
  }

  //canActivate() {
  //  if (this.securityService.IsAuthorized) {
  //    return true;
  //  }
  //  this.router.navigate(['/login']);
  //  return false;
  //}

  //canLoad() {
  //  if (!this.securityService.IsAuthorized) {

  //    return true;

  //  }
  //}

  //this.router.navigate(['/restorepsw']);

  //return true;
}


