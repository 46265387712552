import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGRCCProduction } from '../app/Interfaces/icc';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { getLocationAssigned, GRLocationAssigned, IAddLocationProduction, IGRLocationListResponse, IGRLocationProductionResponse, IGRLocationProductionV2, IGRProductionLocation, IGRServiceLocation, IGRServiceLocationInCC, IGRServiceLocationInProduction, IlocationslistResponse, IPutServiceLocationV1, locationslist, putUserLocation } from '../app/Interfaces/ilocation';
import { IGRDataProduction, IPutDataProduction } from '../app/Interfaces/ipas';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class PASPrdService {
  private storage: any;
  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  //Delete data production in CC
  public DeleteDataProduction(PId: number) {
    let url = environment.urlAPI + 'api/pasprd/v1/dataproduction/';
    try {
      return this.dataService.delete<IgeneralResponse03>(url + PId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //get production data 
  public DataProduction(PCCId: number, PPASId: number) {
    let url = environment.urlAPI + 'api/pasprd/v1/dataproduction/';
    try {
      return this.dataService.get<IGRDataProduction>(url + PCCId + "/" + PPASId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //Put data production in CC
  public PutDataProduction(PDataProduction: IPutDataProduction) {
    let url = environment.urlAPI + 'api/pasprd/v1/dataproduction/';
    try {
      return this.dataService.put<IgeneralResponse03>(url, PDataProduction).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //add data production in CC
  public AddDataProduction(PDataProduction: IPutDataProduction) {
    let url = environment.urlAPI + 'api/pasprd/v1/dataproduction/';
    try {
      return this.dataService.post<IgeneralResponse03>(url, PDataProduction).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

}
