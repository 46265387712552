import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
//import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { DataTableService } from '../../../services/dataTable.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { ICAmodules, IcompanyAdd, ImoduleResponse, IPutFiscalData } from '../../Interfaces/icompany';
import { IdatatableResponse } from '../../Interfaces/idatatable';

@Component({
  selector: 'app-companyadd',
  templateUrl: './companyadd.component.html',
    styleUrls: ['./companyadd.component.css']
})

export class CompanyaddComponent implements OnInit {
  formCompanyadd: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  companyname: string = "";
  companyidn: string = "";
  Columns: string[] = [];
  Modules: ImoduleResponse[] = [];
  modulesList = null;
  fiscaltypelist: IdatatableResponse[];
  taxresponsibilitieslist: IdatatableResponse[];
  taxschemelist: IdatatableResponse[]


 constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
   private companyservice: CompanyService,
    private datatableservice: DataTableService,
    private _snackBar: MatSnackBar
  ) {
    this.formCompanyadd = formBuilder.group({
      idtype: ['N', Validators.required],
      idnumber: ['', Validators.required],
      name: ['', Validators.required],
      lastname:[''],
      telephone1: ['', Validators.required],
      address1: ['', Validators.required],
      email1: ['', Validators.required],
      alternatecode01 : [''],
      sysstatus: [true],
      fiscalType:  [2, Validators.required],
      taxResponsibilities: ['O-99'],
      taxScheme: ['ZZ'],
    })
    this.Columns = ['description', 'selected'];
  }
  selectedvalue: number; selectedview: string;
  idtypelist: IdatatableResponse[];

  ngOnInit() {
    //tipos de id
    this.idtypelist = [];
    this.datatableservice.getDataList("IDTYP").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.Addidtype({ id: entry.id, table: entry.table, code: entry.code, textvalue: entry.textvalue, numericvalue: entry.numericvalue, datevalue: entry.datevalue, boolvalue: entry.boolvalue });
        }
      }
    }, (e) => {
      console.log(e);
    });
    //person types
    this.fiscaltypelist = [];
    this.datatableservice.getDataList("PRTYP").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.fiscaltypelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //tax responsbilities
    this.taxresponsibilitieslist = [];
    this.datatableservice.getDataList("TAXRS").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.taxresponsibilitieslist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //tax schemes
    this.taxschemelist = [];
    this.datatableservice.getDataList("TAXSC").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.taxschemelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
  }


  Cancelar() {
    this.router.navigate(['/company']);
  }

  Grabar()
  {
    try {
      const activemodules: ICAmodules[] = [];
      const icompanyadd: IcompanyAdd = {
        idtype: this.formCompanyadd.value.idtype,
        idnumber: this.formCompanyadd.value.idnumber,
        name: this.formCompanyadd.value.name,
        lastname: this.formCompanyadd.value.lastname,
        telephone: this.formCompanyadd.value.telephone1,
        email: this.formCompanyadd.value.email1,
        address: this.formCompanyadd.value.address1,
        alternatecode01:this.formCompanyadd.value.alternatecode01,
        sysstatus: this.formCompanyadd.value.sysstatus ? "A" : "I",
        license: '',
        modules: activemodules,
      };
      this.companyservice.Registry(icompanyadd).subscribe(results => {
        if (results.body.isValid) {
          const oFiscalData: IPutFiscalData = {
            companyId: results.body.resultData.id,
            fiscalType: this.formCompanyadd.value.fiscalType,
            taxResponsibilities: this.formCompanyadd.value.taxResponsibilities,
            taxScheme: this.formCompanyadd.value.taxScheme
          }
          this.companyservice.PutFiscalData(oFiscalData).subscribe(results => {
            if (results.body.isValid) {
              this.openSB();
              this.router.navigate(['/company']);
            }
            else {
              alert(results.body.error.description);
            }
          },
            (e) => {
              this.mensaje = e.error;
              alert(e);
            });           
        }
        else alert(results.body.errors[0].description); 
      },
        (e) => {
          this.mensaje = e.error;
          console.log(e);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  Addidtype(V_idtype) {
    try {
      this.idtypelist.push(V_idtype);
    }
    catch (e) {
      console.log(e);
    }
  }


  addModule(module) {
    this.Modules.push(module);
  }


  hasError(nombreControl: string, validacion: string) {
    const control = this.formCompanyadd.get(nombreControl);
    return control.hasError(validacion);
  }

  seleccionar(item: { id: string })
  {
    if (item["selected"] == true)  item["selected"] = false; 
    else item["selected"] =true; 

  }

  openSB() {
    this._snackBar.open('Grabado','', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }
}
