import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
//import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataTableService } from '../../../services/dataTable.service';
import { ProviderService } from '../../../services/provider.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IdatatableResponse } from '../../Interfaces/idatatable';

@Component({
  selector: 'app-providerdetail',
  templateUrl: './providerdetail.component.html'
})

export class ProviderDetailComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  //customername: string = "";
  //providerId: string = ""
  idtypelist: IdatatableResponse[];
  persontypelist: IdatatableResponse[];
  departmentlist: IdatatableResponse[];
  citylist: IdatatableResponse[];
  citylistfilter: IdatatableResponse[];
  taxresponsibilitieslist: IdatatableResponse[];
  taxschemelist: IdatatableResponse[]

  constructor(
    private formBuilder: FormBuilder, 
    private securityService: SecurityService,
    private router: Router,
    private providerService: ProviderService,
    private datatableservice: DataTableService,
    private dialogRef: MatDialogRef<ProviderDetailComponent>,
    private _snackBar: MatSnackBar
  ) {
    this.formedit = formBuilder.group({
      idtype: ['N', Validators.required],
      idnumber: ['', [Validators.required, Validators.maxLength(20)]],
      name: ['', [Validators.required, Validators.maxLength(80)]],
lastname:['',[Validators.maxLength(80)]],
      address1: ['', [Validators.required, Validators.maxLength(100)]],
      telephone1: ['', [Validators.required, Validators.maxLength(20)]],
      telephone2: ['', Validators.maxLength(20)],
      email1: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      web1: ['', [Validators.maxLength(100)]],
      state: ['', Validators.required],
      city: ['', Validators.required],
      personType: ['L', Validators.required],
      taxResponsibilities: [''],
      taxScheme: [''],
      deliveryTerm: [''],
      active: true
    })
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    //Id types
    this.idtypelist = [];
    this.datatableservice.getDataList("IDTYP").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.idtypelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //Department list
    this.departmentlist = [];
    this.datatableservice.getDataList("DEPTO").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.departmentlist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //City list
    this.citylist = [];
    this.datatableservice.getDataList("CITY").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.citylist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //person types
    this.persontypelist = [];
    this.datatableservice.getDataList("PRTYP").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.persontypelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //tax responsbilities
    this.taxresponsibilitieslist = [];
    this.datatableservice.getDataList("TAXRS").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.taxresponsibilitieslist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //tax schemes
    this.taxschemelist = [];
    this.datatableservice.getDataList("TAXSC").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.taxschemelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    this.providerService.get(this.providerService.id).subscribe(results => {
      if (results.body.isValid) {
        //this.providerId = results.body.resultData.idTypeName + " " + results.body.resultData.idNumber;
        this.formedit.controls["idtype"].setValue(results.body.resultData.idType);
        this.formedit.controls["idnumber"].setValue(results.body.resultData.idNumber);
        this.formedit.controls['name'].setValue(results.body.resultData.name);
        this.formedit.controls['lastname'].setValue(results.body.resultData.lastName);
        this.formedit.controls['address1'].setValue(results.body.resultData.address1);
        this.formedit.controls['telephone1'].setValue(results.body.resultData.telephone1);
        this.formedit.controls['telephone2'].setValue(results.body.resultData.telephone2);
        this.formedit.controls['email1'].setValue(results.body.resultData.email1);
        this.formedit.controls['web1'].setValue(results.body.resultData.web1);
        this.formedit.controls['state'].setValue(results.body.resultData.stateAddress);
        this.formedit.controls['city'].setValue(results.body.resultData.cityAddress);
        this.formedit.controls['personType'].setValue(results.body.resultData.personType);
        this.formedit.controls['taxResponsibilities'].setValue(results.body.resultData.taxResponsibilities);
        this.formedit.controls['taxScheme'].setValue(results.body.resultData.taxScheme);
        this.formedit.controls['deliveryTerm'].setValue(results.body.resultData.deliveryTerm);
        this.formedit.controls['active'].setValue(results.body.resultData.status == 'A' ? true : false)
        this.formedit.disable();
      }
      else { this.mensaje = results.body.error.description; }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  Cancelar() {
    this.dialogRef.close();
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  seleccionar(item: { id: string })
  {
    //if (item.selected) {-		item	Object {code: "INVE", description: "INVENTARIO", selected: false, …}	Object

    //}		selected	false	boolean

    if (item["selected"] == true)  item["selected"] = false; 
    else item["selected"] =true; 

  }

  filtercity() {
    let _State = this.formedit.value.state.trim();
    let _Length = _State.trim().length;
    this.citylistfilter = this.citylist.filter(x => { return x.code.substring(0, _Length) === _State.trim() });
    return this.citylistfilter;
  }

  openSB() {
    this._snackBar.open('Grabado','', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg'] 
    });
  }


  b_back() {
    this.dialogRef.close();
  }
}
