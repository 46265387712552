import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { CustomerService } from '../../../../services/customer.service';
import { DataService } from '../../../../services/data.service';
import { RegistryService } from '../../../../services/registry.service';
import { iRegistryAdd } from '../../../Interfaces/iregistry';

@Component({
  selector: 'app-licenseadd',
  templateUrl: './licenseadd.component.html'
})
export class LicenseAddComponent implements OnInit {
  private sub: Subscription;
  formMain: FormGroup;
  //private _registryActivate: iRegistryActivate;
  private dataService: DataService;
  private mensaje: string="";

  constructor(private dialogRef: MatDialogRef<LicenseAddComponent>,
    private customerService: CustomerService,
    private registryService: RegistryService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
)
  {
    this.formMain = formBuilder.group({
      license: ['', Validators.required]
    });
  }

  ngOnInit(): void { this.loadContent(); }

  loadContent() {
    //this._registryActivate = { id: this.registryService.id, invoiceNumber: 0, providerId: this.registryService.providerId };
  }

  closeWithoutSave() {
    this.dialogRef.close();
  }

  Save() {
    try {
      this.registryService.ValidateFormat(this.formMain.value.license).subscribe(results => {
        if (results.body.isValid) {
          const _RA: iRegistryAdd = {
            license: this.formMain.value.license,
            customer_id: this.customerService.customerID
          };
          this.registryService.Add(_RA).subscribe(results => {
            if (results.body.isValid) {
              this.openSB(results.body.resultData.description);
              this.dialogRef.close(true);
            }
            else {
              alert(results.body.errors[0].description);
            }
          },
            (e) => {
              this.mensaje = e.error;
              alert(this.mensaje);
              console.log(e);
            }
          );
        }
        else {
          alert(results.body.error.description);
        }
      })  
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
