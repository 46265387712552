import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { DataTableService } from '../../../services/dataTable.service';
import { ModuleService } from '../../../services/module.service';
import { RouterService } from '../../../services/router.service';
import { SystemInternalService } from '../../../services/systemInternal.service';
import { Get_configV2, IconfigResponseV2, Put_config } from '../../Interfaces/iconfig';
import { ConfigTable } from '../../Interfaces/idatatable';
import { IgeneralResponse02 } from '../../Interfaces/igeneralresponse02';

interface CfgNode {
  description: string;
  type: string;
  boolvalue: boolean;
  stringvalue: string;
  numbervalue: number;
  datevalue: Date;
  listValueString: string;
  multipleValueString: string[];
  id: number;
  moduconfig_id: number;
  idexist: number;
  instructions: string;
  referenceTable: string;
  component: string;
  children?: CfgNode[];
}


/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  description: string;
  level: number;
  id: number;
  type: string;
  boolvalue: boolean;
  stringvalue: string;
  numbervalue: number;
  datevalue: Date;
  listValueString: string;
  multipleValueString: string[];
  moduconfig_id: number;
  idexist: number;
  referenceTable: string;
  component: string;
}


@Injectable()
export class FileDatabase {
}

@Component({
  selector: 'app-compcfg',
  templateUrl: './compcfg.component.html'
})

export class CompcfgComponent {
  formFunction: FormGroup;
  private _transformer = (node: CfgNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      description: node.description,
      id: node.id,
      type: node.type,
      boolvalue: node.boolvalue,
      stringvalue: node.stringvalue,
      numbervalue: node.numbervalue,
      datevalue: node.datevalue,
      listValueString: node.listValueString,
      multipleValueString: node.multipleValueString,
      moduconfig_id: node.moduconfig_id,
      idexist: node.idexist,
      referenceTable: node.referenceTable,
      component: node.component,
      level: level,
      instructions: node.instructions
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );


  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  constructor(
    private formBuilder: FormBuilder,
    private companyservice: CompanyService,
    private dataService: DataService,
    private systemInternal: SystemInternalService,
    private moduleService: ModuleService,
    private router: Router,
    private routerService: RouterService,
    private dataTableService: DataTableService,
    private _snackBar: MatSnackBar) {
    this.formFunction = formBuilder.group({
      boolvalue: [true],
      numbervalue: [0],
      stringvalue: [''],
      listValueString: [''],
      multipleValueString: ['']
    });
    this.initialize();
  }
  mensaje: string;
  Configurations: CfgNode[] = [];
  companyname: string;
  r_type: string;
  r_text: string;
  r_id: number;
  r_instructions: string = "";
  listValues: ConfigTable[] = [];
  listValueFilter: ConfigTable[] = []


  initialize() {
    this.mensaje = "";
    this.companyname = this.companyservice.companyName;
    //consultar los permisos
    this.Configurations = [];
    const gf: Get_configV2 = {
      companyId: this.companyservice.companyID,
      level: 1,
      id: 0,
      updateid: 0
    };
    this.systemInternal.GetConfigurationV2(gf).subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.Configurations.push(entry);
        }
        for (const entry of this.Configurations) {
          this.ParseMultipleData(entry);
        }
        for (const entry of results.body.resultData) {
          this.GetDataTable(entry);
        }
        this.dataSource.data = this.Configurations; // results.body.resultData; //   this.accounts; // TREE_DATA; //JSON.parse(td);//
      }
      else {
        alert(results.body.error.description); this.mensaje = results.body.error.description;
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  ParseMultipleData(PNode: CfgNode) {
    if (PNode.component == "MULTIPLE") {
      let _values: string[] = PNode.stringvalue.split(",");
      PNode.multipleValueString = _values;
    }
    if (PNode.children != null) {
      for (const entry of PNode.children) {
        this.ParseMultipleData(entry);
      }
    }
  }

  GetConf(entry) {
    this.Configurations.push(entry);
    if (entry.component == "MULTIPLE") {
      let _values: string[] = entry.value.split(",");
      var index = this.Configurations.find(x => x.id == entry.id);
      if (index != null) {
        index.multipleValueString = _values;
      }
    }
  }

  GetDataTable(entry: IconfigResponseV2) {
    if (entry.referenceTable.trim().length > 0) {
      entry.listValueString = entry.stringvalue;
      switch (entry.referenceTable) {
        case "MODUL":
          this.moduleService.GetTableModulesV1().subscribe(_res => {
            if (_res.body.isValid) {
              for (const entrytable of _res.body.resultData) {
                this.listValues.push({ table: entrytable.table, name: entrytable.textvalue, code: entrytable.code });
              }
            }
          })
          break;
        default:
          this.dataTableService.getDataListV2(entry.referenceTable).subscribe(_res => {
            if (_res.body.isValid) {
              for (const entrytable of _res.body.resultData) {
                this.listValues.push({ table: entrytable.table, name: entrytable.textvalue, code: entrytable.code });
              }
            }
          })
          break;
      }
    }
    if (entry.children != null) {
      for (const _child of entry.children) {
        this.GetDataTable(_child);
      }
    }
  }


  b_back() {
    this.router.navigate([this.routerService.routerParent01]);
  }

  Save() {
    try {
      const conf: Put_config[] = [];
      for (const entry of this.dataSource._flattenedData.value) {
        switch (entry.type) {
          case "B":
            conf.push({ companyId: this.companyservice.companyID, id: entry.id, idexist: entry.idexist, moduconfig_id: entry.moduconfig_id, value: (entry.boolvalue ? "true" : "false") });
            break;
          case "N":
            conf.push({ companyId: this.companyservice.companyID, id: entry.id, idexist: entry.idexist, moduconfig_id: entry.moduconfig_id, value: entry.numbervalue.toString() });
            break;
          case "S":
            if (entry.referenceTable.trim().length > 0) {
              if (entry.component == "MULTIPLE") {
                conf.push({ companyId: this.companyservice.companyID, id: entry.id, idexist: entry.idexist, moduconfig_id: entry.moduconfig_id, value: entry.multipleValueString.toString() });
              }
              else {
                conf.push({ companyId: this.companyservice.companyID, id: entry.id, idexist: entry.idexist, moduconfig_id: entry.moduconfig_id, value: entry.listValueString.toString() });
              }
            }
            else {
              conf.push({ companyId: this.companyservice.companyID, id: entry.id, idexist: entry.idexist, moduconfig_id: entry.moduconfig_id, value: entry.stringvalue.toString() });
            }
            break;
        }
      }
      const url = environment.urlAPI + 'api/configuration';
      this.dataService.put<IgeneralResponse02>(url, conf).subscribe(results => {
        if (results.body.isValid) {
          this.openSB(results.body.resultData.description);
          this.b_back();
        //  this.router.navigate(['company']);
        }
        else { alert("Error"); }
      },
        (e) => {
          alert(e.error);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  onClick(node) {
    try {
      this.r_id = node.id;
      this.r_type = node.type + node.component;
      this.r_text = node.description;
      this.r_instructions = (node.instructions != null && node.instructions != "" ? node.instructions : (node.type == "G" ? "Seleccione alguna de las opciones de " + node.description.trim() : ""));
      this.formFunction.controls['boolvalue'].setValue(node.boolvalue);
      this.formFunction.controls['numbervalue'].setValue(node.numbervalue);
      this.formFunction.controls['stringvalue'].setValue(node.stringvalue);
      this.formFunction.controls['listValueString'].setValue(node.listValueString);
      this.formFunction.controls['multipleValueString'].setValue(node.multipleValueString);

      if ((node.type == "S") && node.referenceTable.trim().length > 0) {
        this.listValueFilter = this.listValues.filter(x => x.table == node.referenceTable.trim());
      }
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  onChange(getId, xnode) {
    try {
      var index: number = this.dataSource._flattenedData.value.indexOf(this.dataSource._flattenedData.value.find(x => x.id == getId));
      if (index >= 0) {
        switch (this.dataSource._flattenedData.value[index].type) {
          case "B":
            this.dataSource._flattenedData.value[index].boolvalue = this.formFunction.controls['boolvalue'].value;
            break;
          case "N":
            this.dataSource._flattenedData.value[index].numbervalue = this.formFunction.controls['numbervalue'].value;
            break;
          case "S":
            if (this.dataSource._flattenedData.value[index].referenceTable == "")
              this.dataSource._flattenedData.value[index].stringvalue = this.formFunction.controls['stringvalue'].value;
            else {
              if (this.dataSource._flattenedData.value[index].component == "MULTIPLE") {
                this.dataSource._flattenedData.value[index].multipleValueString = [];
                let _index = 0;
                for (const entry of this.formFunction.controls['multipleValueString'].value) {
                  this.dataSource._flattenedData.value[index].multipleValueString.push(entry);
                  _index++;
                }
              }
              else {
                this.dataSource._flattenedData.value[index].listValueString = this.formFunction.controls['listValueString'].value;
              }
            }
            break;
        }
      }
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  searchNode(id) {
    var node = this.dataSource.data.find(x => x.id == id);
  }

}
