import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { grWiki } from '../app/Interfaces/igeneralresponse02';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable({ providedIn: 'root' })
export class WikiService {
  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  getWikis(p_UserId:number) {
    try {
      let url = this.baseUrl + 'api/wiki/v1/list/';
      return this.dataService.get<grWiki>(url+p_UserId.toString()).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

}
