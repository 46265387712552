import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { putAccount,accountBasicList, accountGetReport, accountResponse, GRAccount, GRChildAccount, GRListAccountAccount, GRListGroupAccount, GRListGroupByAccount, GRParentAccounts, accountLoad } from '../app/Interfaces/iaccounting';
import { graccountlist, IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { GRReport } from '../app/Interfaces/iReport';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class AccountingService {

  accountId: number;
  parentAccountId: number;
  private baseUrl = environment.urlAPI;


  constructor(private http: HttpClient, private dataService: DataService) { }


  putValues(p_AccountId: number, p_ParentAccountId: number) {
    this.accountId = p_AccountId;
    this.parentAccountId = p_ParentAccountId;
  }

  Import() {
    try {
      let url = this.baseUrl + 'api/accounting/v1/import/';
      return this.dataService.get<IgeneralResponse03>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  putAccount(p_PA:putAccount) {
    try {
      let url = this.baseUrl + 'api/accounting/v1/account/';
      return this.dataService.post<IgeneralResponse03>(url, p_PA)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getAccountList(PCompanyId:number) {
    try {
      const cp: accountLoad = {
        companyId: PCompanyId
      };
      let url = environment.urlAPI + 'api/accounting/list/v1';
      return this.dataService.post<graccountlist>(url, cp).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getParentAccountsInclusive(p_AccountId: number) {
    try {
      let url = this.baseUrl + 'api/accounting/parentsinclusive/';
      return this.dataService.get<GRParentAccounts>(url + p_AccountId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getParentAccounts(p_AccountId:number) {
    try {
      let url = this.baseUrl + 'api/accounting/parents/';
      return this.dataService.get<GRParentAccounts>(url+p_AccountId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getListGroupByAccount(p_AccountId:number) {
    try {
      let url = this.baseUrl + 'api/accounting/groupby/';
      return this.dataService.get<GRListGroupByAccount>(url+p_AccountId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getListAccountAccount() {
    try {
      let url = this.baseUrl + 'api/accounting/account/';
      return this.dataService.get<GRListAccountAccount>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getListGroupAccount() {
    try {
      let url = this.baseUrl + 'api/accounting/group/';
      return this.dataService.get<GRListGroupAccount>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getAccount(p_AccountId: number) {
    try {
      let url = this.baseUrl + 'api/accounting/account/';
      return this.dataService.get<accountResponse>(url + p_AccountId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getChildAccountFeatures(p_AccountId: number) {
    try {
      let url = this.baseUrl + 'api/accounting/childfeatures/';
      return this.dataService.get<GRChildAccount>(url + p_AccountId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getAccountFeatures(p_AccountId: number) {
    try {
      let url = this.baseUrl + 'api/accounting/features/';
      return this.dataService.get<GRAccount>(url + p_AccountId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getBasicList(p_AccountId: number) {
    try {
      let url = this.baseUrl + 'api/accounting/basiclist/';
      return this.dataService.get<accountBasicList[]>(url +p_AccountId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getAccountingRep(p_Par:accountGetReport) {
    try {
      let url = this.baseUrl + 'api/accounting/report/';
      return this.dataService.put<GRReport>(url, p_Par)
        .pipe(res => {
          return res
        });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getAccountingCsv(p_Par: accountGetReport) {
    let url = this.baseUrl + 'api/accounting/report/' + p_Par.ccId + '/' + p_Par.accountId;
    return this.http.get(url, { reportProgress: true, observe: 'events', responseType: 'blob' });
  }
}
