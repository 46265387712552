import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../services/company.service';
import { PermissionsService } from '../../services/permissions.service';
import { RouterService } from '../../services/router.service';
import { ErrorStateMatcher1 } from '../error-state-matcher1';
import { IcompanyResponse } from '../Interfaces/icompany';
import { IuserPermissionsResponse } from '../Interfaces/iuser';


@Component({
  selector: 'app-mycompany',
  templateUrl: './mycompany.component.html'
})


export class MyCompanyComponent implements OnInit {
  formData: FormGroup;
  subRef$: Subscription;
  public companyId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  Companies: IcompanyResponse[] = [];
  Permissions: IuserPermissionsResponse[] = [];
  permissionsList = null;
  MenuG: IuserPermissionsResponse[] = [];
  menuGList = new MatTableDataSource;
  dataList = new MatTableDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  //ngAfterViewInit() {
  //}

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    //private dataService: DataService,
    private permissionService: PermissionsService,
    //private securityService: SecurityService,
    private companyservice: CompanyService,
    private routeparent: RouterService
  ) {
    this.formData = formBuilder.group({
documentId:'',
      telephone1: '',
      address1: '', //[(this.companyservice.idNumber != null ? this.companyservice.idNumber:"")]
      email1: '',
      alternateCode01: '',
sysStatus:[false]
    });
  }
  _companyName: string = "";
  companyIdN: string = "";
  _companyId: number = 0;


  ngOnInit() {
    this.routeparent.putRouter01("mycompany");
    this.mensaje = "";
    //permisos 
    this.Permissions = [];
    this.MenuG = [];
    //permisos para company
    this.permissionService.getPermission("MYCOMPANY").subscribe(results => {
      for (const entry of results.body) {
        this.addPermission({
          id: entry.id, function_code: entry.function_code, code: entry.code,
          description: entry.description, type: entry.type, icon: entry.icon, link: entry.link, toolTip:entry.toolTip
        });
      }
    },
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
        console.log(e);
      });

    //permisos para usuario
    this.permissionService.getPermission("USER").subscribe(results => {
      for (const entry of results.body) {
        if (entry.code == "E") //cualquier otro permiso
        {
          this.addPermission({
            id: entry.id, function_code: entry.function_code, code: entry.code,
            description: "USUARIOS", type: 'G', icon: entry.icon, link: entry.link, toolTip: entry.toolTip
          });
          break;
        }
      }
    },
      (e) => {
        if (e.status != 404) {
          this.mensaje = e.error;
          alert(e.error);
          console.log(e);
        }
      });
    //permisos para cc
    this.permissionService.getPermission("CC").subscribe(results => {
      for (const entry of results.body) {
        if (entry.code == "E") //cualquier otro permiso
        {
          this.addPermission({
            id: entry.id, function_code: entry.function_code, code: entry.code,
            description: "CENTROS DE COSTO", type: 'G', icon: entry.icon, link: entry.link, toolTip: entry.toolTip
          });
          break;
        }
      }
    },
      (e) => {
        if (e.status != 404) {
          this.mensaje = e.error;
          alert(e.error);
          console.log(e);
        }
      });
    //permisos para compmore (more options)
    this.permissionService.getPermission("COMPMORE").subscribe(results => {
      for (const entry of results.body) {
        if (entry.code == "E") 
        {
          this.addPermission({
            id: entry.id, function_code: entry.function_code, code: entry.code,
            description: "MAS OPCIONES", type: 'G', icon: entry.icon, link: entry.link, toolTip: entry.toolTip
          });
          break;
        }
      }
    },
      (e) => {
        if (e.status != 404) {
          this.mensaje = e.error;
          alert(e.error);
          console.log(e);
        }
      });
    this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
    this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);

    this.mensaje = "";
    this.companyservice.GetMyCompany(this._companyId).subscribe(results => {
      if (results.body.isValid) {
        this._companyId = results.body.resultData.id;
        this._companyName = results.body.resultData.name;
        this.companyIdN = results.body.resultData.idtypename;
        this.formData.controls['documentId'].setValue(results.body.resultData.idtypename);
        this.formData.controls['telephone1'].setValue(results.body.resultData.tele1);
        this.formData.controls['email1'].setValue(results.body.resultData.email1);
        this.formData.controls['address1'].setValue(results.body.resultData.address1);
        this.formData.controls['alternateCode01'].setValue(results.body.resultData.alternateCode01);
        this.formData.controls['sysStatus'].setValue(results.body.resultData.sysstatus == "A" ? true : false);
        this.formData.disable();

      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }


  b_more(id, name) {
    this.companyservice.putValues(id, name, true);
    this.router.navigate(['compmore']);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
    }
  }

  b_gen(link, id) {
    this.companyservice.putValues(this._companyId,this._companyName, true);

    this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate(['home']);
  }

}
