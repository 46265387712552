import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GRReport } from '../app/Interfaces/iReport';
import { GetStockValued } from '../app/Interfaces/iStock';
import { environment } from '../environments/environment';
import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class StockService {
  //private storage: any;

  customerID: number = 0;
  customerName: string = "";
  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  //public putValues(customerid, customername) {
  //  this.customerID = customerid;
  //  this.customerName = customername;
  //}

  getStockValued(p_Par: GetStockValued ) {
    try {
      let url = this.baseUrl + 'api/pas/stockvalued/report';
      return this.dataService.put<GRReport>(url,p_Par)
        .pipe(res => {
          return res
        });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getStockValuedCsv(p_Par: GetStockValued) {
    try
    {
      let url = this.baseUrl + 'api/pas/stockvalued/csv/' + p_Par.CCId + '/' + p_Par.Name + '/' + p_Par.Code + '/' + p_Par.StockStatus;
      return this.http.get(url, { reportProgress: true, observe: 'events', responseType: 'blob' });
    }
    catch (e) {
      console.log("Error");
    }
  }

}
