import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';

@Component({
  selector: 'app-requestrestorepsw',
  templateUrl: './requestrestorepsw.component.html'
})

export class RequestRestorePswComponent implements OnInit {
  IsAuthenticated = false;
  formPswRestore: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  msgtype: string = "";
  //key: string = "";

  constructor(
    private formBuilder: FormBuilder, 
    //private securityService: SecurityService,
    private router: Router,
    //private securityParams: ActivatedRoute,
    private userservice: UserService,
    private _snackBar: MatSnackBar
  ) {
    this.formPswRestore = formBuilder.group({
      userName: [null, [Validators.required, Validators.email]]
    });
  }


  ngOnInit() {
    //this.key = this.securityParams.snapshot.params.key;
    this.mensaje = "Ingrese su email para confirmar esta solicitud.";
    this.msgtype = "msg-notify"
  }

  Send()
  {
      try {
        this.userservice.ValidateEmail(this.formPswRestore.value.userName.trim().toLowerCase()).subscribe(result => {
          if (result.body.isValid) {
            this.userservice.SendEmailRestorePswV2(result.body.resultData.id, this.formPswRestore.value.userName.trim().toLowerCase()).subscribe(_resultemail => {
              if (_resultemail.body.isValid) {
                this.mensaje = result.body.resultData.description + ", revise su correo."
                //this.openSB("Petición realizada, revise su correo.")
                this.router.navigate(["keypsw"]);
              }
              else {
                alert(_resultemail.body.error.description);
                this.router.navigate(["login"]);
              }
            })
          }
          else {
            alert(result.body.error.description);
          }
        },
          (e) => {
            alert(e.error);
          });
      }
      catch (e) {
        console.log(e.message);
        alert(e.message);
      }
  }

  Cancel()
  {
    this.router.navigate(["login"]);

  }



  hasError(nombreControl: string, validacion: string) {
    const control = this.formPswRestore.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


}
