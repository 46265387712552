import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { GRPaymMeth, GRPaymMethClass, GRPaymMethCode, GRPaymMethId, GRPaymMethList, PaymMethId } from '../app/Interfaces/ipaymmeth';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class PaymMethService {

  id: number;
  name: string;
  code: string;
  private baseUrl = environment.urlAPI;


  constructor(private http: HttpClient, private dataService: DataService) { }

  putValues(PId:number,PCode:string, PName:string) {
    this.id = PId;this.code=PCode, this.name = PName;
  }

  //add payment method 
  add(PP: PaymMethId) {
    try {
      let url = this.baseUrl + 'api/paymmeth/v1/';
      return this.dataService.post<IgeneralResponse03>(url, PP)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  //get next code
  getAvailableCode() {
    try {
      let url = this.baseUrl + 'api/paymmeth/availablecode/';
      return this.dataService.get<GRPaymMethCode>(url).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  //get payment method by id
  update(PP:PaymMethId) {
    try {
      let url = this.baseUrl + 'api/paymmeth/v1/update/';
      return this.dataService.post<IgeneralResponse03>(url, PP)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  //get payment method by id
  getPaymMethId(PId:number) {
    try {
      let url = this.baseUrl + 'api/paymmeth/v1/id/';
      return this.dataService.get<GRPaymMethId>(url + PId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  import() {
    try {
      let url = this.baseUrl + 'api/paymmeth/v1/import/';
      return this.dataService.get<IgeneralResponse03>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getClass() {
    try {
      let url = this.baseUrl + 'api/paymmeth/v1/class/';
      return this.dataService.get<GRPaymMethClass>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getPaymMeth() {
    try {
      let url = this.baseUrl + 'api/paymmeth/v1/';
      return this.dataService.get<GRPaymMeth>(url+"0")
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getPaymMethList(p_Status: string) {
    try {
      let url = this.baseUrl + 'api/paymmeth/v1/list/';
      return this.dataService.get < GRPaymMethList>(url +p_Status)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

}
