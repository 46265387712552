import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { CustomerService } from '../../services/customer.service';
import { DataService } from '../../services/data.service';
import { RouterService } from '../../services/router.service';
import { Imenu01Response } from '../Interfaces/imenu01';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  color: string;
  cols: number;
  rows: number;
  class: string;
}

export const ROUTES: RouteInfo[] = [];

@Component({
  selector: 'informes-data',
  templateUrl: './informes.component.html',
    styleUrls: ['./informes.component.css']
})

export class InformesComponent {
  constructor(
    private dataService: DataService,
    private router: Router,
    private customerservice: CustomerService,
    private routeparent: RouterService
  ) { }

  menuItems: any[];
  functions: RouteInfo[];


  async ngOnInit() {
    //release customer service data in order to those reports will be generated including all customers
    this.customerservice.putValues(0, "");
    this.routeparent.putRouter("informes");

    const url = environment.urlAPI + 'api/menu/n2/';
    const userId = localStorage.getItem('userId');
    let ROUTES = [];
    this.functions = ROUTES;
    this.menuItems = [];
    this.dataService.get<Imenu01Response[]>(url + userId + '/REPORTS').subscribe(results => {
      for (const entry of results.body) {
        this.addRoutes({ path: '/' + entry.link.trim().toLowerCase(), title: entry.description.trim(), icon: entry.icon, color: '#A51955', cols: 1, rows: 1,class:entry.function_class });
      }
      this.menuItems = this.functions.filter(menuItem => menuItem);
    },
      (e) => {
        console.log(e);
      });
    this.menuItems = this.functions.filter(menuItem => menuItem);


  }

  addRoutes(route) {
    this.functions.push(route);
  }

  b_back() {
    this.router.navigate(['home']);
  }

}






