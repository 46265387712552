import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomerService } from '../../../services/customer.service';
import { DataService } from '../../../services/data.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { grelectronicdocuments01 } from '../../Interfaces/igeneralresponse02';
import { electronicdocuments01, get_electronicdocuments01 } from '../../Interfaces/iinvoice';
import { Token_GET, Token_PUT } from '../../Interfaces/itoken';

@Component({
  selector: 'app-elecdocu01',
  templateUrl: './elecdocu01.component.html',
    styleUrls: ['./elecdocu01.component.css']
})

export class elecdocu01Component implements OnInit {

  formReport: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  DSdocs = null;  //data source
  tokensaphety: string;

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private router: Router,
    private customerservice: CustomerService
  ) {
    this.formReport = formBuilder.group({
      date: ['', Validators.required],
      enddate: ['', Validators.required]
    });
    this.Columns = ['type', 'inout','quantity'];
  }
  edrelation: electronicdocuments01[];
  selectedvalue: number; selectedview: string;
  r_documents: 0;
  secondtitle: string;

  ngOnInit() {
    this.secondtitle = this.customerservice.customerName;
  }

  Filter() {
    const gts: Token_PUT = { 
      username: "direccionsoftware@gruposigma.com.co",
      password: "12345*calo5%%",
      virtual_operator: "gruposigma",
    };

    this.dataService.post<Token_GET>(environment.urlAPI + 'api/saphety/token/v1/', gts).subscribe(results => {
      if (results.body.isValid )
      {
        this.tokensaphety = results.body.resultData.access_token;
        this.mensaje = "";
        this.edrelation = [];
        this.r_documents = 0;
        const ged01: get_electronicdocuments01 = {
          date: this.formReport.value.date,
          token: this.tokensaphety,
          companyId: this.customerservice.customerID,
          endDate:this.formReport.value.enddate,
        };
        this.dataService.post<grelectronicdocuments01>(environment.urlAPI + 'api/saphety/electronicdocuments/v1/', ged01).subscribe(results => {
          if (!results.body.isValid)
            //if (results.body.resultCode == 200)
            this.mensaje = "No hay datos";
          for (const entry of results.body.resultData) {
            this.addDocument({
              type: entry.type,inout:entry.inout, quantity: entry.quantity
            });
          }
          this.DSdocs = new MatTableDataSource<electronicdocuments01>(this.edrelation);
          this.r_documents = this.DSdocs.data.length;
        },
          (e) => {
            this.mensaje = e.error;
            console.log(e);
          });
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });


  }

  addDocument(dco) {
      this.edrelation.push(dco);
  }


  hasError(nombreControl: string, validacion: string) {
    const control = this.formReport.get(nombreControl);
    return control.hasError(validacion);
  }

  getTotalquantity() {
    return this.DSdocs.data.map(t => t.quantity).reduce((acc,quantity) => acc +quantity, 0);
  }

  b_back() {
    if (this.customerservice.customerID==0)
      this.router.navigate(['informes']);
    else
      this.router.navigate(['custmore']);
  }



}
