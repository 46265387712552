import { Component } from '@angular/core';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CcService } from '../../../services/cc.service';
import { RouterService } from '../../../services/router.service';
import { ICCV3 } from '../../Interfaces/icc';

@Component({
  selector: 'ccselectv2-data',
  templateUrl: './ccselectv2.component.html'
})

export class CCSelectV2Component {
  constructor(
    private router: Router,
    private securityParams: ActivatedRoute,
    private ccService: CcService,
    private _snackBar: MatSnackBar,
    private routerService: RouterService
  ) {
  }
  menuCC: any[];
  cc: ICCV3[];
  ccId: number;
  centerName: string;
  message: string = "";
  //MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;
  path: string = "";

  async ngOnInit() {
    //this.path = this.securityParams.snapshot.params.path.trim();

    this.path = this.securityParams.snapshot.params['path'].trim();
    //this.MenuG = [];
    this.cc = [];
    this.menuCC = [];
    this.loadCC();
  }

  ngOnDestroy(): void {
  }

  private async loadCC() {
    this.cc = [];
    this.menuCC = [];
    this.ccService.getCCListV3().subscribe(result => {
      if (result.body.isValid) {
        for (const entry of result.body.resultData) {
          this.addCC({
            id:entry.id, name: entry.name.substring(0, 25).trim(),code:entry.code, path:this.path+"/"+entry.id, icon:"location_on",function_class:entry.function_class
          });
        }
        this.menuCC = this.cc.filter(menuCC => menuCC);
      }
      else {
        this.message = result.body.error.description;
      }
    },
      (e) => {
        console.log(e);
      });
  }

  b_click(PPath) {
    this.router.navigate([PPath])
  }

  addCC(route) {
    this.cc.push(route);
  }


  b_back() {
    this.router.navigate([this.routerService.routerParent]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }
}






