import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppTransferListService {

  fromCC: number = 0;
  toCC: number = 0;
  startDate: Date;
  endDate: Date;
  status: string = "";
  currentPage: number = 0;

  constructor() { }

  public putValues(pFromCC: number, pToCC: number, pStartDate: Date, pEndDate: Date, pStatus: string, pCurrentPage: number) {
    this.fromCC = pFromCC;
    this.toCC = pToCC;
    this.startDate = pStartDate;
    this.endDate = pEndDate;
    this.status = pStatus;
    this.currentPage = pCurrentPage;
  }

}
