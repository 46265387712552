import { Component, ElementRef, enableProdMode, HostListener, OnDestroy, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataService } from '../../services/data.service';
import { SecurityService } from '../../services/security.service';
import { ErrorStateMatcher1 } from '../error-state-matcher1';
import { Ilogin, IloginResponse } from '../Interfaces/ilogin';
//import * as jwt_decode from 'jwt-decode';

if (environment.production) {
  enableProdMode();

    }

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy   {
  formLogin: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  scrHeight: any;
  scrWidth: any;
  mensaje: string;
  msgtype: string;
  _renderer: Renderer2;


  // HostListener: https://angular.io/api/core/HostListener
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    //console.log(this.scrHeight, this.scrWidth);
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
     private dataService: DataService,
    private securityService: SecurityService,
    private rendererFactory:RendererFactory2
  ) {
    this.getScreenSize();
    this.securityService.LogOff();
    this._renderer = rendererFactory.createRenderer('body', null);
    this.formLogin = formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
      //captcha:['',Validators.required]
    });
  }

  ngOnDestroy() {
    if (this.subRef$) {
      this.subRef$.unsubscribe();
    }
  }

  ngOnInit() {
    //this.ele
    //var html = document.documentElement;
//var body=document.body
  //  html.style.backgroundImage = body.style.backgroundImage = "img/gsf01.jpg";
    this._renderer.addClass(document.body, 'body-login-01');
  }

  Login() {
    const usuarioLogin: Ilogin = {
      email: this.formLogin.value.email,
      password: this.formLogin.value.password,
    };
    var html = document.documentElement;
    var body = document.body
    html.style.backgroundImage = body.style.backgroundImage = "img/gsf01.jpg";

    this.mensaje = "";
    this.msgtype = "msg-alert";
    const url = environment.urlAPI + 'api/identidad/login';
    //console.log(environment.urlAPI);
    this.subRef$ = this.dataService.post<IloginResponse>(url, usuarioLogin)
      .subscribe(res => {
        if (res.body.isValid) {
          this.mensaje = "validando...";
          const token = res.body.resultData.access_token;
          const userid = res.body.resultData.id;
          const username = res.body.resultData.name;
          const sysusername = res.body.resultData.username;
          const profile = res.body.resultData.profile;
          const cc_id = res.body.resultData.cc_id;
          const companyid = res.body.resultData.companyid;
          const companyname = res.body.resultData.companyname;
          const expires = res.body.resultData.expires;
          //const newUser = jwt_decode(tokenInfo.authToken).newUser;
          localStorage.setItem('userId', userid);
          localStorage.setItem('userName', username);
          localStorage.setItem('sysUserName', sysusername);
          localStorage.setItem('profile', profile);
          localStorage.setItem('cc_id', cc_id);
          localStorage.setItem('companyId', companyid);
          localStorage.setItem('companyName', companyname);
          //localStorage.setItem('expires',expires.toDateString());
          this.securityService.SetAuthData(token); 
          this.router.navigate(['/home']);
        }
        else {
          this.mensaje =res.body.error[0].description;

        }

      },
        err => {
          this.mensaje = err; // "Usuario no válido";
          console.log('Error en login', err);
        });
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formLogin.get(nombreControl);
    return control.hasError(validacion);
  }


}
