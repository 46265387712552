import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataService } from '../../../services/data.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { UserService } from '../../../services/user.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IdatatableResponse } from '../../Interfaces/idatatable';
import { IResponse } from '../../Interfaces/iresponse';
import { IuserAdd, IuserEditResponse, IuserEdit } from '../../Interfaces/iuser';

@Component({
  selector: 'app-useredit',
  templateUrl: './useredit.component.html'
})

export class UsereditComponent implements OnInit {
  formUseredit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  useridname: string = "";

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private securityService: SecurityService,
    private routerparent: RouterService,
    private router: Router,
    private userservice: UserService,
    private _snackBar: MatSnackBar
  ) {
    this.formUseredit = formBuilder.group({
      //idtype: ['C'],
      //idnumber: ['', Validators.required],
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      telefono1: ['', Validators.required],
      email1: ['', Validators.required],
      profile: ['A', Validators.required],
      activo: [true] // new FormControl(false, [])
    });
  }
  selectedvalue: number; selectedview: string;
  idtypelist: IdatatableResponse[];
  profilelist: IdatatableResponse[];

  ngOnInit() {
    this.mensaje = "";
    this.idtypelist = [];
    let url = environment.urlAPI + 'api/datatable/';
    this.profilelist = [];
    this.dataService.get<IdatatableResponse[]>(url + "PROFL").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.Addprofile({ id: entry.id, table: entry.table, code: entry.code, textvalue: entry.textvalue, numericvalue: entry.numericvalue, datevalue: entry.datevalue, boolvalue: entry.boolvalue });
        }
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    //consultar el usuario
    url = environment.urlAPI + 'api/user/';
    this.dataService.get<IuserEditResponse>(url +this.userservice.userID).subscribe(results => {
      if (results.ok) {
        this.useridname = results.body[0].idtypename;
        this.formUseredit.controls['name'].setValue(results.body[0].name);
        this.formUseredit.controls['lastname'].setValue(results.body[0].lastname);
        this.formUseredit.controls['telefono1'].setValue(results.body[0].tele1);
        this.formUseredit.controls['email1'].setValue(results.body[0].email1);
        this.formUseredit.controls['activo'].setValue(results.body[0].sysstatus=="A"?true:false);
        this.formUseredit.controls['profile'].setValue(results.body[0].profile);
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  Addprofile(V_profile) {
    try {
      this.profilelist.push(V_profile);
    }
    catch (e) {
      console.log(e);
    }
  }

  Grabar()
  {
    try {
      const iuseredit: IuserEdit = {
        id:this.userservice.userID,
        name: this.formUseredit.value.name,
        lastname: this.formUseredit.value.lastname,
        telephone1: this.formUseredit.value.telefono1,
        email1: this.formUseredit.value.email1,
        profile: this.formUseredit.value.profile,
        sysstatus: this.formUseredit.value.activo ? "A" : "I",
        cc_id:0
      };
      const url = environment.urlAPI + 'api/user/'+this.userservice.userID.toString;
      this.dataService.put<IResponse>(url,iuseredit).subscribe(results => {
        if (results.ok) {
          this.openSB(results.body.response);
          this.router.navigate([this.routerparent.routerParent]);
        }
        else { alert("Error"); }
      },
        (e) => {
          alert(e.error);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  Cancelar()
  {
          this.router.navigate([this.routerparent.routerParent]);
  }



  hasError(nombreControl: string, validacion: string) {
    const control = this.formUseredit.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


}
