import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataService } from '../../services/data.service';
import { SecurityService } from '../../services/security.service';
import { ErrorStateMatcher1 } from '../error-state-matcher1';
import { Icc } from '../Interfaces/icc';
import { IcierreResponse, Ifactinfcie } from '../Interfaces/ifactinfcie01';
//import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-factinfcie01',
  templateUrl: './factinfcie01.component.html',
    styleUrls: ['./factinfcie01.component.css']
})

export class Factinfcie01Component implements OnInit {
  formReport: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  transactionsVBFPG: IcierreResponse[] = [];
  transactionsVIMPV: IcierreResponse[] = [];
  transactionsVIMPP: IcierreResponse[] = [];
  transactionsVVLBA: IcierreResponse[] = [];
  transactionsEGRS: IcierreResponse[] = [];
  transactionsCAJA: IcierreResponse[] = [];
  dataSourceVBFPG = null;
  dataSourceVIMPV = null;
  dataSourceVIMPP = null;
  dataSourceVVLBA = null;
  dataSourceEGRS = null;
  dataSourceCAJA = null;

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private router: Router,
    private securityService: SecurityService
  ) {
    this.formReport = formBuilder.group({
      cc_id: [0, Validators.required],
      desde: ['', Validators.required],
      hasta: ['', Validators.required]
    });
    this.Columns = ['date', 'ccname', 'transactionprefix','paymenttax', 'value'];
  }
  cc:Icc[];
  selectedvalue: number; selectedview: string;
  r_VBFPG: 0;
  r_VIMPV: 0;
  r_VIMPP: 0;
  r_VVLBA: 0;
  r_EGRS: 0;
  r_CAJA: 0;

  ngOnInit() {
    const url = environment.urlAPI + 'api/cc/ccuser/';
    const userId = localStorage.getItem('userId');
    this.cc = []; //{ cc_id: 0, cc_name: 'Todos' }
    this.dataService.get<Icc[]>(url + userId).subscribe(results => {
      for (const entry of results.body) {
        this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
      }
    }, (e) => {
      console.log(e);
    });
  }

  Generar() {
    const infcie: Ifactinfcie = {
      cc_id: this.formReport.value.cc_id,
      desde: this.formReport.value.desde,
      hasta: this.formReport.value.hasta
    };
    this.mensaje = "";
    const url = environment.urlAPI + 'api/informes/cierre/';
    this.transactionsVBFPG = [];
    this.transactionsVIMPV = [];
    this.transactionsVIMPP = [];
    this.transactionsVVLBA = [];
    this.transactionsEGRS = [];
    this.transactionsCAJA = [];
    this.r_VBFPG = 0;
    this.r_VIMPV = 0;
    this.r_VIMPP = 0;
    this.r_VVLBA = 0;
    this.r_EGRS = 0;
    this.dataService.post<IcierreResponse[]>(url, infcie).subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No hay datos";
      for (const entry of results.body) {
        this.addTransaction({ date: entry.date.substring(0,10), ccname: entry.ccname, datagroup: entry.datagroup, transactionprefix: entry.transactionprefix, paymenttax: entry.paymenttax, value: entry.value });
      }
      this.dataSourceVBFPG = new MatTableDataSource<IcierreResponse>(this.transactionsVBFPG);
      this.dataSourceVIMPV = new MatTableDataSource<IcierreResponse>(this.transactionsVIMPV);
      this.dataSourceVIMPP = new MatTableDataSource<IcierreResponse>(this.transactionsVIMPP);
      this.dataSourceVVLBA = new MatTableDataSource<IcierreResponse>(this.transactionsVVLBA);
      this.dataSourceEGRS = new MatTableDataSource<IcierreResponse>(this.transactionsEGRS);
      this.dataSourceCAJA = new MatTableDataSource<IcierreResponse>(this.transactionsCAJA);  
      this.r_VBFPG = this.dataSourceVBFPG.data.length;
      this.r_VIMPV = this.dataSourceVIMPV.data.length;
      this.r_VIMPP = this.dataSourceVIMPP.data.length;
      this.r_VVLBA = this.dataSourceVVLBA.data.length;
      this.r_EGRS = this.dataSourceEGRS.data.length;
      this.r_CAJA = this.dataSourceCAJA.data.length;
    },
      (e) => {
        this.mensaje =e.error;
        console.log(e);
      });
  }

  addTransaction(transaction) {
    if (transaction.datagroup == "VBFPG")
      this.transactionsVBFPG.push(transaction);
    if (transaction.datagroup == "VIMPV")
      this.transactionsVIMPV.push(transaction);
    if (transaction.datagroup == "VIMPP")
      this.transactionsVIMPP.push(transaction);
    if (transaction.datagroup == "VVLBA")
      this.transactionsVVLBA.push(transaction);
    if (transaction.datagroup == "EGRS")
      this.transactionsEGRS.push(transaction);
    if (transaction.datagroup == "CAJA")
      this.transactionsCAJA.push(transaction);
  }

  addCc(V_cc) {
    try {
      this.cc.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formReport.get(nombreControl);
    return control.hasError(validacion);
  }

  getTotalvalueVBFPG() {
    return this.dataSourceVBFPG.data.map(t => t.value).reduce((acc, value) => acc + value, 0);
    //let total = 0;
    //for (let i = 0; i < this.dataSourceVBFPG.length; i++) {
    //  total = total + parseInt(this.dataSourceVBFPG[i].value);
    //}
    //return total;
  }

  getTotalvalueVIMPV() {
    return this.dataSourceVIMPV.data.map(t => t.value).reduce((acc, value) => acc + value, 0);
  }
  getTotalvalueVIMPP() {
    return this.dataSourceVIMPP.data.map(t => t.value).reduce((acc, value) => acc + value, 0);
  }
  getTotalvalueVVLBA() {
    return this.dataSourceVVLBA.data.map(t => t.value).reduce((acc, value) => acc + value, 0);
  }
  getTotalvalueEGRS() {
    return this.dataSourceEGRS.data.map(t => t.value).reduce((acc, value) => acc + value, 0);
  }
  //getTotalvalueCAJA() {
  //  let v_caja= this.dataSourceVVLBA.data.map(t => t.value).reduce((acc, value) => acc + value, 0);
  //  return v_caja-this.dataSourceEGRS.data.map(t => t.value).reduce((acc, value) => acc + value, 0);
 // }

  b_back() {
    this.router.navigate(['informes']);
  }


}
