import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { CourseService } from '../../services/course.service';
import { DataService } from '../../services/data.service';
import { RouterService } from '../../services/router.service';
import { grCoursesList } from '../Interfaces/igeneralresponse02';

declare interface RouteInfo {
  id: number;
  path: string;
  title: string;
  icon: string;
  color: string;
  cols: number;
  rows: number;
  class: string;
  picture: string;
}

export const ROUTES: RouteInfo[] = [];

@Component({
  selector: 'courseslist-data',
  templateUrl: './courseslist.component.html'
})


export class CoursesListComponent {

  constructor(
    private dataService: DataService,
    private courseService: CourseService,
    private routeparent: RouterService,
    private router: Router
  ) { }

  menuItems: any[];
  functions: RouteInfo[];


  async ngOnInit() {
    this.routeparent.putRouter("data");
    const url = environment.urlAPI + 'api/course/list/v1';
    this.functions =[];
    this.menuItems = [];
    this.dataService.get<grCoursesList>(url).subscribe(results => {
      for (const entry of results.body.resultData) {
        this.addRoutes({ id: entry.id, path: '/contentbasic/', title: entry.name.trim(), icon: entry.icon, class: (entry.picture.length > 0 ? "item-card-image" :"item-card-0102"), picture: entry.picture });
//entry.picture.le
      }
      console.log(this.functions);

      this.menuItems = this.functions.filter(menuItem => menuItem);
    },
      (e) => {
        console.log(e);
      });
    this.menuItems = this.functions.filter(menuItem => menuItem);
  }

  addRoutes(route) {
    //this.courseService.putValues()
    this.functions.push(route);
  }

  b_back() {
    this.router.navigate(['home']);

  }

  b_click(v_route,v_id,v_name) {
    this.courseService.putValues(v_id, v_name,0,0)
    this.router.navigate([v_route]);
  }

}






