/// <reference path="../../../services/data.service.ts" />
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { PermissionsService } from '../../../services/permissions.service';
import { ProviderService } from '../../../services/provider.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { UserService } from '../../../services/user.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { GetProviderList, IProviderListResponse, providerStatus } from '../../Interfaces/iprovider';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';
import { ProviderAddComponent } from '../add/provideradd.component';
import { ProviderDetailComponent } from '../detail/providerdetail.component';
import { ProviderEditComponent } from '../edit/provideredit.component';

@Component({
  selector: 'app-providerlist',
  templateUrl: './providerlist.component.html'
})

export class ProviderListComponent implements OnInit, AfterViewInit {

  formFilter: FormGroup;
  subRef$: Subscription;
  r_gridregs:number= 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  DataGrid:IProviderListResponse[] = [];
  dataList = new MatTableDataSource;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  r_b_cxc:boolean= false;
  r_b_det: boolean = false;
  _currentId: number;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private companyservice: CompanyService,
    private routeparent: RouterService,
    private permissionservice: PermissionsService,
    private dialog: MatDialog,
private providerService:ProviderService,
private userservice:UserService,
    private _snackBar: MatSnackBar
  ) {
    this.formFilter = formBuilder.group({
      name: [''], status:['A',Validators.required]
    });
    this.Columns = ['name', 'idtypename','email','deliveryTerm','b_menu'];
  }
  selectedvalue: number; selectedview: string;
  status: providerStatus[];


  ngOnInit() {
    this.Columns.pop;
    this.mensaje = "";
    //permisos
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    this.permissionservice.getPermission("PROVIDER").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission(entry);
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
    },
      (e) => {
        this.mensaje  =  e.error;
        console.log(e);
      });
    //providerstatus
    this.status = [];
    this.status.push({ name: "Activo", code: "A" });
    this.status.push({ name: "Inactivo", code: "I" });
    this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
  }

  Filter() {
    const filt: GetProviderList = {
      name: this.formFilter.value.name, status:this.formFilter.value.status
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.providerService.list(filt).subscribe(results => {
      if (!results.body.isValid)
        this.mensaje = results.body.error.description;
      else {
        for (const entry of results.body.resultData) {
          this.addData(entry);
        }
        this.dataList = new MatTableDataSource<IProviderListResponse>(this.DataGrid);
        this.dataList.paginator = this.paginator;
        this.r_gridregs = this.dataList.data.length;
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        this.menuPop.push(permission);
        break;
    }
  }

  b_del(id, name) {
    this.providerService.putValues(id, name)
    this.router.navigate(['/customerdelete']);
  }

  b_gen(PPrmCode,link, id)
  {
    switch (PPrmCode) {
      case "A":
        this.b_Add();
        break;
      default:
        this.router.navigate([link]);
        break;
    }
  }

  b_Det() {
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "dialogViewer";
    const dialogRef = this.dialog.open(ProviderDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
      }, (e) => console.log(e)
    );
  }

  b_Add() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    const dialogRef = this.dialog.open(ProviderAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          console.log("grabado");
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );
  }

  b_Mod() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    //dialogConfig.width= "100%!important";
    const dialogRef = this.dialog.open(ProviderEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          //console.log("grabado");
          let _CurrentPage = this.paginator.pageIndex;
          if (this.r_gridregs > 0) {
            this.paginator.pageIndex = _CurrentPage;
            this.Filter();
          }
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );

  }

  b_CurrentId(p_Id: number) {
    this._currentId = p_Id;
  }

  b_back() {
    this.router.navigate(['home']);
  }

  //execute menu options
  b_execute(PFunctionCode:string ,PPrmCode: string, PLink: string) {
    this.routeparent.putRouter("providerlist");
    var _ProviderId = this.DataGrid.find(x => x.id ==this._currentId);
    this.providerService.putValues(this._currentId, _ProviderId.name)
    switch (PPrmCode) {
      case "M":
        this.b_Mod();
        break;
      case "C":
        this.b_Det(); 
        break;
      default:
        this.router.navigate([PLink]);
        break;
    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
