import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CountService } from '../../../../services/count.service';
import { DataService } from '../../../../services/data.service';
import { LocationService } from '../../../../services/location.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { countingnumber} from '../../../Interfaces/icount';
import { IgeneralResponse02 } from '../../../Interfaces/igeneralresponse02';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html'
})

export class NumberComponent implements OnInit {
  formadd: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  countmessagetype: string = "";
  msgtype: string;
  v_ccid:number;
  r_ccregs: 0;
  countingnumber: number;
  typeselected: string;
  types: string[] = ['Parcial', 'Total'];


  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private countService: CountService,
    private locationService: LocationService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.formadd = formBuilder.group({
      type: [null, Validators.required],
    });
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    if (this.countService.countingID == 0 || this.locationService.countingnumber == 3) this.Abort();
    this.countingnumber = this.locationService.countingnumber+1;
    this.mensaje = "Antes de cambiar de conteo en "+this.locationService.locationName+", verifique que en la ubicacion se hayan registrado todos los PA con su respectiva cantidad y que las terminales de Arion no tengan reportes pendientes del conteo #"+this.locationService.countingnumber+".";
    this.msgtype = "msg-notify"
  }


  Accept()
  {
    try {
      const icn:countingnumber = {
        location_id: this.locationService.locationID,
        countingtype: (this.formadd.value.type == "Parcial" ? "P" : "T")
      };
      const url = environment.urlAPI + 'api/count/countingnumber/';
      this.dataService.put<IgeneralResponse02>(url,icn).subscribe(results => {
        if (results.ok) {
          this.openSB(results.body.resultData.description);
          this.router.navigate(['/locationselect']);
        }
        else { alert("Error"); }
      },
        (e) => {
          alert(e.error);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  Cancel()
  {
          this.router.navigate(['/locationselect']);
  }

  Abort() {
    this.router.navigate(['locationselect']);
  }


  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  counttype(type) {
    switch (type) {
      case "Parcial":
        this.countmessagetype = "En el modo parcial cuente solo los PA a los que desea hacer control de calidad.";
        break;
      case "Total":
        this.countmessagetype = "Para realizar el control de calidad del conteo #1 debe contar todos los PA de esta ubicacion.";
        break;
    }
  }


}
