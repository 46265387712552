import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TargetReport } from '../app/Interfaces/iReport';
import { environment } from '../environments/environment';
import { DataService } from '../services/data.service';


//export interface _RS {
//  Name: string;
//  Value: string;
//}

@Injectable({ providedIn: 'root' })

export class ReportService {
// 
//  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }
  _S: TargetReport[];


 // public GetTarget() {
 //  //let _S = [];
 // this._S.push({ name: "Informe", value: "P" });
 // this._S.push({ name: "Tabla", value: "T" });
 // return this._S;
 // }

}

