import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GRDataTable, IdatatableResponse } from '../app/Interfaces/idatatable';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class DataTableService {

  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  getDataList(PTable:string) {
    try {
      let url = this.baseUrl + 'api/datatable/';
      return this.dataService.get<IdatatableResponse[]>(url +PTable )
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getDataListV2(PTable: string) {
    try {
      let url = this.baseUrl + 'api/datatable/v2/';
      return this.dataService.get<GRDataTable>(url + PTable)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

}
