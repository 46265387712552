import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { ModuleService } from '../../../services/module.service';
import { RouterService } from '../../../services/router.service';
import { SystemInternalService } from '../../../services/systemInternal.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { ISaveCompModu, ISaveModu } from '../../Interfaces/icompmodu';
import { Get_configV2, IconfigResponseV2 } from '../../Interfaces/iconfig';
import { moduleStatus } from '../../Interfaces/imodules';

interface Status {
  name: string;
  code: moduleStatus;
}

interface CfgNode {
  description: string;
  id: number;
  code: string;
  parentId: number;
  status:moduleStatus;
  startDate: Date;
  instructions: string;
  children?: CfgNode[];
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  code: string;
  description: string;
  level: number;
  id: number;
  status:moduleStatus;
  startDate: Date;
  instructions: string;
}


@Injectable()
export class FileDatabase {
}

@Component({
  selector: 'app-mymodules',
  templateUrl: './mymodules.component.html'
})

export class MyModulesComponent {
  FormModule: FormGroup;
  matcher = new ErrorStateMatcher1();
  private _transformer = (node: CfgNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      description: node.description,
      id: node.id,
      code: node.code,
      parentId: node.parentId,
      status: node.status,
      startDate: node.startDate,
      instructions: node.instructions,
      level: level

    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );


  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  constructor(
    private formBuilder: FormBuilder,
    private companyservice: CompanyService,
    private dataService: DataService,
    private systemInternal: SystemInternalService,
    private moduleService: ModuleService,
    private router: Router,
    private routerService: RouterService,
    private _snackBar: MatSnackBar) {
    this.FormModule = formBuilder.group({
      status: ['INACTIVE',Validators.required],
      startDate: ['2000-01-01',Validators.required],
    });
    this.initialize();
  }
  mensaje: string;
  Modules: CfgNode[] = [];
  companyname: string;
  r_status: string="";
  r_text: string;
  r_id: number;
  r_instructions: string = "Seleccione el módulo para su manejo";
  listStatus: Status[] = [{ code:1, name: 'Activo' }, { code: 2, name: 'Inactivo' }/*, { code:3, name: 'No aplica' }*/];

  initialize() {
    this.mensaje = "";
    this.companyname = this.companyservice.companyName;
    //consultar los permisos
    this.Modules = [];
    const gf: Get_configV2 = {
      companyId: this.companyservice.companyID,
      level: 1,
      id: 0,
      updateid: 0
    };
    this.moduleService.GetModulesAvailablesV2(this.companyservice.companyID).subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.Modules.push(entry);
        }
        this.dataSource.data = this.Modules; // results.body.resultData; //   this.accounts; // TREE_DATA; //JSON.parse(td);//
      }
      else {
        alert(results.body.error.description); this.mensaje = results.body.error.description;
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  //ParseMultipleData(PNode: CfgNode) {
  //  if (PNode.component == "MULTIPLE") {
  //    let _values: string[] = PNode.stringvalue.split(",");
  //    PNode.multipleValueString = _values;
  //  }
  //  if (PNode.children != null) {
  //    for (const entry of PNode.children) {
  //      this.ParseMultipleData(entry);
  //    }
  //  }
  //}

  //GetConf(entry) {
  //  this.Configurations.push(entry);
  //  if (entry.component == "MULTIPLE") {
  //    let _values: string[] = entry.value.split(",");
  //    var index = this.Configurations.find(x => x.id == entry.id);
  //    if (index != null) {
  //      index.multipleValueString = _values;
  //    }
  //  }
  //}

  GetDataTable(entry: IconfigResponseV2) {
  //  if (entry.referenceTable.trim().length > 0) {
  //    entry.listValueString = entry.stringvalue;
  //    switch (entry.referenceTable) {
  //      case "MODUL":
  //        this.moduleService.GetTableModulesV1().subscribe(_res => {
  //          if (_res.body.isValid) {
  //            for (const entrytable of _res.body.resultData) {
  //              this.listValues.push({ table: entrytable.table, name: entrytable.textvalue, code: entrytable.code });
  //            }
  //          }
  //        })
  //        break;
  //      default:
  //        this.dataTableService.getDataListV2(entry.referenceTable).subscribe(_res => {
  //          if (_res.body.isValid) {
  //            for (const entrytable of _res.body.resultData) {
  //              this.listValues.push({ table: entrytable.table, name: entrytable.textvalue, code: entrytable.code });
  //            }
  //          }
  //        })
  //        break;
  //    }
  //  }
  //  if (entry.children != null) {
  //    for (const _child of entry.children) {
  //      this.GetDataTable(_child);
  //    }
  //  }
  }


  b_back() {
    this.router.navigate([this.routerService.routerParent01]);
  }

  Save() {
    try {
      const oModule:ISaveModu[] = [];

      for (const entry of this.dataSource._flattenedData.value) {
        if (entry.status != 3) {
          oModule.push({ id: entry.id, startDate: entry.startDate, status: entry.status, code: entry.code });
        }
      }
      const oSaveModule: ISaveCompModu={
        companyId: this.companyservice.companyID, modules:oModule
      };
      this.moduleService.SaveCompanyModulesV1(oSaveModule).subscribe(results => {
        if (results.body.isValid) {
          this.openSB(results.body.resultData.description);
          this.b_back();
        }
        else { alert("Error"); }
      },
        (e) => {
          alert(e.error);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  onClick(node) {
    try {
      this.r_id = node.id;
      this.r_status = node.status;
      this.r_instructions = (node.instructions != null && node.instructions != "" ? node.instructions : "Opciones de " + node.description.trim() );
      this.FormModule.controls['status'].setValue(node.status);
      const [datecmp, timecmp] = node.startDate.split('T');
      //let _Date =Date node.startDate;
      let _date = Date.now;
      let _newDate = new Date(datecmp).getFullYear + "-";
      _newDate = _newDate + new Date(datecmp).getMonth + "-";
      _newDate =_newDate+ new Date(datecmp).getDay;


      this.FormModule.controls['startDate'].setValue(datecmp);
      //this.FormModule.controls['stringvalue'].setValue(node.stringvalue);
      //this.FormModule.controls['listValueString'].setValue(node.listValueString);
      //this.FormModule.controls['multipleValueString'].setValue(node.multipleValueString);
    //  if ((node.type == "S") && node.referenceTable.trim().length > 0) {
    //    this.listValueFilter = this.listValues.filter(x => x.table == node.referenceTable.trim());
    //  }
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  onChange(getId, xnode) {
    try {
      var index: number = this.dataSource._flattenedData.value.indexOf(this.dataSource._flattenedData.value.find(x => x.id == getId));
      if (index >= 0) {
      //  switch (this.dataSource._flattenedData.value[index].type) {
      //    case "B":
            this.dataSource._flattenedData.value[index].startDate = this.FormModule.controls['startDate'].value;
            this.dataSource._flattenedData.value[index].status = this.FormModule.controls['status'].value;
      //      break;
      //    case "S":
      //      if (this.dataSource._flattenedData.value[index].referenceTable == "")
      //        this.dataSource._flattenedData.value[index].stringvalue = this.FormModule.controls['stringvalue'].value;
      //      else {
      //        if (this.dataSource._flattenedData.value[index].component == "MULTIPLE") {
      //          this.dataSource._flattenedData.value[index].multipleValueString = [];
      //          let _index = 0;
      //          for (const entry of this.FormModule.controls['multipleValueString'].value) {
      //            this.dataSource._flattenedData.value[index].multipleValueString.push(entry);
      //            _index++;
      //          }
      //        }
      //        else {
      //          this.dataSource._flattenedData.value[index].listValueString = this.FormModule.controls['listValueString'].value;
      //        }
      //      }
      //      break;
      //  }
      }
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  searchNode(id) {
    var node = this.dataSource.data.find(x => x.id == id);
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.FormModule.get(nombreControl);
    return control.hasError(validacion);
  }
}
