import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { concat, Subscription } from 'rxjs';
import { DataService } from '../../../../services/data.service';
import { RegistryService } from '../../../../services/registry.service';
import { iRegistryUpdate } from '../../../Interfaces/iregistry';

@Component({
  selector: 'app-licensedetail',
  templateUrl: './licensedetail.component.html'
})
export class LicenseDetailComponent implements OnInit {
  formedit: FormGroup;
  private sub: Subscription;
  private _registry: iRegistryUpdate;
  private dataService: DataService;
  private mensaje: string="";
  //protected providers: IcustomerlistResponse[];
  url: string;

  constructor(private dialogRef: MatDialogRef<LicenseDetailComponent>, private registryservice:RegistryService, private registryService: RegistryService, private formBuilder: FormBuilder) 
  {
    this.formedit = formBuilder.group({
      type: [''], level: [''], users: [0], apps: [''],fromdate: ['2000-01-01']
    })
}


  ngOnInit(): void {
    this.loadContent();
  }

loadContent(){
   // this.providers = [];
  this._registry = {  id: this.registryService.id, idInstallation: "", key: "", invoiceHId: 0, status: "", license: "", providerId:this.registryService.providerId };0
  this.registryservice.getRegistryDetails(this.registryservice.id).subscribe(response => {
    if (!response.body.isValid)
      console.log(response.body.error.description);
    else {
      this.formedit.controls['type'].setValue(response.body.resultData.registryType);
      this.formedit.controls['level'].setValue(response.body.resultData.level);
      this.formedit.controls['users'].setValue(response.body.resultData.users);
      this.formedit.controls['fromdate'].setValue(response.body.resultData.fromDate.toString().substring(0, 10));
      let _appst:string = "";
      for (const _app of response.body.resultData.apps) {
        _appst = _appst + _app.name + " ";
      }
      this.formedit.controls['apps'].setValue(_appst);
      this.formedit.disable();
    }
  },
    (e) => {
      console.log(e);
    });
  }

 
  closeWithoutSave() {
    this.dialogRef.close();
  }

}
