import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DataTableService } from '../../../services/dataTable.service';
import { PasService } from '../../../services/pas.service';
import { PasGroupService } from '../../../services/pasGroup.service';
import { TaxesService } from '../../../services/taxes.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IdatatableResponse } from '../../Interfaces/idatatable';
import { IgrouppaslistResponse } from '../../Interfaces/igrouppas';
import { GRPASCCV1Detail, ICCPASUpdate, ICompanyPASModify, IPutCCPAS, paslist, PASTaxes, putPASTaxes } from '../../Interfaces/ipas';

@Component({
  selector: 'app-pasedit',
  templateUrl: './pasedit.component.html'
})

export class PASEditComponent implements OnInit {
  formedit: FormGroup;
  //formCCData: FormArray;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  groups: IgrouppaslistResponse[];
  measurementUnits: IdatatableResponse[];
  compositionClasses: IdatatableResponse[];
  //taxPercentageTypes: IdatatableResponse[];
  //taxValueTypes: IdatatableResponse[];
  statuses: IdatatableResponse[];
  dataCCs: GRPASCCV1Detail;
  element: HTMLElement;
  Columns: string[] = [];
  DataGrid: PASTaxes[] = [];
  taxList = new MatTableDataSource;
  _TaxRegs: number = 0;
  lineCC = {
    dataCC: {
      data: [{ ccCode: '', ccName: '', status: '' }]
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private datatableservice: DataTableService,
    private passervice: PasService,
    private pasgroupservice: PasGroupService,
    private taxesservice: TaxesService,
    private dialogRef: MatDialogRef<PASEditComponent>,
    private _snackBar: MatSnackBar
  ) {
    this.formedit = formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(80)]],
      measurementUnit: ['UN', Validators.required],
      measurementQuantity: [1, Validators.required],
      presentation: ['', Validators.maxLength(50)],
      brand: ['', Validators.maxLength(80)],
      group: [-1, [Validators.required]],
      //taxPercentageType: ['N', [Validators.required]],
      //taxPercentage: [0, [Validators.min(0)]],
      //taxValueType: ['N', [Validators.required]],
      //taxValue: [0, [Validators.min(0)]],
      observation: ['', [Validators.maxLength(180)]],
      compositionClass: ['R', [Validators.required]],
      minProfitPercent: [0, [Validators.required, Validators.min(1), Validators.max(99)]],
      profitPercent: [0, [Validators.required,Validators.min(1), Validators.max(99)]],
      dataCC: this.formBuilder.group(
        {
          data: this.formBuilder.array([])
        })
    });
    this.Columns = ['name', 'selected', 'value'];
  }
  selectedvalue: number; selectedview: string;
  r_companiesregs: 0;
  codeSelect: string;
  PASCode: string;

  newCCData(entry): FormGroup {
    return this.formBuilder.group({
      ccId: [entry.id],
      ccName: { value: [entry.ccCode + " " + entry.ccName], disabled: true },
      status: [entry.status]
    })
  }

  ngOnInit() {
    this.measurementUnits = [];
    this.datatableservice.getDataList("MEASU").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.measurementUnits.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //group
    this.groups = [];
    this.pasgroupservice.GetGroups(0).subscribe(results => {
      for (const entry of results.body.resultData) {
        this.groups.push(entry);
      }
    }, (e) => {
      console.log(e);
    });
    //composition classes
    this.compositionClasses = [];
    this.datatableservice.getDataList("CMPCL").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.compositionClasses.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //PAS statuses
    this.statuses = [];
    this.datatableservice.getDataList("PASST").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.statuses.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //get Company PAS 
    this.passervice.getDataCompanyV1(this.passervice.pasID).subscribe(result => {
      if (result.body.isValid) {
        this.PASCode = result.body.resultData.code;
        this.formedit.controls['name'].setValue(result.body.resultData.name);
        this.formedit.controls['measurementUnit'].setValue(result.body.resultData.unitMeasurement);
        this.formedit.controls['measurementQuantity'].setValue(result.body.resultData.measurementQuantity);
        this.formedit.controls['presentation'].setValue(result.body.resultData.presentation);
        this.formedit.controls['brand'].setValue(result.body.resultData.brand);
        this.formedit.controls['group'].setValue(result.body.resultData.groupId);
        //this.formedit.controls['taxPercentageType'].setValue(result.body.resultData.taxPercentageType);
        //this.formedit.controls['taxPercentage'].setValue(result.body.resultData.taxPercentage);
        //this.formedit.controls['taxValueType'].setValue(result.body.resultData.taxValueType);
        //this.formedit.controls['taxValue'].setValue(result.body.resultData.taxValue);
        this.formedit.controls['observation'].setValue(result.body.resultData.observation);
        this.formedit.controls['compositionClass'].setValue(result.body.resultData.compositionClass);
        this.formedit.controls['minProfitPercent'].setValue(result.body.resultData.minProfitPercent);
        this.formedit.controls['profitPercent'].setValue(result.body.resultData.profitPercent);
      }
    }, (e) => {
      console.log(e);
    });
    //get PAS CC
    const _Filter: paslist = {
      cc_id: 0,
      group_id: 0,
      pasId: this.passervice.pasID,
      pasName:''
    };
    //this.dataCCs = [];
    this.passervice.listIdCC(_Filter).subscribe(result => {
      if (result.body.isValid) {
        const control = <FormArray>this.formedit.get('dataCC.data');
        for (const entry of result.body.resultData) {
          this.lineCC.dataCC.data.forEach(x => {
            control.push(this.newCCData(entry))
          });
        }
      }
      else {
      }
    }, (e) => {
      console.log(e);
    });
    //PAS Taxes
    this.DataGrid = [];
    this.passervice.getAllPASTaxes(this.passervice.pasID).subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.DataGrid.push(entry);
        }
        this.taxList = new MatTableDataSource<PASTaxes>(this.DataGrid);
        this._TaxRegs = this.taxList.data.length;
      }
    }, (e) => {
      console.log(e);
    });
//
    this.element = document.getElementById("name") as HTMLElement;
    //this.element.focus();
    this.mensaje = "";
  }

  Cancel() {
    this.dialogRef.close();
  }

  Save() {
    try {
      const _Taxes: putPASTaxes[] = [];
      const _DeleteTaxes: putPASTaxes[] = [];
      for (const entry of this.taxList.data) {
        if (entry["selected"] == true) {
          if (entry["valuetype"] == "V" && entry["value"] <= 0) {
            this.openSB("Error en el valor del impuesto " + entry["name"]);
            return;
          }
        }
      }
      const IReg: ICompanyPASModify =
      {
        id: this.passervice.pasID,
        code: this.PASCode,
        name: this.formedit.value.name.trim(),
        type: "P",
        measurementUnit: this.formedit.value.measurementUnit,
        measurementQuantity: this.formedit.value.measurementQuantity,
        presentation: this.formedit.value.presentation,
        brand: this.formedit.value.brand.trim(),
        groupId: this.formedit.value.group,
        observation: "",
        perishable: false,
        compositionClass: this.formedit.value.compositionClass,
        supervisedBilling: false,
        order: 0,
        taxPercentageType:"",
        taxPercentage:0,
        taxValueType:"",
        taxValue: 0,
        minProfitPercent: this.formedit.value.minProfitPercent,
        profitPercent: this.formedit.value.profitPercent,
      };
      this.passervice.ModifyPAS(IReg).subscribe(result => {
        if (result.body.isValid) {
          const control = <FormArray>this.formedit.get('dataCC.data');
          control.value.forEach(x => {
            const _PASCC: ICCPASUpdate = {
              ccId: x.ccId, pasId: this.passervice.pasID, status: x.status
            }
            this.passervice.UpdatePASCC(_PASCC).subscribe(result => {
              if (!result.body.isValid) {
                alert(result.body.error.description);
                return;
              }
            });
          });
          //taxes
          for (const entry of this.taxList.data) {
            if (entry["selected"] == true) {
              _Taxes.push({
                id: entry["id"], pasId: result.body.resultData.id, taxesId: entry["id"], value: entry["value"]
              });
            }
            else {
              _DeleteTaxes.push({ id: entry["id"], pasId: result.body.resultData.id, taxesId: entry["id"], value: entry["value"] });
            }
          }
          if (_Taxes != null) {
            this.passervice.putTaxes(_Taxes).subscribe(_restax => {
              if (!_restax.body.isValid) {
                alert(_restax.body.error.description);
                return;
              }
            });
          }
          if (_DeleteTaxes != null) {
            for (const _TD of _DeleteTaxes) {
              this.passervice.deleteTaxes(_TD.pasId, _TD.taxesId).subscribe(_restax => {
                if (!_restax.body.isValid) {
                  alert(_restax.body.error.description);
                  return;
                }
              });
            }
          }

          this.openSB(result.body.resultData.description);
          this.dialogRef.close(true);
        }
        else {
          alert(result.body.error.description);
        }
      },
        (e) => {
          this.mensaje = e.error;
          console.log(e);
        }

      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  onToggle(event, pIndex) {
    this.taxList.data[pIndex]["selected"] = event.checked; //..data.map(t => t.value).find.reduce((acc, value) => acc + value, 0);
  }

  onValueChange(event, pIndex) {
    try {
      this.taxList.data[pIndex]["value"] = parseFloat(event.target.value); //..data.map(t => t.value).find.reduce((acc, value) => acc + value, 0);
    }
    catch (e) {
      alert("Error al modificar el PAS: ");
    }
    return;
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  ngOnDestroy(): void {
  }
}
