import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BackgroundProcessService } from '../../../../services/backgroundprocess';
import { CcService } from '../../../../services/cc.service';
import { CountService } from '../../../../services/count.service';
import { DataService } from '../../../../services/data.service';
import { FileService } from '../../../../services/file.service';
import { RouterService } from '../../../../services/router.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { countstatusResponse } from '../../../Interfaces/icc';
import { counttosync } from '../../../Interfaces/icount';
import { IuserPermissionsResponse } from '../../../Interfaces/iuser';

@Component({
  selector: 'app-ccselect',
  templateUrl: './ccselect.component.html',
  styleUrls: ['./ccselect.component.css']
})

export class CcSelectComponent implements OnInit {


  subRef$: Subscription;
  r_ccregs: 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  Cc: countstatusResponse[] = [];
  ccList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  url = environment.urlAPI;
  progress: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private routeparent: RouterService,
    private ccservice: CcService,
    private countservice: CountService,
    private _snackBar: MatSnackBar,
    private fileService: FileService,
    private backgroundProcess: BackgroundProcessService
  ) {
    this.Columns = ['code', 'name','countstatusname','b_stc'];
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    //permisos
    let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.MenuG = [];
    this.dataService.get<IuserPermissionsResponse[]>(url + "COUNT-SC").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    },
      (e) => {
        this.mensaje  =  e.error;
        console.log(e);
      });
    //cc
    this.Cc= [];
    this.r_ccregs = 0;
    url = environment.urlAPI + 'api/count/countstatus/' ;
    this.dataService.get<countstatusResponse[]>(url+'0').subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No hay datos";
      for (const entry of results.body) {
        this.addCc({
          id: entry.id, name: entry.name, code: entry.code, inventcountstatus: entry.inventcountstatus,
          oncount: entry.oncount, countstatusname: entry.countstatusname, countinghh_id: entry.countinghh_id,
          moduleCode:entry.moduleCode
        });
      }
      this.ccList = new MatTableDataSource<countstatusResponse>(this.Cc);
      this.r_ccregs = this.ccList.data.length;
    },
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
        console.log(e);
      });
  }

  addCc(cc) {
      this.Cc.push(cc);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G" && permission.code=="X") {

          this.MenuG.push(permission);
    }
    else {
      if (permission.type == "R") {
        switch (permission.code) {
          case "W": {
            this.Columns.push("b_down");
            break;
          }
        }
      }

    }
  }

  b_down(id, name, status, code, countingid, moduleCode) {
    var confirmDownload = confirm("Desea obtener el último conteo?");
    if (confirmDownload) {
      const icts: counttosync = {
        cc_id: id,
        id: 0
      };
      this.fileService.download(id)
        .subscribe((response) => {
          if (response.type == HttpEventType.UploadProgress)
            this.progress = Math.round((100 * response.loaded) / response.total);
          else {
            if (response.type == HttpEventType.Response) {
              this.mensaje = 'Ok';
              this.downloadFile(response, countingid, code);
            }
          }
          (e) => {
            this.mensaje = e.error;
            alert(e.error);
            console.log(e);
          }
        });
    }
  }

  private downloadFile = (data: HttpResponse<Blob>, vp_CountingId, vp_CCCode) => {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = "count" + vp_CCCode.toString() + ".csv"; // environment.urlAPI + 'api/count/finished/v2/';  //  this.fileUrl;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
    this.openSB('Archivo ' + a.download + ' bajado');
  }

  b_stc(id, name, status, code, countingid, moduleCode) {
    this.backgroundProcess.GetStatus("counting").subscribe(response => {
      if (response.body.isValid) {
        this.openSB("El conteo se encuentra en proceso de cierre");
        this.b_back();
      }
      else {
        this.ccservice.putValues(id, name, code)
        if (status.trim() == '') {
          this.router.navigate(['/countstart']);
        }
        else {
          if (moduleCode == "ARION") {
            this.countservice.putValues(countingid, moduleCode)
            this.router.navigate(['/locationselect']);
          }
          else {
            this.openSB("El conteo se inicio con otro modulo");
          }
        }
      }
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
      }
    });
  }


  b_gen(link, id) {
        this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate(['inventory']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
