import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { environment } from '../environments/environment';
import { GRCCUser, GRCCUserV3, Icc, IGRCCProduction, IRCCData } from '../app/Interfaces/icc';

@Injectable()
export class CcService {
  private storage: any;

  ccID: number = 0;
  ccName: string = "";
  cccode: string = "";
  path: string = "";
  baseUrl = environment.urlAPI;


  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(id, name, code) {
    this.ccID = id;
    this.ccName = name;
    this.cccode = code;
  }
  public putPath(PPath) {
    this.path = PPath;
  }

  //Obj: get CC data by id
  GetCCData(PCCId: number) {
    try {
      let url = this.baseUrl + 'api/cc/v2/';
      return this.dataService.get<IRCCData>(url + PCCId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  //obj: get cc productions (with production locations)
  getListProduction() {
    try {
      let url = this.baseUrl + 'api/cc/production/';
      return this.dataService.get<IGRCCProduction>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getCCList() {
    try {
      let url = this.baseUrl + 'api/cc/ccuser/';
      return this.dataService.get<Icc[]>(url + '0')
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getCCListV2() {
    try {
      let url = this.baseUrl + 'api/cc/v2/ccuser/';
      return this.dataService.get<GRCCUser>(url + '0')
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  //obj:Get CC's user V3 (route, icon)
  getCCListV3() {
    try {
      let url = this.baseUrl + 'api/cc/v3/ccuser/';
      return this.dataService.get<GRCCUserV3>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

}
