import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from '../../services/data.service';
import { UserService } from '../../services/user.service';
import { Imenu01Response } from '../Interfaces/imenu01';
import { IuserPermissionsResponse } from '../Interfaces/iuser';


declare interface RouteInfo {
  function_code: string;
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [];

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})

export class NavMenuComponent {
  headers = new Headers();
  menuItems: any[];
  functions: RouteInfo[];
  firstName: string;
  isExpanded = false;
  Permissions: IuserPermissionsResponse[] = [];
  menuUser: IuserPermissionsResponse[] = [];
  menuUserList: any[];

  
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(
    private dataService: DataService, private userService: UserService) { }


  async ngOnInit() {
    const userId = localStorage.getItem('userId');
    const userName = localStorage.getItem('userName');
    this.firstName=userName.split(" ", 1).toString();
    this.firstName = this.firstName.substring(0, 1) + this.firstName.substring(1).toLowerCase();
    //permisos
    let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.menuUser = [];
    this.userService.putValues(parseInt( userId),userName);

    this.dataService.get<IuserPermissionsResponse[]>(url + "USER").subscribe(results => {
      for (const entry of results.body) {
        this.addPermissionsUser({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description.substring(0,1).toUpperCase()+entry.description.substring(1).toLowerCase(), type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.menuUserList = this.menuUser.filter(menuItem => menuItem); // new MatTableDataSource<IuserPermissionsResponse>(this.menuUser);
    },
      (e) => {
        console.log(e);
      });
    //
    url = environment.urlAPI + 'api/menu/n0/';
    let ROUTES = [];
    this.functions = [];  
    this.functions.pop();
    this.addRoutes({function_code:'', path: '/home', title: 'Inicio', icon: 'home', class: '' });

    this.dataService.get<Imenu01Response[]>(url + userId).subscribe(results => {
        for (const entry of results.body) {
          this.addRoutes({function_code:entry.function_code, path: '/' + entry.function_code.toLowerCase(), title: entry.description.trim(), icon: entry.icon, class: '' });
      }
        this.menuItems = this.functions.filter(menuItem => menuItem);
    },
      (e) => {
        console.log(e);
        this.menuItems = this.functions.filter(menuItem => menuItem);
      });
  }

  addRoutes(route) {
    this.functions.push(route);
  }

  addPermissionsUser(permission) {
    this.Permissions.push(permission);
    if (permission.type == "R") {
      switch (permission.code) {
        case "M": { //modify account
            this.menuUser.push(permission);
          break;
        }
        case "S": {  //password
            this.menuUser.push(permission);
          break;
        }
        case "P": {  //permissions
            this.menuUser.push(permission);
          break;
        }
      }
    }
  }

}


