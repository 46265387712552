import { Injectable } from '@angular/core';

@Injectable()
export class RouterService {

  routerParent: string ="";
  routerParent01: string = "";
  routerParent02: string = "";
  constructor() { }


  public putRouter(router) {
    this.routerParent = router;
  }
  public putRouter01(router) {
    this.routerParent01 = router;
  }
  public putRouter02(router) {
    this.routerParent02 = router;
  }
    
  
}
