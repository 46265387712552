import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { RouterService } from '../../services/router.service';
import { SecurityService } from '../../services/security.service';
import { UserService } from '../../services/user.service';
import { ErrorStateMatcher1 } from '../error-state-matcher1';
import { PermissionsService } from '../../services/permissions.service';
import { WikiService } from '../../services/wiki.service';
import { Iwiki } from '../Interfaces/iwiki';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-listwikis',
  templateUrl: './listwikis.component.html'
})

export class ListWikisComponent implements OnInit {
  formPswedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  msgtype: string = "";
  username = this.userservice.userName;
  titles: Iwiki[] = [];
  Columns: string[] = [];
  dataTitles = null;

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private routerparent: RouterService,
    private userservice: UserService,
    private _snackBar: MatSnackBar, private permissionsService: PermissionsService,
    private wikiService: WikiService
  ) {
    this.Columns = ['name','description'];
  }


  ngOnInit() {
    //this.permissionsService.getPermission("WIKI").subscribe(response => {
    //  if (response.body.length == 0)
    //    this.mensaje = "No tiene permisos";
    //  else {
    //    for (const entry of response.body)
    //    { this.addPermission({id:entry.id, function_code:entry}) }
    //  }
    //});
    //let url=environment.urlAPI+
    //this.mensaje = "La contrasena debe tener al menos 6 caracteres de longitud";
    this.msgtype = "msg-notify";
    this.loadData();

  }

  loadData() {
    const _UserId = parseInt(localStorage.getItem('userId'));
    this.titles = [];
    this.wikiService.getWikis(_UserId).subscribe(response => {
      if (!response.body.isValid)
        this.mensaje = response.body.error.description;
      else {
        for (const entry of response.body.resultData) {
          this.addWiki({
            id: entry.id, name: entry.name, description: entry.description,
            status: entry.status, icon: entry.icon, parentId: entry.parentId,
            levelTree: entry.levelTree, url:entry.url
          });
        }
        this.dataTitles = new MatTableDataSource<Iwiki>(this.titles);
      }
    });
  }

  addWiki(_wiki) {
    //if (_wiki.levelTree == 1)
      this.titles.push(_wiki);
    //else {
    //  this.lines.push(_wiki);
    //}
  }

  
  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  b_back() {
    this.router.navigate(['home']);
  }

}
