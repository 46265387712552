import { AfterViewInit, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataService } from '../../../services/data.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { Icc } from '../../Interfaces/icc';
import { Igrdailypurchase } from '../../Interfaces/igeneralresponse02';
import { dailypurchase, Idailypurchase } from '../../Interfaces/ipurchase';

@Component({
  selector: 'app-dailypurchase',
  templateUrl: './dailypurchase.component.html',
  styleUrls: ['./dailypurchase.component.css']
})

export class DailypurchaseComponent implements OnInit, AfterViewInit{

  formReport: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  DataGrid: Idailypurchase[] = [];
  dataList = new MatTableDataSource;
  r_gridregs: number = 0;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private router: Router,
    private securityService: SecurityService
  ) {
    this.formReport = formBuilder.group({
      cc_id: [null, Validators.required],
      date: ['', Validators.required]
    });
    this.Columns = ['ccname','pasnameum','date', 'price', 'cost', 'quantity','totalcost'];
  }
  cc: Icc[];
  selectedvalue: number; selectedview: string;


  ngOnInit() {
    //cc
    const url = environment.urlAPI + 'api/cc/ccuser/';
    this.cc = []; 
    this.dataService.get<Icc[]>(url + "0").subscribe(results => {
      for (const entry of results.body) {
        this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
      }
    }, (e) => {
      console.log(e);
    });
  }

  Generar() {
    const inf:dailypurchase = {
      ccid: this.formReport.value.cc_id,
      date: this.formReport.value.date
    };
    this.mensaje = "";
    const url = environment.urlAPI + 'api/purchase/daily/';
    this.dataList.paginator = this.paginator;
    this.dataList = null;
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.dataService.post<Igrdailypurchase>(url, inf).subscribe(results => {
      if (! results.body.isValid)
        this.mensaje = "No hay datos";
      for (const entry of results.body.resultData) {
        this.addData({
          id: entry.id, ccname: entry.ccname.trim(), pas_id: entry.pas_id, pasnameum: entry.pasnameum,
          date: entry.date.toString().substring(0,10), quantity: entry.quantity,price: entry.price,
          cost: entry.cost,averagecost: entry.averagecost, totalprice: entry.totalprice,
          totalcost: entry.totalcost, avergaecost: entry.averagecost});
      }
      this.dataList = new MatTableDataSource<Idailypurchase>(this.DataGrid);
      this.r_gridregs = this.dataList.data.length;
    },
      (e) => {
        this.mensaje =e.error;
        console.log(e);
      });
  }

  addData(transaction) {
      this.DataGrid.push(transaction);
  }

  addCc(V_cc) {
    try {
      this.cc.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formReport.get(nombreControl);
    return control.hasError(validacion);
  }

  getTotalcost() {
    return this.DataGrid.map(t => t.totalcost).reduce((acc,totalcost) => acc +totalcost, 0);
  }

  b_back() {
    this.router.navigate(['informes']);
  }



}
