//import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
//@Injectable()
export class UploadFilesService {

  //Url obtenida de la variable de enviroments
  baseUrl = environment.urlAPI;


  //Inyeccion de HttpClient
  constructor(private http: HttpClient,private dataService: DataService  ) { }

  //private http: HttpClient,
  //Metodo que envia los archivos al endpoint /upload 
  //upload(file: File): Observable<HttpEvent<any>> {
  //  const formData: FormData = new FormData();
  //  formData.append('files', file);

    //let headers = new Headers();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    //headers.append('Content-Type',' multipart/form-data');
    //headers.append('Accept', 'application/json');

    //const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {

  //  this.dataService.post<IResponse[]>(this.baseUrl+'api/file/v1/arionpas', formData,).subscribe(results => {
      //reportProgress: true,
       // responseType: 'json'

 //     for (const entry of results.body) {
  //      return entry; // this.http.request(entry);
  //
  //    }
  //  },
  //    (e) => {
  //      console.log(e);
   //   });
    //const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
    //
    //  reportProgress: true,
    //  responseType: 'json'
    //});


    //return this.http.request(req);
 // }

  //Metodo para Obtener los archivos
  getFiles() {
    return this.dataService.get(`${this.baseUrl}/files`);
  }



  //Metodo para borrar los archivos
  deleteFile(filename: string) {
    return this.dataService.get(`${this.baseUrl}/delete/${filename}`);
  }

}
