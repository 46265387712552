import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IdatatableResponse } from '../../Interfaces/idatatable';
import { IuserAdd } from '../../Interfaces/iuser';
import { IResponse } from '../../Interfaces/iresponse';
import { RouterService } from '../../../services/router.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-useradd',
  templateUrl: './useradd.component.html'
})

export class UseraddComponent implements OnInit {
  formUseradd: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private routerparent: RouterService,
    private companyservice: CompanyService,
    private _snackBar: MatSnackBar,
private userservice:UserService
  ) {
    this.formUseradd = formBuilder.group({
      idtype: ['C'],
      idnumber: ['', Validators.required],
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      telefono1: ['', Validators.required],
      email1: ['', Validators.required],
      profile: ['A', Validators.required],
      activo: [true] // new FormControl(false, [])
    });
  }
  selectedvalue: number; selectedview: string;
  idtypelist: IdatatableResponse[];
  profilelist: IdatatableResponse[];


  ngOnInit() {
    //para usar con router
    //let companyId = this.route.snapshot.paramMap.get("id");
    this.mensaje = "";
    //tipos de id
    this.idtypelist = [];
    let url = environment.urlAPI + 'api/datatable/';
    this.dataService.get<IdatatableResponse[]>(url + "IDTYP").subscribe(results => {
      if (results.ok) {

        for (const entry of results.body) {
          this.Addidtype({ id: entry.id, table: entry.table, code: entry.code, textvalue: entry.textvalue, numericvalue: entry.numericvalue, datevalue: entry.datevalue, boolvalue: entry.boolvalue });
        }
      }
    }, (e) => {
      console.log(e);
    });
    //perfiles
    this.profilelist = [];
    this.dataService.get<IdatatableResponse[]>(url + "PROFL").subscribe(results => {
      if (results.ok) {

        for (const entry of results.body) {
          this.Addprofile({ id: entry.id, table: entry.table, code: entry.code, textvalue: entry.textvalue, numericvalue: entry.numericvalue, datevalue: entry.datevalue, boolvalue: entry.boolvalue });
        }
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  Addidtype(V_idtype) {
      try {
        this.idtypelist.push(V_idtype);
      }
    catch(e) {
        console.log(e);
      }
    }

  Addprofile(V_profile) {
    try {
      this.profilelist.push(V_profile);
    }
    catch (e) {
      console.log(e);
    }
  }

  Grabar()
  {
    try {
      const iuseradd: IuserAdd = {
        idparent: this.companyservice.companyID,
        idtype: this.formUseradd.value.idtype,
        idnumber: this.formUseradd.value.idnumber,
          name: this.formUseradd.value.name,
          lastname: this.formUseradd.value.lastname,
        telephone1: this.formUseradd.value.telefono1,
        email1: this.formUseradd.value.email1,
        profile: this.formUseradd.value.profile,
        sysstatus: this.formUseradd.value.activo?"A":"I"
      };
      this.userservice.Add(iuseradd).subscribe(results => {
        if (results.ok) {
          this.openSB(results.body.response);
          this.router.navigate([this.routerparent.routerParent]);
        }
        else { alert("Error"); }
      },
        (e) => {
          //this.mensaje = e.error;
          alert(e.error);
          //console.log(e);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  Cancelar()
  {
          this.router.navigate([this.routerparent.routerParent]);
  }



  hasError(nombreControl: string, validacion: string) {
    const control = this.formUseradd.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


}
