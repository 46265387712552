import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { count, countpending, iGetTotalCount } from '../app/Interfaces/icount';
import { IgeneralResponse02, IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { GRReport } from '../app/Interfaces/iReport';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class CountService {
  private storage: any;

  countingID: number = 0;
  moduleCode: string;
  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(id,modulecode) {
    this.countingID = id;
    this.moduleCode = modulecode;
  }

  public SumLinesPending(PCountingId:number) {
    try {
      let url = this.baseUrl + 'api/count/v1/sumlinespending/';
      return this.dataService.get<IgeneralResponse03>(url+PCountingId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public UpdatePendingCount(PPCount: countpending)
  {
    try {
      let url = this.baseUrl + 'api/count/pending/';
      return this.dataService.post<IgeneralResponse03>(url, PPCount)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public GetDifferencesBetweenCounts(PCount: countpending)
  {
    try {
      let url = this.baseUrl + 'api/count/differencesallbnc/';
      return this.dataService.post<IgeneralResponse02>(url, PCount).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public CloseCountV2(PCount: count) {
    try {
      let url = this.baseUrl + 'api/count/closecountv2/';
      return this.dataService.put<IgeneralResponse02>(url,PCount)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public GetTotalCount(PGTC: iGetTotalCount) {
    try {
      let url = this.baseUrl + 'api/count/v1/totalcount/';
      return this.dataService.post<GRReport>(url,PGTC)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }
}
