import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppCustomerListService {

  customerName: string = "";
  idNumber: string = "";
  status: string = "";
  currentPage: number = 0;

  constructor() { }

  public putValues(pCustomerName: string, pIDNumber: string, pStatus: string, pCurrentPage: number) {
    this.customerName = pCustomerName;
    this.idNumber = pIDNumber,
      this.status = pStatus,
      this.currentPage = pCurrentPage;
  }

}

