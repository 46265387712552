import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PaymMethService } from '../../../services/paymmeth.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { PaymMethClass, PaymMethId } from '../../Interfaces/ipaymmeth';

@Component({
  selector: 'app-paymmethedit',
  templateUrl: './paymmethedit.component.html'
})

export class PaymMethEditComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  paymmethname: string = "";
  paymmethcode: string = "";
  paymMethClasses: PaymMethClass[] = [];

  constructor(
    private formBuilder: FormBuilder, 
    private securityService: SecurityService,
    private router: Router,
    private paymmethservice: PaymMethService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<PaymMethEditComponent>,
  ) {
    this.formedit = formBuilder.group({
      name: ['', Validators.required],
      class: [0, Validators.required],
      pmStatus: '',
      initials: ['', Validators.required],
      commissionpercentage: [0]
    })
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.paymmethname = this.paymmethservice.name;
    this.paymmethcode = this.paymmethservice.code;
    this.mensaje = "";
    this.paymmethservice.getClass().subscribe(result => {
      if (result.body.isValid) {
        for (const entry of result.body.resultData) {
          this.addPaymMethClass(entry);
        }
      }
    });
    this.paymmethservice.getPaymMethId(this.paymmethservice.id).subscribe(results => {
      if (results.body.isValid) {
        this.formedit.controls['name'].setValue(results.body.resultData.name);
        this.formedit.controls['class'].setValue(results.body.resultData.class);
        this.formedit.controls['pmStatus'].setValue((results.body.resultData.status=='A'?true:false));
        this.formedit.controls['initials'].setValue(results.body.resultData.initials);
        this.formedit.controls['commissionpercentage'].setValue(results.body.resultData.commissionPercentage);
      }
      else { this.mensaje = results.body.error.description; }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }


  Cancel() {
    this.dialogRef.close();
  }

  Save()
  {
    try {
      const pm:PaymMethId = {
        id: this.paymmethservice.id, name: this.formedit.value.name,
        class:this.formedit.value.class, status:(this.formedit.value.pmStatus?"A":"I"),
        code:"", commissionPercentage:this.formedit.value.commissionpercentage,
        initials: this.formedit.value.initials
      };
      this.paymmethservice.update(pm).subscribe(results => {
        if (results.ok) {
          this.openSB(results.body.resultData.description);
          this.dialogRef.close(true);
          //this.router.navigate(['paymmethlist']);
        }
        else { alert("Error"); }
      },
        (e) => {
          this.mensaje = e.error;
          console.log(e);
        }

      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  addPaymMethClass(pmc) {
    this.paymMethClasses.push(pmc);
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  seleccionar(item: { id: string })
  {
    //if (item["selected"] == true)  item["selected"] = false; 
    //else item["selected"] =true; 
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
