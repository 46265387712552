import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GRReport } from '../app/Interfaces/iReport';
import { iGetSellerSales } from '../app/Interfaces/iSeller';
import { environment } from '../environments/environment';
import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class SellerService {

  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  getSellerSales(p_Par: iGetSellerSales ) {
    try {
      //v1/sales/report/{pCCid}/{pDateStart}/{pDateEnd}/{pSellerId}/{pPASId}
      let url = this.baseUrl + 'api/seller/v1/sales/report/';
      return this.dataService.get<GRReport>(url+p_Par.CCId+"/"+p_Par.startDate+"/"+p_Par.endDate+"/0/0")
        .pipe(res => {
          return res
        });
    }
    catch (e) {
      console.log("Error");
    }
  }
}
