import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { copyCustomer, customerdocid, customerUpdate, getcustomerlist, ICustomerRegistry } from '../app/Interfaces/icustomer';
import { IgeneralResponse } from '../app/Interfaces/igeneralresponse';
import { grcustomerdetail, grcustomerlist, IgeneralResponse02 } from '../app/Interfaces/igeneralresponse02';
import { environment } from '../environments/environment';
import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  //private storage: any;

  customerID: number = 0;
  customerName: string = "";
  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(customerid, customername) {
    this.customerID = customerid;
    this.customerName = customername;
  }

  getCustomerListV1(pFilter: getcustomerlist) {
    try {
      let url = this.baseUrl + 'api/customer/list/v1/';
      return this.dataService.post<grcustomerlist>(url, pFilter)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }
  Copy(PCopy:copyCustomer) {
    try {
      let url = environment.urlAPI + 'api/customer/copy';
      return this.dataService.post<IgeneralResponse>(url,PCopy).pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  Registry(PReg: ICustomerRegistry) {
    try {
      let url = environment.urlAPI + 'api/customer/add';
      return this.dataService.post<IgeneralResponse>(url,PReg).pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  check( PIdType: string, PIdNumber: string, branchOffice: number) {
    try {
      const Iget: customerdocid = {
        branchOffice:0, idnumber:PIdNumber, idtype:PIdType
      };
      let url = environment.urlAPI + 'api/customer/docid/';
      return  this.dataService.post<IgeneralResponse02>(url, Iget).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  get(pId:number) {
    try {
      let url = environment.urlAPI + 'api/customer/v1/';
      return this.dataService.get<grcustomerdetail>(url + pId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getCustomerList() {
    try {
      let url = this.baseUrl + 'api/customer/list/v2/';
      return this.dataService.get<grcustomerlist>(url + '0')
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  putUpdate(pCU: customerUpdate) {
    try {
      let url = environment.urlAPI + 'api/customer/id/';
      console.log(pCU)
      return this.dataService.put<IgeneralResponse02[]>(url, pCU).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("error");
    }
  }
}
