import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../../../../services/data.service';
import { LocationService } from '../../../../services/location.service';
import { RouterService } from '../../../../services/router.service';
import { SecurityService } from '../../../../services/security.service';
import { SrvLocationService } from '../../../../services/SrvLocation.service';
import { UserService } from '../../../../services/user.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { Icc } from '../../../Interfaces/icc';
import { getLocationAssigned, locationsTiqueResponse, putUserLocation } from '../../../Interfaces/ilocation';
import { IuserPermissionsResponse } from '../../../Interfaces/iuser';

@Component({
  selector: 'app-locationTiqueAssign',
  templateUrl: './locationTiqueAssign.component.html'
})

export class LocationTiqueAssignComponent implements OnInit {

  formReport: FormGroup;
  subRef$: Subscription;
  r_gridregs: 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  DataGrid: locationsTiqueResponse[] = [];
  dataList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;
  moduleId: number= 6;
  filtermenu = { type: 'G' };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private routerparent: RouterService,
    private locationservice: LocationService,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private locationService: LocationService,
    private srvLocationService:SrvLocationService
  ) {
    this.formReport = formBuilder.group({
      cc_id: [null, Validators.required]
    });
    this.Columns = ['ccCode','zone','code','name','selected', ];
  }
  selectedvalue: number; selectedview: string;
  cc: Icc[];


  ngOnInit() {
    this.mensaje = "";
    //permisos
    //location
    const localist: getLocationAssigned = {
      ccId: 0,
      userId: this.userService.userID,
      type: "M",
      moduleId: this.moduleId
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.dataList = [];
    this.r_gridregs = 0;
    this.srvLocationService.Assigned(localist).subscribe(results => {
      if (!results.body.isValid)
        this.openSB("No hay datos");
      else
      {
        for (const entry of results.body.resultData) {
          this.addData({ id: entry.id,zone:entry.zone, code: entry.code, name: entry.name, ccCode: entry.ccCode, selected: entry.selected });
        }
        this.dataList = new MatTableDataSource<locationsTiqueResponse>(this.DataGrid);
        this.r_gridregs = this.dataList.data.length;
      }
    },
      (e) => {
        this.openSB(e.error);
        console.log(e);
      });
  }

  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
          this.MenuG.push(permission);
    }
    else {
      //switch (permission.code) {
      //  case "A": {
      //    this.Columns.push("b_add");
      //    break;
      //  }
      //}
    }
  }

  b_gen(link, id) {
        this.router.navigate([link]);
  }

  cancel() {
    this.router.navigate([this.routerparent.routerParent]);
  }

  save() {
    const putUL: putUserLocation[] = [];
    for (const entry of this.dataList.data) {
      putUL.push({userId:this.userService.userID, locationId:entry.id, selected:entry.selected, moduleId:this.moduleId})
    }
    this.srvLocationService.PutUserLocation(putUL).subscribe(_result => {
      if (_result.body.isValid) {
        this.openSB(_result.body.resultData.description);
        this.router.navigate([this.routerparent.routerParent]);
      }
      else {
        alert(_result.body.error.description);       
      }
    },
      (e) => {
      alert(e.error);
      console.log(e);
    });
  }

  select(item: { id: string }) {
    if (item["selected"] == true) item["selected"] = false;
    else item["selected"] = true;
  }

  allSelect(selected: boolean) {
      for (const entry of this.dataList.data) {
        entry.selected =selected;
      }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  //isAllSelected() {
  //  const numSelected = this.dataList.data.selected.length;
  //  const numRows = this.dataList.data.length;
  //  return numSelected === numRows;
  //}

  /** Selects all rows if they are not all selected; otherwise clear selection. */
 // masterToggle() {
 //   this.isAllSelected() ?
 //     this.dataList.data.selected.clear() :
 //     this.dataList.data.forEach(row => this.dataList.data.selected(row));
  //}

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
