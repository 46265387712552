import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SrvLocationService } from '../../../services/SrvLocation.service';
import { SystemInternalService } from '../../../services/systemInternal.service';
import { IPutServiceLocationV1 } from '../../Interfaces/ilocation';
import { IThemes } from '../../Interfaces/isysteminternal';

@Component({
  selector: 'app-customservlocation',
  templateUrl: './customservlocation.component.html'
})

export class CustomServLocationComponent implements OnInit {
  formedit: FormGroup;
  private sub: Subscription;
  private _Themes: IThemes[] = [];
  private _Theme: IThemes = {
    color01: "#71797a", color02: "#646a6b", color03: "", code: "", applyTo: "", color04: "", color05: "", idThemes: 0
  }

    ;
  private mensaje: string = "";
  url: string;
  locationId: number = 0;
  locationName: string = "";

  constructor(
    private dialogRef: MatDialogRef<CustomServLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private systemInternalService: SystemInternalService,
    //private locationService: LocationService,
    private srvLocationService: SrvLocationService,
    private securityParams: ActivatedRoute, private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) {
    this.formedit = formBuilder.group({
      name: ['', Validators.required],
      function_class: ['', Validators.required],
      themeId: [0, Validators.required],
userIdAttends:[0],
    })
  }

  ngOnInit(): void {
    //this.locationId = parseInt(this.securityParams.snapshot.params.locationId);
    this._Themes = [];
    this.locationId = this.data;

    this.loadContent();
  }

  loadContent() {
    this.systemInternalService.GetTheme("LOCSV").subscribe(result => {
      if (result.body.isValid) {
        for (const entry of result.body.resultData) {
          this.addTheme(entry);
        }
      }
      else {
        alert(result.body.error.description);
      }
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      }
    });
    this.srvLocationService.GetServiceLocation(this.locationId).subscribe(result => {
      if (result.body.isValid) {
        this.formedit.controls['name'].setValue(result.body.resultData.name);
        this.formedit.controls['function_class'].setValue(result.body.resultData.function_class);
        this.formedit.controls['themeId'].setValue(result.body.resultData.themeId);
        this.formedit.controls['userIdAttends'].setValue(result.body.resultData.userIdAttends);
        this.locationName = result.body.resultData.name;
        this.selectTheme(result.body.resultData.themeId);
      }
    })
  }

  addTheme(PTh) {
    try {
      this._Themes.push(PTh);
    } catch (e) { console.log(e); }
  }

  selectTheme(PthId) {
    if (PthId != null && PthId > 0) {
      var _indexTheme = this._Themes.find(x => x.idThemes == PthId);
      this._Theme = { color01: _indexTheme.color01, color02: _indexTheme.color02, color03: _indexTheme.color03, code: _indexTheme.code, applyTo: "", color04: "", color05: "", idThemes: _indexTheme.idThemes }
    }
    else {
      var _indexTheme = this._Themes[0];
      this._Theme = { color01: _indexTheme.color01, color02: _indexTheme.color02, color03: _indexTheme.color03, code: _indexTheme.code, applyTo: "", color04: "", color05: "", idThemes: _indexTheme.idThemes }
      this.formedit.controls['themeId'].setValue(_indexTheme.idThemes);

    }
  }

  changeTheme(PthId)
  {
    var _length = this._Themes.length;
    var _indexTheme = this._Themes.find(x => x.idThemes == PthId );
    var _index = this._Themes.indexOf( _indexTheme);
    if (_index == _length - 1) {
      _index = 0;
    }
    else {
      _index++;
    }
    var _indexTheme = this._Themes[_index];
    this._Theme = { color01: _indexTheme.color01, color02: _indexTheme.color02, color03: _indexTheme.color03, code: _indexTheme.code, applyTo: "", color04: "", color05: "", idThemes: _indexTheme.idThemes }
    this.formedit.controls['themeId'].setValue(_indexTheme.idThemes);
  }

  closeWithoutSave() {
    this.dialogRef.close(false);
  }

  //.pipe(finalize(()=>this.closeall()))
  save() {
    const _SL: IPutServiceLocationV1 = {
      id: this.locationId, name: this.formedit.get("name").value, themeId: this.formedit.get("themeId").value, userIdAttends: this.formedit.get("userIdAttends").value
    }
     this.srvLocationService.PutServiceLocation(_SL).subscribe(result => {
      if (result.body.isValid) {
        this.openSB(result.body.resultData.description);
        this.closeall();
      }
      else {
        alert(result.body.error.description);
      }
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      }
    });
  }

  closeall() {
    var _indexTheme = this._Themes.find(x => x.idThemes == this.formedit.get("themeId").value);

    this.dialogRef.close(_indexTheme);

  }
      
   openSB(msg) {
      this._snackBar.open(msg, '', {
        duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
      });
    }

}
