import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { IGRPASInProduction, IGRPASOptions, IOrderId } from '../app/Interfaces/iproduction';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class ProductionService {
  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public GetPASOptions(PTextOption: string) {
    try {
      let url = this.baseUrl + 'api/production/v1/pasoptions/';
      if (PTextOption.length == 0) PTextOption = "*";
      return this.dataService.get<IGRPASOptions>(url+PTextOption.trim()).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public PutOrderOut(POrderId: IOrderId) {
    try {
      let url = this.baseUrl + 'api/production/v1/commander/orderOut/';
      return this.dataService.put<IgeneralResponse03>(url, POrderId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public PutOrderIn(POrderId: IOrderId) {
    try {
      let url = this.baseUrl + 'api/production/v1/commander/orderIn/';
      return this.dataService.put<IgeneralResponse03>(url, POrderId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public PutOrderinProduction(POrderId: IOrderId) {
    try {
      let url = this.baseUrl + 'api/production/v1/commander/orderinproduction/';
      return this.dataService.put < IgeneralResponse03 > (url, POrderId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public GetPASinProduction(PLocationId: number) {
    try {
      let url = this.baseUrl + 'api/production/v1/commander/inproduction/';
      return this.dataService.get<IGRPASInProduction>(url + PLocationId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }




}
