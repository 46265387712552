import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CcService } from '../../../../services/cc.service';
import { DataService } from '../../../../services/data.service';
import { LocationService } from '../../../../services/location.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { startcount } from '../../../Interfaces/icc';
import { IgeneralResponse03 } from '../../../Interfaces/igeneralresponse02';

@Component({
  selector: 'app-countstart',
  templateUrl: './countstart.component.html'
})

export class CountstartComponent implements OnInit {
  formadd: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  msgtype: string;
  v_ccid:number;
  r_ccregs: 0;
  typeselected: string;
  types: string[] = ['Parcial', 'Total'];

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private ccService: CcService,
    private securityService: SecurityService,
    private router: Router,
    private locationservice: LocationService,
    private _snackBar: MatSnackBar
  ) {
    this.formadd = formBuilder.group({
      type: [null, Validators.required],
    });
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    this.msgtype = "msg-notify"
  }

  counttype(type) {
    switch (type)
    {
      case "Parcial":
        this.mensaje = "Únicamente los PA que relacione se afectarán, los no contabilizados permanecen con su inventario actual.";
        break;
      case "Total":
        this.mensaje = "El conteo se debe realizar de todos los PA en el CC, los no contabilizados se ajustarán como 0. Durante el proceso no se podra realizar ninguna transacción que afecte el inventario.";
        break;
    }
  }

  Start()
  {
    this.v_ccid = this.ccService.ccID;
    try {
      const isc:startcount = {
        ccid: this.ccService.ccID,
        type: (this.formadd.value.type == "Parcial" ? "P" : "T"),
        moduleCode:"ARION"
      };
      const url = environment.urlAPI + 'api/count/startcount/';
      this.dataService.put<IgeneralResponse03>(url,isc).subscribe(results => {
        if (results.body.isValid) {
          this.openSB(results.body.resultData.description);
          this.router.navigate(['/ccselect']);
        }
        else { alert(results.body.error.description); }
      },
        (e) => {
          alert(e.error);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  Cancel()
  {
          this.router.navigate(['/ccselect']);
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formadd.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


}
