import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { LocationService } from '../../../services/location.service';
import { PrdLocationService } from '../../../services/prdLocation.service';
import { ProductionService } from '../../../services/production.service';
import { RouterService } from '../../../services/router.service';
import { SrvLocationService } from '../../../services/SrvLocation.service';
import { SystemInternalService } from '../../../services/systemInternal.service';
import { IGetConfigurationCodeV3 } from '../../Interfaces/iconfig';
import { IOrderId, IPASInProduction } from '../../Interfaces/iproduction';

declare interface Location {
  locationName: string;
  userName: string;
  difference: string;
  differenceMinutes: number;
  percentage: number;
  class: string;
}

@Component({
  selector: 'kds-data',
  templateUrl: './kds.component.html'
})

export class KdsComponent {
  constructor(
    private router: Router,
    private locationService: LocationService,
    private securityParams: ActivatedRoute,
    private productionService: ProductionService,
    private prdLocationService: PrdLocationService,
    private srvLocationService: SrvLocationService,
    private _snackBar: MatSnackBar,
    private routerService: RouterService,
    private systemInternalService: SystemInternalService
  ) {
    this._timer = setInterval(() => this.loadServiceLocations(), 15000);
    this.SetIntervalProduction() /* this._productionTimer = setInterval(() => this.loadPASInProduction(), 11000)*/
  }

  menuLocations: any[];
  _result: boolean = false;
  locations: Location[];
  menuPasIn: IPASInProduction[];
  menuPasInProduction: IPASInProduction[];
  menuPasDeleted: IPASInProduction[];
  locationId: number;
  centerName: string;
  registryTimeout: number;
  messageLocations: string = "";
  _timer: any;
  _productionTimer: any;
  _buttonPrdToInTimer: any;
  _ClickProductionModeNext: boolean = true;
  _FontSizeTitle: string = "15px";
  _FontSizeSubtitle: string = "13px";
  _FontSizeComment: string = "13px";
  _PasInFilter: number = 0;
  _IntervalProductionOn: boolean = true;


  async ngOnInit() {
    //var processData = angular.module('library.process_data', ['blockUI']);
    const _Config: IGetConfigurationCodeV3 = {
      code: "KDSFNTSIZE", parentCode: "KDSA"
    }
    this.systemInternalService.GetConfiguration(_Config).subscribe(result => {
      if (result.body.isValid) {
        if (result.body.resultData.numbervalue >= 15 && result.body.resultData.numbervalue <= 30) {
          this._FontSizeTitle = result.body.resultData.numbervalue.toString().trim() + "px";
          this._FontSizeSubtitle = (result.body.resultData.numbervalue - 2).toString() + "px";
          this._FontSizeComment = (result.body.resultData.numbervalue - 4).toString() + "px";
        }
      }
    },
      (e) => {
        console.log(e);
      });
    this.locationId = parseInt(this.securityParams.snapshot.params.locationid);
    this.prdLocationService.GetProductionDataV2(this.locationId).subscribe(result => {
      if (result.body.isValid) {
        this.centerName = result.body.resultData.name; this.registryTimeout = result.body.resultData.registryTimeout;
      }
      else {
        alert(result.body.error.description);
      }
    },
      (e) => {
        console.log(e);
      });
    this.locations = [];
    this.menuLocations = [];
    this.loadServiceLocations();
    this.loadPASInProduction();
    //
    this.menuPasIn = [];
    this.menuPasInProduction = [];
    this.menuPasDeleted = [];
    //this.pasIn = [];
    //this.pasInProduction = [];


  }

  ngOnDestroy(): void {
    clearInterval(this._timer);
    this.ClearIntervalProduction();
    //clearTimeout(this._productionTimer);

  }

  SetIntervalProduction() {
    this._IntervalProductionOn = true;
    //this._productionTimer = setInterval(() => this.loadPASInProduction(), 11000)
    this._productionTimer = setInterval(() => {
      this.loadPASInProduction();
    }, 15000, 15000);

  }

  ClearIntervalProduction() {
    this._IntervalProductionOn = false;
    //clearTimeout(this._productionTimer);
    if (this._productionTimer) {
      clearInterval(this._productionTimer);
    }
  }

  private async loadServiceLocations() {
    this.srvLocationService.GetServiceLocationsInProduction(this.locationId).subscribe(result => {
      if (result.body.isValid) {
        this.locations = [];
        this.menuLocations = [];
        for (const entry of result.body.resultData) {
          this.addLocations({
            locationName: entry.locationName.substring(0, 20).trim(), userName: entry.userName.substring(0, 20).trim(), difference: entry.difference.substring(0, 20),
            differenceMinutes: entry.differenceMinutes, percentage: entry.percentage, class: entry.function_class, color01: entry.color01, color02: entry.color02
          });
        }
        this.menuLocations = this.locations; //.filter(menuLocation => menuLocation);
        this.messageLocations = "";

      }
      else {
        if (result.body.error.code == 204) {
          this.locations = [];
          this.menuLocations = [];
          this.messageLocations = result.body.error.description;
        }
        else {
          alert(result.body.error.description);
        }
      }
    },
      (e) => {
        console.log(e);
      });
    return this.locations;
  }

  private async loadPASInProduction() {
    //if (!this._IntervalProductionOn) {
    //  return;
    //}
    //this.ClearIntervalProduction();
    //this.productionService.GetPASinProduction(this.locationId).subscribe(result => {
    this.productionService.GetPASinProduction(this.locationId) //.pipe(finalize(() => this.SetIntervalProduction()))
      .subscribe(result => {
        if (result.body.isValid) {
          //if (this._IntervalProductionOn) {
            console.log("...");
            for (const entry of result.body.resultData) {
              let _StatusOri = entry.status;
              if (entry.parentId > 0 || (entry.compType == 0)) {
                var _Item = this.menuPasIn.find(x => x.id == entry.id);
                if (_Item) {
                  _StatusOri = "R";
                }
                else {
                  _Item = this.menuPasInProduction.find(x => x.id == entry.id);
                  if (_Item) {
                    _StatusOri = "P";
                  }
                  else {
                    _Item = this.menuPasDeleted.find(x => x.id == entry.id);
                    if (_Item) {
                      _StatusOri = "D";
                    }
                  }
                }
                if (_Item) {
                  _Item.status = entry.status;
                  _Item.difference = entry.difference.substring(0, 20);
                  _Item.differenceMinutes = entry.differenceMinutes;
                  _Item.onTime = entry.onTime; _Item.color01 = entry.color01; _Item.color02 = entry.color02, _Item.color03 = entry.color03;
                  //  if (entry.parentId > 0) {
                  //    switch (_StatusOri) {
                  //      case "R":
                  //        var _ItemParentF = this.menuPasIn.find(x => x.id == entry.parentId);
                  //        break;
                  //      case "P":
                  //        var _ItemParentF = this.menuPasInProduction.find(x => x.id == entry.parentId);
                  //        break;
                  //    }
                  //    if (_ItemParentF) {
                  //      _ItemParentF.difference = _Item.difference.substring(0, 20);
                  //      _ItemParentF.differenceMinutes = _Item.differenceMinutes;
                  //      _ItemParentF.onTime = _Item.onTime;
                  //      /*_Item.color01 = entry.color01; _Item.color02 = entry.color02, _Item.color03 = entry.color03;*/

                  //    }
                  //  }
                }
                else {
                  switch (entry.status) {
                    case "R":
                      this.addPASIn(entry);
                      break;
                    case "P":
                      this.addPASInProduction(entry);
                      break;
                  }
                  if (entry.parentId > 0) {
                    switch (_StatusOri) {
                      case "R":
                        var _ItemParent = this.menuPasIn.find(x => x.id == entry.parentId);
                        if (!_ItemParent) {
                          var _ItemParent = result.body.resultData.find(x => x.id == entry.parentId && x.status == entry.status);
                          this.addPASInContainer(_ItemParent);
                        }
                        break;
                      case "P":
                        var _ItemParent = this.menuPasInProduction.find(x => x.id == entry.parentId);
                        if (!_ItemParent) {
                          var _ItemParent = result.body.resultData.find(x => x.id == entry.parentId && x.status == entry.status);
                          this.addPASInProductionContainer(_ItemParent);
                        }
                        break;
                    }
                  }
                }
              }
              else {
                switch (entry.status) {
                  case "R":
                    var _Item = this.menuPasIn.find(x => x.id == entry.id && x.status == entry.status);
                    if (_Item) {
                      _Item.difference = entry.difference.substring(0, 20);
                      _Item.differenceMinutes = entry.differenceMinutes;
                      _Item.onTime = entry.onTime; _Item.color01 = entry.color01; _Item.color02 = entry.color02, _Item.color03 = entry.color03;
                    }
                    break;
                  case "P":
                    var _Item = this.menuPasInProduction.find(x => x.id == entry.id && x.status == entry.status);
                    if (_Item) {
                      _Item.difference = entry.difference.substring(0, 20);
                      _Item.differenceMinutes = entry.differenceMinutes;
                      _Item.onTime = entry.onTime; _Item.color01 = entry.color01; _Item.color02 = entry.color02, _Item.color03 = entry.color03;
                    }
                    break;

                }
              }
            }
            //if (this._IntervalProductionOn) {
            //this.menuPasIn = this.pasIn; //.filter(pasIn => pasIn.parentId==0);
            //this.menuPasInProduction = this.pasInProduction; //.filter(pasInProduction=> pasInProduction)
          //}
        }
        else {
          if (result.body.error.code == 204) {
            //if (this._IntervalProductionOn) {

            //this.pasIn = [];
            this.menuPasIn = [];
            //this.pasInProduction = [];
            this.menuPasInProduction = [];
            //}
          }
          else {
            alert(result.body.error.description);
          }
        }
      },
        (e) => {
          console.log(e);
        });

    return this.locations;
  }

  addLocations(route) {
    this.locations.push(route);
  }

  addPASInContainer(route) {
      this.menuPasIn.push({
        id: route.id, color01: route.color01, color02: route.color02, color03: route.color03, compType: route.compType, difference: route.difference.substring(0, 20),
        differenceMinutes: route.differenceMinutes, function_class: route.function_class, locationName: route.locationName.substring(0, 20).trim(), onTime: route.onTime,
        parentId: route.parentId, pasNameUm: route.pasNameUm, quantity: route.quantity, requestOrder: route.requestOrder, status:"R", textOptions: route.textOptions
      });
  }

  addPASInProductionContainer(route) {
    this.menuPasInProduction.push({
      id: route.id, color01: route.color01, color02: route.color02, color03: route.color03, compType: route.compType, difference: route.difference.substring(0, 20),
      differenceMinutes: route.differenceMinutes, function_class: route.function_class, locationName: route.locationName.substring(0, 20).trim(), onTime: route.onTime,
      parentId: route.parentId, pasNameUm: route.pasNameUm, quantity: route.quantity, requestOrder: route.requestOrder, status: "P", textOptions: route.textOptions
    });
  }


  addPASIn(route) {
    //this.pasIn.push(route);
    route.locationName = route.locationName.substring(0, 20).trim();
    route.difference = route.difference.substring(0, 20),
      this.menuPasIn.push(route);
  }

  addPASInProduction(route) {
    //this.pasInProduction.push(route);
    route.locationName = route.locationName.substring(0, 20).trim();
    route.difference = route.difference.substring(0, 20),

      this.menuPasInProduction.push(route);
  }

  filterPasInGroups() {
    try {
      if (this.menuPasIn.length > 0) {
        return this.menuPasIn.filter(x => x.parentId == 0);
      }
      else {
        return this.menuPasIn;

      }
    } catch {
      return null;
    }
  }

  filterPasIn(idparent) {
    return this.menuPasIn.filter(x => x.parentId == idparent);
  }

  filterPasPrdGroups() {
    try {
      if (this.menuPasInProduction.length > 0) {
        return this.menuPasInProduction.filter(x => x.parentId == 0);
      }
      else {
        return this.menuPasInProduction;

      }
    } catch {
      return null;
    }
  }

  //return this.menuPasInProduction.filter(x => x.parentId == 0);

  filterPasPrd(idparent) {
    return this.menuPasInProduction.filter(x => x.parentId == idparent);
  }

  MoveScreenItemToProduction(Id) {
    //drop parent container
    var _Item = this.menuPasIn.find(x => x.id == Id);
    if (_Item && _Item.parentId > 0) {
      var _Children = this.menuPasIn.filter(x => x.parentId == _Item.parentId);
      var _ItemParent = this.menuPasInProduction.find(x => x.id == _Item.parentId);
      if (!_ItemParent) {
        var _ItemParent = this.menuPasIn.find(x => x.id == _Item.parentId);
        if (_ItemParent) {
          //_ItemParent.status = "P";
          this.addPASInProductionContainer(_ItemParent);
        };
      }
      if (_Children.length == 1) {
        this.menuPasIn = this.menuPasIn.filter(x => x.id != _Item.parentId);
      }
    }
    //items in
    var _Items = this.menuPasIn.filter(x => x.id == Id || x.parentId == Id);
    for (const _Item of _Items) {
      if (_Item.compType > 0) {
        var _Parentfound = this.menuPasInProduction.find(x => x.id == _Item.id);
        if  (!_Parentfound) {
          //_Item.status = "P";
          this.addPASInProductionContainer(_Item);
        }
      }
      else {
        this.menuPasInProduction.push(_Item);
      }
    }
    //drop item and its childrens
    this.menuPasIn = this.menuPasIn.filter(x => x.id != Id && x.parentId != Id);
    return _Items;
  }

  b_OrderToProduction(PId, e: Event) {
    e.stopPropagation();
    //this.ClearIntervalProduction();
    let _Count = 0;
    var _Items = this.MoveScreenItemToProduction(PId);
    for (const _Item of _Items) {
      const _OP: IOrderId = {
        orderId: _Item.id
      }
      this.productionService.PutOrderinProduction(_OP).pipe(finalize(() => this.OTPFinish(_Items, PId, _Count)))
        .subscribe(result => {
          if (result.body.isValid) {
            _Count++;
          }
          else {
            alert(result.body.error.description);
          }
        },
          (e) => {
            console.log(e);
          });
    }
  }

  OTPFinish(PItems: any[], PId, PCount) {
    //if (PCount == PItems.length) {
    this.loadPASInProduction();
    //}
  }

  MoveScreenItemToIn(Id) {
    //drop parent container
    var _Item = this.menuPasInProduction.find(x => x.id == Id);
    if (_Item && _Item.parentId > 0) {
      var _Children = this.menuPasInProduction.filter(x => x.parentId == _Item.parentId);
      var _ItemParent = this.menuPasIn.find(x => x.id == _Item.parentId);
      if (! _ItemParent) {
        var _ItemParent = this.menuPasInProduction.find(x => x.id == _Item.parentId);
        if(_ItemParent) {
          //_ItemParent.status = "R";
          this.addPASInContainer(_ItemParent);
        };
      }
      if (_Children.length == 1) {
        this.menuPasInProduction = this.menuPasInProduction.filter(x => x.id != _Item.parentId);
      }
    }
    //items in
    var _Items = this.menuPasInProduction.filter(x => x.id == Id || x.parentId == Id);
    for (const _Item of _Items) {
      if (_Item.compType > 0) {
        var _Parentfound = this.menuPasIn.find(x => x.id == _Item.id);
        if (!_Parentfound) {
          //_Item.status = "R";
          this.addPASInContainer(_Item);
        }
      }
      else {
        this.menuPasIn.push(_Item);
      }
    }
    //drop item and its childrens
    this.menuPasInProduction = this.menuPasInProduction.filter(x => x.id != Id && x.parentId != Id);
    return _Items;
  }

  b_OrderToIn(PId) {
    let _Count = 0;
    var _Items = this.MoveScreenItemToIn(PId);
    for (const _Item of _Items) {
      const _OP: IOrderId = {
        orderId: _Item.id
      }
      this.productionService.PutOrderIn(_OP).pipe(finalize(() => this.OTIFinish(_Items, PId, _Count)))
        .subscribe(result => {
          if (result.body.isValid) {
            _Count++;
          }
          else {
            alert(result.body.error.description);
          }
        },
          (e) => {
            console.log(e);
          });
    }
  }

  OTIFinish(PItems: any[], PId, PCount) {
    //if (PCount == PItems.length) {
    this.loadPASInProduction();
    //}
  }

  MoveScreenItemToOut(Id) {
    //drop parent container
    var _Item = this.menuPasInProduction.filter(x => x.id == Id);
    if (_Item[0].parentId > 0) {
      var _Items = this.menuPasInProduction.filter(x => x.parentId == _Item[0].parentId);
      if (_Items.length == 1) {
        this.menuPasInProduction = this.menuPasInProduction.filter(x => x.id != _Item[0].parentId);
      }
    }
    var _Items = this.menuPasInProduction.filter(x => x.id == Id || x.parentId == Id);
    for (const _Item of _Items) {
          this.menuPasDeleted.push(_Item);
    }
    //drop item and its childrens
    this.menuPasInProduction = this.menuPasInProduction.filter(x => x.id != Id && x.parentId != Id);
    return _Items;
  }

  b_OrderToOut(PId) {
    let _Count = 0;
    //var _Items = this.menuPasInProduction.filter(x => x.id == PId || x.parentId == PId);
    var _Items = this.MoveScreenItemToOut(PId);
    //this.menuPasInProduction = this.menuPasInProduction.filter(x => x.id != PId && x.parentId != PId)
    for (const _Item of _Items) {
      const _OP: IOrderId = {
        orderId: _Item.id
      }
      this.productionService.PutOrderOut(_OP).pipe(finalize(() => this.OTIFinish(_Items, PId, _Count)))
        .subscribe(result => {
          if (result.body.isValid) {
            _Count++;
          }
          else {
            alert(result.body.error.description);
          }
        },
          (e) => {
            console.log(e);
          });
    }
  }

  b_ProcessOrderInProduction(Pid, e: Event) {
    e.stopPropagation();
    //if  (!this._IntervalProductionOn) {
    //  this.openSB("Intentelo de nuevo");
    //  return;
    //}

    //this.ClearIntervalProduction();
    if (this._ClickProductionModeNext) {
      this.b_OrderToOut(Pid);
    }
    else {
      this.b_OrderToIn(Pid);
      this.b_ClickProductionMode();
    }
  }

  b_ClickProductionMode() {
    this._ClickProductionModeNext = !this._ClickProductionModeNext;
    if (!this._ClickProductionModeNext) {
      //this._buttonPrdToInTimer = setInterval(() => this.ResetButtonPrdToIn(), 5000)
      this.openSB("Seleccione la orden a devolver.")
    }
  }

  ResetButtonPrdToIn() {
    this._ClickProductionModeNext = true;
    clearInterval(this._buttonPrdToInTimer);
  }

  b_back() {
    this.router.navigate([this.routerService.routerParent]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }
}






