import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomerService } from '../../../services/customer.service';
import { DataService } from '../../../services/data.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { grInvoicesSPAS, grpasdataResponse } from '../../Interfaces/igeneralresponse02';
import { get_invoicesSPAS, IinvoicesSPAS } from '../../Interfaces/iinvoice';
import { pasdataResponse } from '../../Interfaces/ipas';

@Component({
  selector: 'app-supervisedpas01',
  templateUrl: './supervisedpas01.component.html',
    styleUrls: ['./supervisedpas01.component.css']
})

export class supervisedpas01Component implements OnInit {

  formReport: FormGroup;
  subRef$: Subscription;

  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  invoices: IinvoicesSPAS[] = [];
  dSInvoices = null;  //data source

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private router: Router,
    private securityService: SecurityService,
    private customerService: CustomerService
  ) {
    this.formReport = formBuilder.group({
      id: [null, Validators.required],
      date: [null, Validators.required],
      enddate: [null, Validators.required],
    });
    this.Columns = ['ccName','date','prefix','invoiceNumber','pasCode','pasFullName', 'quantity', 'netPrice'];
  }
  pas: pasdataResponse[];
  selectedvalue: number; selectedview: string;
  r_invoices: 0;
  secondtitle: string;

  ngOnInit() {
    this.secondtitle = this.customerService.customerName;
    const url = environment.urlAPI + 'api/pas/supervised/';
    this.pas = []; 
    this.dataService.get<grpasdataResponse>(url).subscribe(results => {
      for (const entry of results.body.resultData) {
        this.addPas({id: entry.id,code:entry.code,name: entry.name });
      }
    }, (e) => {
      console.log(e);
    });
    this.addPas({id:0, code:'',name: "Todos" });
  }

  Filter() {
    this.mensaje = "";
    const url = environment.urlAPI + 'api/invoice/supervisedpas/v1/';
    this.invoices = [];
    this.r_invoices = 0;
    const gisp: get_invoicesSPAS = {
      pasId: this.formReport.value.id,
      date: this.formReport.value.date,
      endDate: this.formReport.value.enddate,
      customerId: this.customerService.customerID
    };
    this.dataService.post<grInvoicesSPAS>(url,gisp).subscribe(results => {
      if (! results.body.isValid && results.body.resultCode == 200)
        this.mensaje = "No hay datos";
      for (const entry of results.body.resultData) {
        this.addInvoice({
          id: entry.id, ccName: entry.ccName, date: entry.date,prefix:entry.prefix,
          invoiceNumber: entry.invoiceNumber,pasCode:entry.pasCode,pasFullName: entry.pasFullName,quantity:entry.quantity,
          netPrice: entry.netPrice});
      }
      this.dSInvoices = new MatTableDataSource<IinvoicesSPAS>(this.invoices);
      this.r_invoices = this.dSInvoices.data.length;
    },
      (e) => {
        this.mensaje =e.error;
        console.log(e);
      });
  }

  addInvoice(invoice) {
      this.invoices.push(invoice);
  }

  addPas(vp_pas) {
    try {
      this.pas.push(vp_pas);
    }
    catch (e) {
      console.log(e);
    }
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formReport.get(nombreControl);
    return control.hasError(validacion);
  }

  getTotalquantity() {
    return this.dSInvoices.data.map(t => t.quantity).reduce((acc,quantity) => acc +quantity, 0);
  }
  getTotalnetPrice() {
    return this.dSInvoices.data.map(t => t.netPrice).reduce((acc,netPrice) => acc +netPrice, 0);
  }
  b_back() {
    if (this.customerService.customerID==0)
      this.router.navigate(['informes']);
    else
      this.router.navigate(['custmore']);
  }



}
