import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataService } from '../../../services/data.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { UserService } from '../../../services/user.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IResponse } from '../../Interfaces/iresponse';
import { Ipassword } from '../../Interfaces/iuser';

@Component({
  selector: 'app-pswedit',
  templateUrl: './pswedit.component.html'
})

export class PsweditComponent implements OnInit {
  formPswedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  msgtype: string = "";
  username = this.userservice.userName;

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private routerparent: RouterService,
    private userservice: UserService,
    private _snackBar: MatSnackBar
  ) {
    this.formPswedit = formBuilder.group({
      psw1: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
      psw2: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]]
    });
  }
  selectedvalue: number; selectedview: string;


  ngOnInit() {
    this.mensaje = "La contrasena debe tener al menos 6 caracteres de longitud";
    this.msgtype = "msg-notify"

  }

  Grabar()
  {
    if (this.formPswedit.value.psw1 != this.formPswedit.value.psw2)
      alert("Error en la confirmación de la contraseña");
    else {
      try {

        const ip: Ipassword = {
          id: this.userservice.userID,
          password: this.formPswedit.value.psw1
        };
        let url = environment.urlAPI + 'api/user/password';
        this.dataService.put<IResponse>(url, ip).subscribe(results => {
          if (results.ok) {
            this.openSB(results.body.response);
            this.router.navigate([this.routerparent.routerParent]);
          }
          else {
            alert(results.body.response);
          }
        },
          (e) => {
            alert(e.error);
          });
      }
      catch (e) {
        console.log(e.message);
        alert(e.message);
      }
    }
  }

  Cancelar()
  {
          this.router.navigate([this.routerparent.routerParent]);
  }



  hasError(nombreControl: string, validacion: string) {
    const control = this.formPswedit.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


}
