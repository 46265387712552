import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IccAdd } from '../../Interfaces/icc';
import { IdatatableResponse } from '../../Interfaces/idatatable';
import { IResponse } from '../../Interfaces/iresponse';

@Component({
  selector: 'app-ccadd',
  templateUrl: './ccadd.component.html'
})

export class CcAddComponent implements OnInit {
  formCcadd: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private companyservice: CompanyService,
    private _snackBar: MatSnackBar
  ) {
    this.formCcadd = formBuilder.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      telephone1: ['', Validators.required],
      email1: ['', Validators.required],
      address1: ['', Validators.required],
      type: ['', Validators.required],
    });
  }
  typelist: { type: string; description: string; }[];


  ngOnInit() {
    this.mensaje = "";
    //tipos de id
    this.typelist = [];
    let url = environment.urlAPI + 'api/datatable/';
    this.dataService.get<IdatatableResponse[]>(url + "CCTYP").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.typelist.push({ type: entry.code, description: entry.textvalue });
        }
      }
    }, (e) => {
      console.log(e);
    });
  }

  Grabar()
  {
    try {
      //name: this.companyservice.companyID,

      const icc: IccAdd = {
        idparent: this.companyservice.companyID,
        code: this.formCcadd.value.code,
        name: this.formCcadd.value.name,
        telephone1: this.formCcadd.value.telephone1,
        email1: this.formCcadd.value.email1,
        address1: this.formCcadd.value.address1,
        type: this.formCcadd.value.type
      };
      const url = environment.urlAPI + 'api/cc/';
      this.dataService.post<IResponse>(url,icc).subscribe(results => {
        if (results.ok) {
          this.openSB(results.body.response);
          this.router.navigate(['/cclist']);
        }
        else { alert("Error"); }
      },
        (e) => {
          alert(e.error);
        }

      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  Cancelar()
  {
          this.router.navigate(['/cclist']);
  }



  hasError(nombreControl: string, validacion: string) {
    const control = this.formCcadd.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


}
