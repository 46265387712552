import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
//import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
//import { DataTableService } from '../../../services/dataTable.service';
//import { ProviderService } from '../../../services/provider.service';
//import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../error-state-matcher1';
//import { IdatatableResponse } from '../../Interfaces/idatatable';
//import { IProviderRegistry } from '../../Interfaces/iprovider';
import { listTrnStage, listTrnStatus, listTrnTechProvider } from '../Interfaces/itransaction';

@Component({
  selector: 'app-transactionadd',
  templateUrl: './transactionadd.component.html',
})

export class TransactionAddComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  customername: string = "";
  //idtypelist: IdatatableResponse[];
  //persontypelist: IdatatableResponse[];
  //departmentlist: IdatatableResponse[];
  //countrylist: IdatatableResponse[];
  //citylist: IdatatableResponse[];
  //citylistfilter: IdatatableResponse[];
  //taxresponsibilitieslist: IdatatableResponse[];
  //taxschemelist:IdatatableResponse[]

  constructor(
    private formBuilder: FormBuilder, 
    //private securityService: SecurityService,
    private router: Router,
    //private providerService: ProviderService,
    //private datatableservice: DataTableService,
    private dialogRef: MatDialogRef<TransactionAddComponent>,
    private _snackBar: MatSnackBar
  )
  {
    this.formedit = formBuilder.group({
      prefix: ['', [Validators.required, Validators.maxLength(10)]],
      authorization: ['', [Validators.required, Validators.maxLength(30)]],
      numberlimitlower: ['', [Validators.required]],
      numberlimitupper: ['', [Validators.required]],
      number: ['', [Validators.required]],
      datelimitlower: [Date.now, [Validators.required]],
      datelimitupper: [Date.now, [Validators.required]],
      text01: [''],
      key01: ['', Validators.required],
      imc: [false],
      techprovider: [1, [Validators.required]],
      stage: [1, [Validators.required]],
      status: [1, [Validators.required]]
    });
  }
  selectedvalue: number; selectedview: string;
  listStatus: listTrnStatus[] = [{ code: 1, name: 'Activo' }, { code: 2, name: 'Inactivo' }];
  listStage: listTrnStage[] = [{ code: 1, name: 'NA' }, { code: 2, name: 'Pruebas' }, { code: 3, name: 'Habilitación' }, { code: 4, name: 'Producción' }]; /*, { code:3, name: 'No aplica' }*/
  listTechProvider: listTrnTechProvider[] = [{ code: 1, name: 'NA' }, { code: 2, name: 'GSIGMA-SPH' }, { code: 3, name: 'GSIGMA-F1' }];


  ngOnInit() {
    ////Id types
    //this.idtypelist = [];
    //this.datatableservice.getDataList("IDTYP").subscribe(results => {
    //  if (results.ok) {
    //    for (const entry of results.body) {
    //      this.idtypelist.push(entry);
    //    }
    //  }
    //}, (e) => {
    //  console.log(e);
    //});
    ////Department list
    //this.departmentlist = [];
    //this.datatableservice.getDataList("DEPTO").subscribe(results => {
    //  if (results.ok) {
    //    for (const entry of results.body) {
    //      this.departmentlist.push(entry);
    //    }
    //  }
    //}, (e) => {
    //  console.log(e);
    //});
    //Country list
    //this.countrylist = [];
    //this.datatableservice.getDataList("CNTRY").subscribe(results => {
    //  if (results.ok) {
    //    for (const entry of results.body) {
    //      this.countrylist.push(entry);
    //    }
    //  }
    //}, (e) => {
    //  console.log(e);
    //});

    this.mensaje = ""; 
  }


  Cancelar() {
    this.dialogRef.close();
  }

  Save()
  {
//    try {
//      const IReg:IProviderRegistry =
//      {
//countryAddress:'CO',
//        cityAddress: this.formedit.value.city,
//        stateAddress: this.formedit.value.state,
//        taxScheme: this.formedit.value.taxScheme,
//        taxResponsibilities: this.formedit.value.taxResponsibilities,
//        idNumber: this.formedit.value.idnumber,
//        idType: this.formedit.value.idtype,
//        lastName: this.formedit.value.lastname,
//        parentProviderId: 0, 
//        web1:this.formedit.value.web1,
//        name: this.formedit.value.name,
//        address1: this.formedit.value.address1,
//        telephone1: this.formedit.value.telephone1,
//        telephone2: this.formedit.value.telephone2,
//        email1: this.formedit.value.email1,
//        personType: this.formedit.value.personType,
//        deliveryTerm: this.formedit.value.deliveryTerm,
//        status: (this.formedit.value.active ? "A" : "I")
//      };
//      console.log(IReg);
//      this.providerService.Registry(IReg).subscribe(results => {
//        if (results.body.isValid)
//        {
//          this.openSB("Proveedor grabado");
//          this.dialogRef.close(true);
//        }
//        else
//        {
//          alert(results.body.error.description);
//        }
//      },
//        (e) => {
//          this.mensaje = e.error;
//          console.log(e);
//        }

//      );
//    }
//    catch (e) {
//      console.log(e.message);
//      alert(e.message);
//    }
  }



  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }


  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  
}
