import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGRCCProduction } from '../app/Interfaces/icc';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { IAddLocationProduction, IGRLocationListResponse, IGRLocationProductionResponse, IGRLocationProductionV2, IGRProductionLocation, IRLocationList, locationslist } from '../app/Interfaces/ilocation';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class PrdLocationService {
  private storage: any;

  locationID: number = 0;
  locationName: string = "";
  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(locationid,locationname) {
    this.locationID =locationid;
    this.locationName = locationname;
  }

  //get production location data V2
  public GetProductionDataV2(PLocationId: number) {
    try {
      let url = this.baseUrl + 'api/prdlocation/v2/production/';
      return this.dataService.get<IGRLocationProductionV2>(url + PLocationId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  //get production locations related to the user's cc
  public UserProductionList() {
    try {
      let url = this.baseUrl + 'api/prdlocation/v2/productionlist/';
      return this.dataService.get<IGRLocationProductionResponse>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("error");
    }
  }

  //obj: get cc productions (with production locations)
  getCCProduction() {
    try {
      let url = this.baseUrl + 'api/prdlocation/v1/ccproduction';
      return this.dataService.get<IGRCCProduction>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

//get production locations
  public ProductionList(PCCId:number) {
    try {
      let url = this.baseUrl + 'api/prdlocation/productionlist/';
      return this.dataService.get<IGRLocationProductionResponse>(url+PCCId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("error");
    }
  }

  public DeleteProduction(PLocation: number) {
    try {
      let url = this.baseUrl + 'api/prdlocation/production/';
      return this.dataService.delete<IgeneralResponse03>(url + PLocation)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public EditProduction(PLocation:IAddLocationProduction) {
    try {
      let url = this.baseUrl + 'api/prdlocation/production/';
      return this.dataService.post<IgeneralResponse03>(url, PLocation)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public IsProduction(PLocation: number) {
    try {
      let url = this.baseUrl + 'api/prdlocation/isproduction/';
      return this.dataService.get<IgeneralResponse03>(url + PLocation)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  //Obj:Get data production location 
  public GetProduction(PLocation:number) {
    try {
      let url = this.baseUrl + 'api/prdlocation/production/';
      return this.dataService.get<IGRProductionLocation>(url+PLocation)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public AddProduction( PLocation:IAddLocationProduction) {
    try {
      let url = this.baseUrl + 'api/prdlocation/production/';
      return this.dataService.put<IgeneralResponse03>(url, PLocation)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public List(p_LL: locationslist) {
    try {
      let url = this.baseUrl + 'api/prdlocation/list/';
      return this.dataService.post<IRLocationList>(url, p_LL)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("error");
    }
  }

  //public Assigned(p_LL: getLocationAssigned) {
  //  try {
  //    let url = this.baseUrl + 'api/location/v1/assigned/';
  //    return this.dataService.post<GRLocationAssigned>(url, p_LL)
  //      .pipe(res => { return res });
  //  }
  //  catch (e) {
  //    console.log("error");
  //  }
  //}

  //public PutUserLocation(p_PUL: putUserLocation[]) {
  //  try {
  //    let url = this.baseUrl + 'api/location/v1/userlocation/';
  //    return this.dataService.post<IgeneralResponse03>(url, p_PUL).pipe(res => { return res });
  //  }
  //  catch (e) {
  //    console.log(e);
  //  }
  //}



}
