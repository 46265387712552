import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
//import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomerService } from '../../../services/customer.service';
import { DataTableService } from '../../../services/dataTable.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { ICustomerRegistry } from '../../Interfaces/icustomer';
import { IdatatableResponse } from '../../Interfaces/idatatable';

@Component({
  selector: 'app-customeradd',
  templateUrl: './customeradd.component.html',
  //standalone: true,
  //imports: [MatTabsModule]
})

export class CustomerAddComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  customername: string = "";
  idtypelist: IdatatableResponse[];
  persontypelist: IdatatableResponse[];
  departmentlist: IdatatableResponse[];
  citylist: IdatatableResponse[];
  citylistfilter: IdatatableResponse[];
  taxresponsibilitieslist: IdatatableResponse[];
  taxschemelist:IdatatableResponse[]

  constructor(
    private formBuilder: FormBuilder, 
    private securityService: SecurityService,
    private router: Router,
    private customerservice: CustomerService,
    private datatableservice: DataTableService,
    private dialogRef: MatDialogRef<CustomerAddComponent>,
    private _snackBar: MatSnackBar
  )
  {
    this.formedit = formBuilder.group({
      idtype: ['N', Validators.required],
      idnumber: ['',[ Validators.required,Validators.maxLength(20)]],
      name: ['', [Validators.required, Validators.maxLength(80)]],
      lastname: ['',Validators.maxLength(80)],
      address1: ['', [Validators.required, Validators.maxLength(100)]],
      telephone1: ['',[ Validators.required, Validators.maxLength(20)]],
      telephone2: ['', Validators.maxLength(20)],
      email1: ['',[ Validators.required, Validators.email, Validators.maxLength(100)]],
      state: ['', Validators.required],
      city: ['', Validators.required],
      personType: ['L', Validators.required],
      taxResponsibilities: ['O-99'],
      taxScheme:['ZZ'],
      creditLimit: [0], deadLineDays: [0],
      alternatecode01: ['', Validators.maxLength(50)],
      reportCxc: false,
      active:true
    }
    )
  }
  selectedvalue: number; selectedview: string;
  r_companiesregs: 0;

  ngOnInit() {
    //Id types
    this.idtypelist = [];
    this.datatableservice.getDataList("IDTYP").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.idtypelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //Department list
    this.departmentlist = [];
    this.datatableservice.getDataList("DEPTO").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.departmentlist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //City list
    this.citylist = [];
    this.datatableservice.getDataList("CITY").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.citylist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //person types
    this.persontypelist = [];
    this.datatableservice.getDataList("PRTYP").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.persontypelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //tax responsbilities
    this.taxresponsibilitieslist = [];
    this.datatableservice.getDataList("TAXRS").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.taxresponsibilitieslist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //tax schemes
    this.taxschemelist = [];
    this.datatableservice.getDataList("TAXSC").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.taxschemelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    this.mensaje = ""; 
  }


  Cancelar() {
    this.dialogRef.close();
    //this.router.navigate(['customerlist']);
  }

  Save()
  {
    //add customer
    try {
      const IReg: ICustomerRegistry =
      {
        cityAddress: this.formedit.value.city,
        stateAddress: this.formedit.value.state,
        taxScheme: this.formedit.value.taxScheme,
        branchOffice: 0,
        fiscalRespon: this.formedit.value.taxResponsibilities,
        idNumber: this.formedit.value.idnumber,
        idType: this.formedit.value.idtype,
        lastName: this.formedit.value.lastname,
        observation: "",
        parentCustomerId: 0,
        priceList_Id: 0,
        web1: "",
        name: this.formedit.value.name,
        address1: this.formedit.value.address1,
        telephone1: this.formedit.value.telephone1,
        telephone2: this.formedit.value.telephone2,
        email1: this.formedit.value.email1,
        creditLimit: this.formedit.value.creditLimit,
        deadLineDays: this.formedit.value.deadLineDays,
        personType: this.formedit.value.personType,
        alternateCode01: this.formedit.value.alternatecode01,
        reportCXC: this.formedit.value.reportCxc,
        status: (this.formedit.value.active ? "A" : "I")
      };
      this.customerservice.Registry(IReg).subscribe(results => {
        if (results.body.isValid)
        {
          this.openSB("Cliente grabado");
          this.dialogRef.close(true);
          //this.router.navigate(['customerlist']);
        }
        else
        {
          alert(results.body.errors[0].description);
        }
      },
        (e) => {
          this.mensaje = e.error;
          console.log(e);
        }

      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  seleccionar(item: { id: string })
  {
    //if (item.selected) {-		item	Object {code: "INVE", description: "INVENTARIO", selected: false, …}	Object

    //}		selected	false	boolean

    if (item["selected"] == true)  item["selected"] = false; 
    else item["selected"] =true; 

  }

  filtercity() {
    let _State = this.formedit.value.state.trim();
    let _Length = _State.trim().length;
    this.citylistfilter = this.citylist.filter(x => { return x.code.substring(0, _Length) === _State.trim() });
    return this.citylistfilter;
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  
}
