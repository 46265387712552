import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getTransferHeader, resultTransferDetail, resultTransferHeader } from '../app/Interfaces/itransfer';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class TransferService {

  private baseUrl = environment.urlAPI;
  public id: number;
  public docnumber: number;

  constructor(private http: HttpClient, private dataService: DataService) { }

  putValues(PTransferHId: number, pTransferDocNumber: number)
  {
    this.id = PTransferHId;
    this.docnumber = pTransferDocNumber;
  }

  getTransferHeader(_GT: getTransferHeader) {
    try {
      let url = this.baseUrl + 'api/transfer/v1/header/';
      return this.dataService.post<resultTransferHeader>(url, _GT)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getTransferDetail(_GTD:number) {
    try {
      let url = this.baseUrl + 'api/transfer/v1/detail/';
      return this.dataService.get<resultTransferDetail>(url+_GTD.toString())
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

}
