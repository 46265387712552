/// <reference path="../../../services/data.service.ts" />
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppTransferListService } from '../../../services/apptransferlist.service';
import { CcService } from '../../../services/cc.service';
import { PermissionsService } from '../../../services/permissions.service';
import { RouterService } from '../../../services/router.service';
import { TransferService } from '../../../services/transfer.service';
import { UserService } from '../../../services/user.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { Icc } from '../../Interfaces/icc';
import { getTransferHeader, TransferHeader, transferStatus } from '../../Interfaces/itransfer';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';

@Component({
  selector: 'app-transferlist',
  templateUrl: './transferlist.component.html'
})

export class TransferListComponent implements OnInit {
  filterForm: FormGroup;
  subRef$: Subscription;
  r_gridregs: 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  Columns: string[] = [];
  DataGrid:TransferHeader[] = [];
  dataList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  _currentId: number;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  ngAfterViewInit() {
    //this.dataList.paginator = this.paginator;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    //private dataService: DataService,
    //private securityService: SecurityService,
    //private companyservice: CompanyService,
    private routeparent: RouterService,
    private _snackBar: MatSnackBar,
    private ccService: CcService,
    private userService: UserService,
    private transferService: TransferService,
    private permissionservice: PermissionsService,
    private appTransferListService: AppTransferListService

  ) {
    this.filterForm = formBuilder.group({
      ccIdFrom: [this.appTransferListService.fromCC, Validators.required],
      ccIdTo: [this.appTransferListService.toCC, Validators.required],
      startDate: [(this.appTransferListService.startDate != null ? this.appTransferListService.startDate : new Date().toISOString().substring(0,10)), Validators.required],
      endDate: [(this.appTransferListService.endDate!=null?this.appTransferListService.endDate:new Date().toISOString().substring(0, 10)), Validators.required],
      status: [this.appTransferListService.status]
    });
    this.Columns = ['fromCCName','documentNumber','transferDate','toCCName','statusName','b_menu'];
  }
  selectedvalue: number; selectedview: string;
  ccFrom: Icc[];
  ccTo: Icc[];
  status: transferStatus[];

  ngOnInit() {
    this.mensaje = "";
    //const _CurrentDate =new  Date();
    //this.filterForm.controls['startDate'].setValue(new Date());
    //this.filterForm.value.startDate = _CurrentDate;
    //permisos
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    this.permissionservice.getPermission("TRANSFERLIST").subscribe(_Result => {
      if (_Result.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of _Result.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
      this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
    },
      (e) => {
        this.mensaje  =  e.error;
        console.log(e);
      });
    //cc
    this.ccFrom = [];
    this.ccTo = [];
    this.ccService.getCCListV2().subscribe(_Result => {
      if (_Result.body.isValid) {
        for (const entry of _Result.body.resultData) {
          this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
        }
      } 
    }, (e) => {
      console.log(e);
    });
    //status
    this.status = [];
    this.status.push({ name: 'Todos', code: '' }, { name: "Registrado", code: "R" }, { name: "Recibido", code: "I" }, { name: "Aceptado", code: "C" }, { name: "Rechazado", code: "E" });
    if (this.filterForm.value.ccIdFrom > 0 || this, this.filterForm.value.ccIdTo > 0) {
      this.Filter();
    }
  }


  Filter() {
    //let _NewDate = new Date(Date.now());
    //_NewDate.setDate(_NewDate.getDate() - 180);
    const get: getTransferHeader = {
      fromCCId: this.filterForm.value.ccIdFrom,
      toCCId: this.filterForm.value.ccIdTo,
      startDate: this.filterForm.value.startDate,
      endDate: this.filterForm.value.endDate,
      status: this.filterForm.value.status 
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.dataList = [];
    this.transferService.getTransferHeader(get).subscribe(_Result => {
      if (_Result.body.isValid) {
        for (const entry of _Result.body.resultData) {
          this.addData(entry);
        }
        this.dataList = new MatTableDataSource<TransferHeader>(this.DataGrid);
        //this.dataList.paginator = this.paginator;
        this.r_gridregs = this.dataList.data.length;
        if (this.appTransferListService.currentPage > 0) {
          this.paginator.pageIndex = this.appTransferListService.currentPage;
        }
        this.dataList.paginator = this.paginator;

      }
      else {
        this.openSB("No hay datos");
        this.mensaje = "No hay datos";
      }
    },
      (e) => {
        this.openSB(e.error);
        this.mensaje =e.error;
        console.log(e);
      });
  }


  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        {
          switch (permission.code) {
            case "D":
                this.menuPop.push(permission);
              break;
            default:
              this.menuPop.push(permission);
              break;
          }
          break;
        }
    }
  }

  addCc(V_cc) {
    try {
      this.ccFrom.push(V_cc);
      this.ccTo.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }

  b_CurrentId(PId: number) {
    this._currentId = PId;
    this.appTransferListService.putValues(this.filterForm.value.ccIdFrom, this.filterForm.value.ccIdTo,this.filterForm.value.startDate,this.filterForm.value.endDate, this.filterForm.value.status, this.paginator.pageIndex);
  }

  b_execute(prmCode: string, pLink: string, pId: number) {
    var _Trf = this.DataGrid.find(x => x.id == pId);
    switch (prmCode) {
      case "D":
        this.routeparent.putRouter("transferlist");
        this.transferService.putValues(_Trf.id, _Trf.documentNumber)
        this.router.navigate(['transferdetail']);
        break;
    }
  }


  //b_det(id, docnumber) {
  //  this.routeparent.putRouter("transferlist");
  //  this.transferService.putValues(id,docnumber)
  //  this.router.navigate(['transferdetail']);

  //}


  b_back() {
    this.appTransferListService.putValues(0,0,null,null,"",0);
    this.router.navigate(['inventory']);
  }


  b_gen(link, id) {
        this.router.navigate([link]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }
  getTotalOrder() {
    return this.dataList.data.map(t => t.subTotal).reduce((acc, subTotal) => acc +subTotal, 0);
    //let total = 0;
    //for (let i = 0; i < this.dataSourceVBFPG.length; i++) {
    //  total = total + parseInt(this.dataSourceVBFPG[i].value);
    //}
    //return total;
  }

}
