import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { CustomerService } from '../../../services/customer.service';
import { DataService } from '../../../services/data.service';
import { Imenu01Response } from '../../Interfaces/imenu01';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  color: string;
  cols: number;
  rows: number;
  class: string;
}

@Component({
  selector: 'custmore-data',
  templateUrl: './custmore.component.html',
    styleUrls: ['./custmore.component.css']
})

export class CustmoreComponent {
  constructor(
    private dataService: DataService,
    private router: Router,
    private customerservice: CustomerService
  ) { }

  menuItems: any[];
  functions: RouteInfo[];
  secondtitle: string;


  async ngOnInit() {
    const url = environment.urlAPI + 'api/menu/n2/';
    const userId = localStorage.getItem('userId');
    let ROUTES = [];
    this.functions = ROUTES;
    this.menuItems = [];
    this.secondtitle = this.customerservice.customerName;
    this.dataService.get<Imenu01Response[]>(url + userId + '/CUSTMORE').subscribe(results => {
      for (const entry of results.body) {
        this.addRoutes({ path: '/' + entry.link.trim().toLowerCase(), title: entry.description.trim(), icon: entry.icon, color: '#A51955', cols: 1, rows: 1,class:entry.function_class });
      }
      this.menuItems = this.functions.filter(menuItem => menuItem);
    },
      (e) => {
        console.log(e);
      });
    this.menuItems = this.functions.filter(menuItem => menuItem);


  }

  addRoutes(route) {
    this.functions.push(route);
  }

  b_back() {
    this.router.navigate(['customerlist']);
  }

}
