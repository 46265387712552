import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from '../../../services/location.service';
import { PrdLocationService } from '../../../services/prdLocation.service';
import { RouterService } from '../../../services/router.service';

declare interface RouteInfo {
  id: number;
  path: string;
  title: string;
  icon: string;
  cols: number;
  rows: number;
  class: string;
}

export const ROUTES: RouteInfo[] = [];

@Component({
  selector: 'prodlocation-data',
  templateUrl: './prodlocation.component.html',
})

export class ProdLocationComponent {
  constructor(
    private router: Router,
    //private kdsService: KdsService,
    private locationService: LocationService,
    private prdLocationService: PrdLocationService,
    private routerService: RouterService
  ) { }

  menuItems: any[];
  functions: RouteInfo[];
  //productionLocations: ILocationProductionResponse[];
  message: string = "";




  async ngOnInit() {
    let ROUTES = [];
    this.functions = ROUTES;
    this.menuItems = [];
    this.prdLocationService.UserProductionList().subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.addRoutes({ id: entry.id, path: "kds", title: entry.name.trim().substring(0, 20), icon:"arrow_forward_ios", cols: 1, rows: 1, class:entry.function_class});
        }
        this.menuItems = this.functions.filter(menuItem => menuItem);
      }
      else {
        this.message = results.body.error.description;
      }
    },
      (e) => {
        console.log(e);
      });
    //this.menuItems = this.functions.filter(menuItem => menuItem);
  }

  addRoutes(route) {
    this.functions.push(route);
  }

  b_back() {
    this.router.navigate(['home']); // this.routerService.routerParent]);
  }

  b_click(PRoute, PId) {
    this.routerService.putRouter("prodlocation");
    PRoute+="/"+PId
    this.router.navigate([PRoute]);
  }

}






