import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatPaginator, MatSnackBar, MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import { CompanyService } from "../../../services/company.service";
import { DataService } from "../../../services/data.service";
import { PaymMethService } from "../../../services/paymmeth.service";
import { PermissionsService } from "../../../services/permissions.service";
import { RouterService } from "../../../services/router.service";
import { SecurityService } from "../../../services/security.service";
import { ErrorStateMatcher1 } from "../../error-state-matcher1";
import { PaymMethList, PaymMethStatus } from "../../Interfaces/ipaymmeth";
import { IuserPermissionsResponse } from "../../Interfaces/iuser";
import { PaymMethAddComponent } from "../add/paymmethadd.component";
import { PaymMethEditComponent } from "../edit/paymmethedit.component";

@Component({
  selector: 'app-paymmethlist',
  templateUrl: './paymmethlist.component.html'
})

export class PaymMethListComponent implements OnInit, AfterViewInit {
  formFilter: FormGroup;
  r_gridregs: number = 0;
  matcher= new ErrorStateMatcher1();
  message: string = "";
  columns: string[] = [];
  dataGrid: PaymMethList[] = [];
  dataList = new MatTableDataSource;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  menuPop: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;
  menuPopList = new MatTableDataSource;
  filtermenu = { type: 'G' };
  statuslist: PaymMethStatus[] = [];
  _currentId: number = 0;

  //paymMethList: PaymMethList[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
    //this.paginator.pageSize = 10;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private companyservice: CompanyService,
    private routeparent: RouterService,
    private _snackBar: MatSnackBar,
    private permissionsservice: PermissionsService,
    private paymmethservice: PaymMethService,
    private dialog: MatDialog,
  ) {
    this.formFilter = formBuilder.group({
      status: ['A', Validators.required],
    });
    this.columns = ['code', 'name', 'className', 'statusName','b_menu'];
  }
  selectedvalue: number; selectedview: string;


  ngOnInit() {
    this.message = "";
    this.statuslist.push({ code: "A", name: "Activo" }, { code: "I", name: "Inactivo" });
    //permisos
    this.Permissions = [];
    this.permissionsservice.getPermission("PAYMMETHLIST").subscribe(result => {
        if (result.body.length == 0)
          this.message = "No tiene permisos";
        for (const entry of result.body)
        {
          this.addPermission(entry);
        }
        this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
      this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
    },
      (e) => {
        this.message = e.error;
        console.log(e);
      });
  }

  Filter() {
    //payment method
    this.MenuG = [];
    this.dataList = null;
    this.dataGrid = [];
    this.paymmethservice.getPaymMethList(this.formFilter.get("status").value).subscribe(result => {
      //console.log(result.body);
      if (result.body.isValid) {
        for (const entry of result.body.resultData) {
          this.addPaymMeth(entry);
        }
        this.dataList = new MatTableDataSource<PaymMethList>(this.dataGrid);
        this.dataList.paginator = this.paginator;
        this.r_gridregs = this.dataList.data.length;
        this.message = "";
      }
      else {
        this.message = result.body.error.description;
      }
    }, (e) => {
      this.message = e.error;
      console.log(e);
    });
  }

  addPaymMeth(pm) {
    this.dataGrid.push(pm);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        this.menuPop.push(permission);
        break;
    }
  }

  b_back() {
    this.router.navigate(['data']);
  }


  b_Add() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'my-class';
    const dialogRef = this.dialog.open(PaymMethAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          console.log("grabado");
          //if (this.r_gridregs > 0) {
          //  this.Filter();
          //}
          //  data.providerId = parseInt(data.providerId, 10);
          //this.registryService.putUpdate(data).subscribe(response => {
          //  if (!response.body.isValid) {
          //    console.log(response.body.error.description);
          //  }
          //  else {
          //    this.loadData();
          //    this.openSB(response.body.resultData.description);
          //  }
          //}, (e) => {
          //  console.log(e);
          //});
        }
        else {
          console.log('Undefined data. No will insert');
        }
      }, (e) => console.log(e)
    );
  }

  b_Mod() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'my-class';
    const dialogRef = this.dialog.open(PaymMethEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          let _CurrentPage = this.paginator.pageIndex;
          if (this.r_gridregs > 0) {
            this.Filter();
            this.paginator.pageIndex = _CurrentPage;
          }
          //  data.providerId = parseInt(data.providerId, 10);
          //this.registryService.putUpdate(data).subscribe(response => {
          //  if (!response.body.isValid) {
          //    console.log(response.body.error.description);
          //  }
          //  else {
          //    this.loadData();
          //    this.openSB(response.body.resultData.description);
          //  }
          //}, (e) => {
          //  console.log(e);
          //});
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );
  }

  b_gen(code, link, id) {
    switch (code) {
      case "I":
        var _Import = confirm("Desea importar las formas de pago generales?");
        if (_Import) {
          this.paymmethservice.import().subscribe(result => {
            if (result.body.isValid) {
              this.openSB(result.body.resultData.description);
              this.message = "";
            }
            else {
              this.message = result.body.error.description;
            }
          }, (e) => {
            this.message = e.error;
            console.log(e);
          });
        }
        break;
      case "A":
        this.b_Add();
        break;
      default:
        this.router.navigate([link]);
        break;
    }
  }

  //menu execute items
  b_execute(prmCode: string, PLink: string) {
    var _Payment = this.dataGrid.find(x => x.id ==this._currentId);
    switch (prmCode) {
      default:
        this.paymmethservice.putValues(this._currentId, _Payment.code, _Payment.name)
        this.b_Mod();
        //this.router.navigate.arguments();
        //this.router.navigate([PLink]);
        break;
    }
  }

  b_CurrentId(p_Id: number) {
    this._currentId = p_Id;
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
