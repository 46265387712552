import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { CustomerService } from '../../../../services/customer.service';
import { DataService } from '../../../../services/data.service';
import { RegistryService } from '../../../../services/registry.service';
import { IcustomerlistResponse } from '../../../Interfaces/icustomer';
import { iRegistryStatus, iRegistryUpdate } from '../../../Interfaces/iregistry';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html'
})
export class StatusComponent implements OnInit {
  private sub: Subscription;
  private _registry: iRegistryUpdate;
  private dataService: DataService;
  private mensaje: string="";
  protected providers: IcustomerlistResponse[];
  private statusList:iRegistryStatus[];

  constructor(private dialogRef: MatDialogRef<StatusComponent>, private customerService: CustomerService, private registryService:RegistryService) {
      }

  ngOnInit(): void { this.loadContent(); }

loadContent(){
  this.providers = [];
  this.statusList = [];
  this._registry = {  id: this.registryService.id, idInstallation: "", key: "", invoiceHId: 0, status:this.registryService.status, license: "", providerId:this.registryService.providerId };0
  this.customerService.getCustomerList().subscribe(response => {
    if (!response.body.isValid)
      console.log( response.body.error.description);
    else {
      for (const entry of response.body.resultData) {
        if (entry.id!=this.customerService.customerID)
        this.addProvider({ id: entry.id, idparent: entry.idparent, name: entry.name, idtypename: entry.idtypename, email1: entry.email1, branchoffice: entry.branchoffice });
      }
    }
  },
    (e) => {
      //    this.mensaje = e.error;
      console.log(e);
    });
  this.statusList = this.registryService.GetStatusList(this.registryService.status);
  }

  addProvider(vp_provider) {
    try {
      this.providers.push(vp_provider);
    } catch (e) { console.log(e); }
        //throw new Error('Method not implemented.');
    }

  
  //private initHoursArray() {
  //  this.hoursArray = new Array<number>();
  //  for (let i = 25; i < 151; i++) {
  //    this.hoursArray.push(i);
  //  }
 // }

  closeWithoutSave() {
    this.dialogRef.close();
  }

  //private initDefaultCourse() {
    //this.courseToInsert.hours = 25;
    //this.courseToInsert.level = 'Intermedio';
    //this.courseToInsert.active = true;
 // }
}
