import { DatePipe } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from '../../services/data.service';
import { Imenu01Response } from '../Interfaces/imenu01';
import { ImnsResponse } from '../Interfaces/imns';
import { RouterService } from '../../services/router.service';
import { CompanyService } from '../../services/company.service';

declare interface Mensaje {
  subject: string;
  message: string;
  from_name: string;
  date: string;
  class: string;
  icon: string;
}
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [];
export const MNS: Mensaje[] = [];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
  scrHeight: any;
  scrWidth: any;
  loading: boolean = false;
  companyname = localStorage.getItem('companyName');
  menuItems: any[];
  functions: RouteInfo[];
  _renderer: Renderer2;


  // HostListener: https://angular.io/api/core/HostListener
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    console.log(this.scrHeight, this.scrWidth);
  }

  constructor(private dataService: DataService, private routeparent: RouterService, private companyservice: CompanyService,
    private rendererFactory: RendererFactory2
) {
    this.getScreenSize();
    this._renderer = rendererFactory.createRenderer('body', null);
  }
  mensajesList: any[];
  mensajes: Mensaje[];
  V_date: string;
  


  ngOnInit() {
    this._renderer.removeClass(document.body, 'body-login-01');
    this._renderer.addClass(document.body, 'body-main-01');
    this.routeparent.putRouter("/home");
    this.loading = true;
    let url = environment.urlAPI + 'api/menu/n1/';
    const userId = localStorage.getItem('userId');
    let ROUTES=[];
    //this.functions = ROUTES;
    this.functions=[];
    //funciones
    this.dataService.get<Imenu01Response[]>(url + userId).subscribe(results => {
    for (const entry of results.body) {
      this.addRoutes({ path: '/' +entry.link.trim() , title: entry.description.trim(), icon: entry.icon, class: entry.function_class }); //'/' + entry.function_code.toLowerCase()
      }
      this.menuItems = this.functions.filter(menuItem => menuItem);
    },
      (e) => {
        console.log(e);
      });
    this.menuItems = this.functions.filter(menuItem => menuItem);
    this.companyservice.putValues(0,"",false);
    //mensajes
    url = environment.urlAPI + 'api/mns/';
    var datePipe = new DatePipe('en-US');
    let MNS=[];
    this.mensajes =[];
    this.mensajesList = [];
    this.dataService.get<ImnsResponse[]>(url + userId ).subscribe(results => {
      for (const entry of results.body) {
        this.V_date = datePipe.transform(entry.date, 'dd/MM/yyyy hh:mm');
        this.addMensajes({ subject: entry.subject,message: entry.message,from_name: entry.from_name ,date: this.V_date, class: entry.mnsclass,icon:'notifications_none' });
      }
      this.mensajesList = this.mensajes.filter(menuItem => menuItem);
    },
      (e) => {
        console.log(e);
      });
    this.mensajesList = this.mensajes.filter(menuItem => menuItem);
  }
  ngOnDestroy() { }

  addMensajes(mns) {
    this.mensajes.push(mns);
  }

  addRoutes(route) {
    this.functions.push(route);
  }


}
