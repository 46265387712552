/// <reference path="../../../services/data.service.ts" />
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppCustomerListService } from '../../../services/appcustomerlist.service';
import { CompanyService } from '../../../services/company.service';
import { CustomerService } from '../../../services/customer.service';
import { DataService } from '../../../services/data.service';
import { PermissionsService } from '../../../services/permissions.service';
import { RouterService } from '../../../services/router.service';
import { UserService } from '../../../services/user.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IAddCompanyFromCustomer, IAModule } from '../../Interfaces/icompany';
import { CustomerStatus, getcustomerlist, IcustomerlistResponse } from '../../Interfaces/icustomer';
import { AddUserPermissions, IUserFromCompanyV1, IuserPermissionsResponse, putPermissionsC } from '../../Interfaces/iuser';
import { CustomerAddComponent } from '../add/customeradd.component';
import { CustomerdetailComponent } from '../detail/customerdetail.component';
import { CustomereditComponent } from '../edit/customeredit.component';

@Component({
  selector: 'app-customerlist',
  templateUrl: './customerlist.component.html'
})

export class CustomerListComponent implements OnInit, AfterViewInit {

  formFilter: FormGroup;
  subRef$: Subscription;
  r_gridregs:number= 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  DataGrid: IcustomerlistResponse[] = [];
  dataList = new MatTableDataSource;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  statuslist: CustomerStatus[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  r_b_cxc:boolean= false;
  r_b_det: boolean = false;
  _currentId: number;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    //private securityService: SecurityService,
    private companyservice: CompanyService,
    private routeparent: RouterService,
    private customerservice: CustomerService,
    private permissionservice: PermissionsService,
    private appcustomerlistservice: AppCustomerListService,
    private dialog: MatDialog,
private userservice:UserService,
    private _snackBar: MatSnackBar
  ) {
    this.formFilter = formBuilder.group({
      name: [this.appcustomerlistservice.customerName],
      idNumber: [this.appcustomerlistservice.idNumber],
      status: [(this.appcustomerlistservice.status == '' ? 'A' : this.appcustomerlistservice.status), Validators.required]
    });
    this.Columns = ['name', 'idtypename','branchoffice','b_menu'];
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.Columns.pop;
    this.mensaje = "";
    this.statuslist.push({ code: "A", name: "Activo" }, { code: "I", name: "Inactivo" });
    //permisos
    let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    this.permissionservice.getPermission("CUSTOMER").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission(entry);
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
    },
      (e) => {
        this.mensaje  =  e.error;
        console.log(e);
      });
    //to check cxc permissions
    this.permissionservice.getPermission("CXC01").subscribe(results => {
      for (const entry of results.body) {
        if (entry.code == "E") //cualquier otro permiso
        {
          entry.description = "CUENTAS x COBRAR";
          this.menuPop.push(entry);
          break;
        }
      }
    },
      (e) => {
        if (e.status != 404) {
          this.mensaje = e.error;
          alert(e.error);
          console.log(e);
        }
      });
    //permisos para licenses
    this.permissionservice.getPermission("LICENSES").subscribe(results => {
      for (const entry of results.body) {
        if (entry.code == "E") 
        {
          entry.description = "LICENCIAS"; 
          this.menuPop.push(entry);
          break;
        }
      }
    },
      (e) => {
        if (e.status != 404) {
          this.mensaje = e.error;
          alert(e.error);
          console.log(e);
        }
      });
    //permissions for custmore (more options)
    this.permissionservice.getPermission("CUSTMORE").subscribe(results => {
      for (const entry of results.body) {
        if (entry.code == "E") {
          entry.description= "MAS OPCIONES"; 
          this.menuPop.push(entry);
          break;
        }
      }
    },
      (e) => {
        if (e.status != 404) {
          this.mensaje = e.error;
          alert(e.error);
          console.log(e);
        }
      });
    //permissions for custmore (more options)
    this.permissionservice.getPermission("COMPANY").subscribe(results => {
      for (const entry of results.body) {
        if (entry.code == "H") {
          entry.description = entry.description; // "HABILITAR CURSOS";
          this.menuPop.push(entry);
          break;
        }
      }
    },
      (e) => {
        if (e.status != 404) {
          this.mensaje = e.error;
          alert(e.error);
          console.log(e);
        }
      });
    ////permissions for custmore (more options) , don't delete '
    //this.permissionservice.getPermission("COURSES").subscribe(results => {
    //  for (const entry of results.body) {
    //    if (entry.code == "H") {
    //      entry.description = "HABILITAR CURSOS";
    //      this.menuPop.push(entry);
    //      break;
    //    }
    //  }
    //},
    //  (e) => {
    //    if (e.status != 404) {
    //      this.mensaje = e.error;
    //      alert(e.error);
    //      console.log(e);
    //    }
    //  });
    this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
    if (this.formFilter.value.name != "" || this.formFilter.value.idNumber != "") {// || this.formFilter.value.status!="") {
      this.Filter();
    }
  }

  Filter() {
    const filt: getcustomerlist = {
      name: this.formFilter.value.name,
      idNumber: this.formFilter.get("idNumber").value,
      status: this.formFilter.get("status").value  
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.customerservice.getCustomerListV1(filt).subscribe(results => {
      if (! results.body.isValid) 
        this.mensaje =results.body.error.description;
      for (const entry of results.body.resultData) {
        this.addData({ id: entry.id, idparent: entry.idparent, name: entry.name, idtypename: entry.idtypename, email1: entry.email1, branchoffice: entry.branchoffice });
      }
      this.dataList = new MatTableDataSource<IcustomerlistResponse>(this.DataGrid);
      this.r_gridregs = this.dataList.data.length;
      if (this.appcustomerlistservice.currentPage > 0) {
        this.paginator.pageIndex = this.appcustomerlistservice.currentPage;
      }
      this.dataList.paginator = this.paginator;

    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        this.menuPop.push(permission);
        break;
    }
  }

  b_del(id, name) {
    this.customerservice.putValues(id, name)
    this.router.navigate(['/customerdelete']);
  }

  b_addcompanyfromcustomer() {
    //create company
    const IModules: IAModule[] = [];
    IModules.push({ ModuleCode: "ITHK" }); //{ ModuleCode: "GSGMCOURSES" },
    const IACC: IAddCompanyFromCustomer = {
      sysStatus: "A", customerId: this._currentId, modules: IModules
    }
    this.companyservice.AddFromCustomer(IACC).subscribe(result => {
      if (!result.body.isValid) {
        alert(result.body.error.description);
        return;
      }
      else {
        //create user
        const iuseradd: IUserFromCompanyV1 = {
          companyId: result.body.resultData.id, // this._currentId,
          profile: "A",
          sysStatus: "A"
        };
        this.userservice.AddFromCompany(iuseradd).subscribe(resultU => {
          if (resultU.body.isValid) {
            let _UserId = resultU.body.resultData.id;
            try {
              //const ppf: putPermissionsC[] = [];
              //ppf.push({ code: "", functionCode: "COURSES" });
              //const pupf: AddUserPermissions =
              //{
              //  userId: _UserId,
              //  permissions: ppf
              //};
              //this.permissionservice.putByFunction(pupf).subscribe(resultF => {
              //  if (!resultF.body.isValid) {
              //    alert(resultF.body.error.description);
              //  }
              //},
              //  (e) => {
              //    alert(e.error);
              //  }
              //);
              const ppc: putPermissionsC[] = [];
              ppc.push({ code: "E", functionCode: "MYCOMPANY" },
                { code: "E", functionCode: "USER" },
                { code: "S", functionCode: "USER" });
              const pupc: AddUserPermissions =
              {
                userId: _UserId,
                permissions: ppc
              };
              this.permissionservice.putByCode(pupc).subscribe(resultC => {
                if (!resultC.body.isValid) {
                  alert(result.body.error.description);
                }
              },
                (e) => {
                  alert(e.error);
                }
              );
              this.openSB("Procedimiento finalizado");
            }
            catch (e) {
              console.log(e.message);
              alert(e.message);
            }
          }
          else { alert("Error"); }
        },
          (e) => {
            alert(e.error);
          }
        );
      }
    },
      (e) => {
        alert(e.error)
      }
    );
  }

  //b_addcompanyfromcustomer()
  //{
  //  //create company
  //  const IModules: IAModule[] = [];
  //  IModules.push({ ModuleCode: "GSGMCOURSES" }, { ModuleCode: "ITHK" });
  //  const IACC: IAddCompanyFromCustomer = {
  //    sysStatus: "A", customerId: this._currentId, modules: IModules
  //  }
  //  this.companyservice.AddFromCustomer(IACC).subscribe(result => {
  //    if (!result.body.isValid) {
  //      alert(result.body.error.description);
  //      return;
  //    }
  //    else {
  //      //create user
  //      const iuseradd: IUserFromCompanyV1 = {
  //        companyId: result.body.resultData.id, // this._currentId,
  //        profile: "A",
  //        sysStatus: "A"
  //      };
  //      this.userservice.AddFromCompany(iuseradd).subscribe(resultU => {
  //        if (resultU.body.isValid) {
  //          let _UserId = resultU.body.resultData.id;
  //          try {
  //            const ppf: putPermissionsC[] = [];
  //            ppf.push({ code: "", functionCode: "COURSES" });
  //            const pupf: AddUserPermissions =
  //            {
  //              userId:_UserId,
  //              permissions: ppf
  //            };
  //            this.permissionservice.putByFunction(pupf).subscribe(resultF => {
  //              if (!resultF.body.isValid) {
  //                alert(resultF.body.error.description);
  //              }
  //            },
  //              (e) => {
  //                alert(e.error);
  //              }
  //            );
  //            const ppc: putPermissionsC[] = [];
  //            ppc.push({ code: "E", functionCode: "COMPANY" },
  //              { code: "E", functionCode: "USER" },
  //              { code: "S", functionCode: "USER" });
  //            const pupc: AddUserPermissions =
  //            {
  //              userId:_UserId,
  //              permissions: ppc
  //            };
  //            this.permissionservice.putByCode(pupc).subscribe(resultC => {
  //              if (!resultC.body.isValid) {
  //                alert(result.body.error.description);
  //              }
  //            },
  //              (e) => {
  //                alert(e.error);
  //              }
  //            );
  //            this.openSB("Procedimiento finalizado");
  //          }
  //          catch (e) {
  //            console.log(e.message);
  //            alert(e.message);
  //          }
  //        }
  //        else { alert("Error"); }
  //      },
  //        (e) => {
  //          alert(e.error);
  //        }
  //      );
  //    }
  //  },
  //    (e) => {
  //      alert(e.error)
  //    }
  //  );
  //}

  b_gen(PPrmCode,link, id)
  {
    switch (PPrmCode) {
      case "A":
        this.b_Add();
        break;
      default:
        this.router.navigate([link]);
        break;
    }
  }

  b_Det() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    const dialogRef = this.dialog.open(CustomerdetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
        }
        else {
        }
      }, (e) => console.log(e)
    );
  }

  b_Add() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    const dialogRef = this.dialog.open(CustomerAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          console.log("grabado");
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );
  }

  b_Mod() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    const dialogRef = this.dialog.open(CustomereditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          console.log("grabado");
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );
  }

  b_CurrentId(p_Id: number) {
    this._currentId = p_Id;
    this.appcustomerlistservice.putValues(this.formFilter.value.name,this.formFilter.value.idNumber, this.formFilter.value.status, this.paginator.pageIndex);
  }

  b_back() {
    this.appcustomerlistservice.putValues("","","",0);
    this.router.navigate(['data']);
  }

  //execute menu options
  b_execute(PFunctionCode:string ,PPrmCode: string, PLink: string) {
    this.routeparent.putRouter("customerlist");
    var _Customer = this.DataGrid.find(x => x.id ==this._currentId);
    this.customerservice.putValues(this._currentId, _Customer.name)
    switch (PPrmCode) {
      case "M":
        this.b_Mod();
        break;
      case "C":
        this.b_Det(); 
        break;
      case "P":
        if (_Customer.email1.trim() != "") {
          var _Confirm = confirm("Desea conceder el acceso a " + _Customer.name + "?");
          if (_Confirm) {
            this.b_addcompanyfromcustomer();
          }
        }
        else {
          alert("El cliente no tiene Email");
        }
        break;

      default:
        this.router.navigate([PLink]);
        //if (PFunctionCode == "LICENSES" && PPrmCode == "E")
        //{
        //  this.appcustomerlistservice.putValues(this.formFilter.value.name,this.paginator.pageIndex);
        //}
        break;
    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
