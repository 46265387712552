import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-companygraph',
  templateUrl: './companygraph.component.html'
})

export class CompanyGraphComponent implements OnInit {
  private sub: Subscription;
  _chart: any;
  private mensaje: string = "";

  constructor(
    private dialogRef: MatDialogRef<CompanyGraphComponent>,
    //@Inject(MAT_DIALOG_DATA) public data: any,
    private companyService:CompanyService,
    private securityParams: ActivatedRoute, 
    private _snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.loadContent();
  }

  loadContent() {
    //
    //const data = {
    //  labels: [
    //    'Red',
    //    'Green',
    //    'Yellow',
    //    'Grey',
    //    'Blue'
    //  ],
    //  datasets: [{
    //    label: 'My First Dataset',
    //    data: [11, 16, 7, 3, 14],
    //    backgroundColor: [
    //      'rgb(255, 99, 132)',
    //      'rgb(75, 192, 192)',
    //      'rgb(255, 205, 86)',
    //      'rgb(201, 203, 207)',
    //      'rgb(54, 162, 235)'
    //    ]
    //  }]
    //};
    //this._chart = new Chart("canvas", {
    //  type: 'bar',//as ChartType, // tipo de la gráfica 
    //  data: data, // datos 
    //});
    this.companyService.GetMyCompaniesData().subscribe(result => {
      if (result.body.isValid) {
        const data = {
          labels: [
            'Activas',
            'Inactivas'
          ],
          datasets: [{
            label: 'Empresas',
            data: [result.body.resultData.active, result.body.resultData.inactive],
            backgroundColor: [
              'rgb(75, 192, 192)',
              'rgb(255, 99, 132)'
            ]
          }]
        };
        this._chart = new Chart("canvas", {
          type: 'pie',//as ChartType, // tipo de la gráfica 
          data: data, // datos 
        });

      }
      else {
        alert(result.body.error.description);
      }
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      }
    });
  }

  b_back() {
    this.dialogRef.close(false);
  }
    
   openSB(msg) {
      this._snackBar.open(msg, '', {
        duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
      });
    }

}
