import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
//import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomerService } from '../../../services/customer.service';
import { DataTableService } from '../../../services/dataTable.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { customerUpdate } from '../../Interfaces/icustomer';
import { IdatatableResponse } from '../../Interfaces/idatatable';

@Component({
  selector: 'app-customeredit',
  templateUrl: './customeredit.component.html',
    styleUrls: ['./customeredit.component.css']
})

export class CustomereditComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  //customername: string = "";
customerId:string=""
  persontypelist: IdatatableResponse[];
  departmentlist: IdatatableResponse[];
  citylist: IdatatableResponse[];
  citylistfilter: IdatatableResponse[];
  taxresponsibilitieslist: IdatatableResponse[];
  taxschemelist: IdatatableResponse[]

  constructor(
    private formBuilder: FormBuilder, 
    private securityService: SecurityService,
    private router: Router,
    private customerservice: CustomerService,
    private datatableservice: DataTableService,
    private dialogRef: MatDialogRef<CustomereditComponent>,
    private _snackBar: MatSnackBar
  ) {
    this.formedit = formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(80)]],
lastname:['',[Validators.maxLength(80)]],
      address1: ['', [Validators.required, Validators.maxLength(100)]],
      telephone1: ['', [Validators.required, Validators.maxLength(20)]],
      telephone2: ['', Validators.maxLength(20)],
      email1: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      state: ['', Validators.required],
      city: ['', Validators.required],
      personType: ['N', Validators.required],
      taxResponsibilities: [''],
      taxScheme: [''],
      creditLimit: [0], deadLineDays: [0],
      alternatecode01: ['', Validators.maxLength(50)],
      reportCxc: false,
      active: true
    })
  }
  selectedvalue: number; selectedview: string;
  r_companiesregs: 0;

  ngOnInit() {
    this.mensaje = "";
    //Department list
    this.departmentlist = [];
    this.datatableservice.getDataList("DEPTO").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.departmentlist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //City list
    this.citylist = [];
    this.datatableservice.getDataList("CITY").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.citylist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //person types
    this.persontypelist = [];
    this.datatableservice.getDataList("PRTYP").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.persontypelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //tax responsbilities
    this.taxresponsibilitieslist = [];
    this.datatableservice.getDataList("TAXRS").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.taxresponsibilitieslist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //tax schemes
    this.taxschemelist = [];
    this.datatableservice.getDataList("TAXSC").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.taxschemelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    this.customerservice.get(this.customerservice.customerID).subscribe(results => {
      if (results.body.isValid) {
        this.customerId = results.body.resultData.idTypeName + " " + results.body.resultData.idnumber;
        this.formedit.controls['name'].setValue(results.body.resultData.name);
        this.formedit.controls['lastname'].setValue(results.body.resultData.lastName);
        this.formedit.controls['address1'].setValue(results.body.resultData.address1);
        this.formedit.controls['telephone1'].setValue(results.body.resultData.telephone1);
        this.formedit.controls['telephone2'].setValue(results.body.resultData.telephone2);
        this.formedit.controls['email1'].setValue(results.body.resultData.email1);
        this.formedit.controls['state'].setValue(results.body.resultData.stateaddress);
        this.formedit.controls['city'].setValue(results.body.resultData.cityaddress);
        this.formedit.controls['personType'].setValue(results.body.resultData.personType);
        this.formedit.controls['taxResponsibilities'].setValue(results.body.resultData.fiscalRespon);
        this.formedit.controls['taxScheme'].setValue(results.body.resultData.taxScheme);
        this.formedit.controls['creditLimit'].setValue(results.body.resultData.creditLimit);
        this.formedit.controls['deadLineDays'].setValue(results.body.resultData.deadLineDays);
        this.formedit.controls['alternatecode01'].setValue(results.body.resultData.alternateCode01);
        this.formedit.controls['reportCxc'].setValue(results.body.resultData.reportCxc);
        this.formedit.controls['active'].setValue(results.body.resultData.status=='A'?true:false)
      }
      else { this.mensaje = results.body.error.description; }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }


  Cancelar() {
    this.dialogRef.close();
    //this.router.navigate(['customerlist']);
  }

  Grabar()
  {
    try {
      const cu: customerUpdate = {
        id: this.customerservice.customerID,
        name: this.formedit.value.name,
lastname:this.formedit.value.lastname,
        address1: this.formedit.value.address1,
        telephone1: this.formedit.value.telephone1,
        telephone2: this.formedit.value.telephone2,
        stateAddress:this.formedit.value.state,
        cityAddress:this.formedit.value.city,
        email1: this.formedit.value.email1,
        creditLimit: this.formedit.value.creditLimit,
        deadLineDays: this.formedit.value.deadLineDays,
        personType: this.formedit.value.personType,
        fiscalRespon: this.formedit.value.taxResponsibilities,
        taxScheme:this.formedit.value.taxScheme,
        alternateCode01: this.formedit.value.alternatecode01,
        reportCxc: this.formedit.value.reportCxc,
        status: (this.formedit.value.active?'A':'I')
      };
      this.customerservice.putUpdate(cu).subscribe(results => {
        if (results.ok) {
          this.openSB();
          this.dialogRef.close();
        }
        else { alert("Error"); }
      },
        (e) => {
          this.mensaje = e.error;
          console.log(e);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }



  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  seleccionar(item: { id: string })
  {
    //if (item.selected) {-		item	Object {code: "INVE", description: "INVENTARIO", selected: false, …}	Object

    //}		selected	false	boolean

    if (item["selected"] == true)  item["selected"] = false; 
    else item["selected"] =true; 

  }

  filtercity() {
    let _State = this.formedit.value.state.trim();
    let _Length = _State.trim().length;
    this.citylistfilter = this.citylist.filter(x => { return x.code.substring(0, _Length) === _State.trim() });
    return this.citylistfilter;
  }

  openSB() {
    this._snackBar.open('Grabado','', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg'] 
    });
  }

}
