import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatPaginator } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../services/company.service';
import { PermissionsService } from '../../services/permissions.service';
import { RouterService } from '../../services/router.service';
import { ErrorStateMatcher1 } from '../error-state-matcher1';
import { IcompanyResponse, IGetCompaniesList } from '../Interfaces/icompany';
import { IuserPermissionsResponse } from '../Interfaces/iuser';
import { CompanyGraphComponent } from './companyGraph.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})


export class CompanyComponent implements OnInit, AfterViewInit {
  formFilter: FormGroup;
  subRef$: Subscription;
  subRef2$: Subscription;
  public companyId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  Companies: IcompanyResponse[] = [];
  Permissions: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  MenuG: IuserPermissionsResponse[] = [];
  menuGList = new MatTableDataSource;
  dataList = new MatTableDataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private permissionService: PermissionsService,
    private companyservice: CompanyService,
    private routeparent: RouterService,
    private dialog: MatDialog,

  ) {
    this.formFilter = formBuilder.group({
      name: [(this.companyservice.filterName!= null ? this.companyservice.filterName:""),[Validators.maxLength(20)]], // Validators.required]],
      idNumber: [(this.companyservice.idNumber != null ? this.companyservice.idNumber : ""), [Validators.maxLength(10)]], // Validators.required]]
      validator:['',[Validators.required]]
    });
    this.Columns = ['name', 'idtypename','b_menu'];
  }
  selectedvalue: number; selectedview: string;
  r_companiesregs: number = 0;
  _CurrentId: number = 0;

  //private readonly ValidName: ValidatorFn = c => {
  //  try {
  //    if (this.formFilter.controls['name'].value.trim() == "" && this.formFilter.controls['idNumber'].value.trim() == "") {
  //      return Validators.required(c);
  //    }
  //    else {
  //      return Validators.nullValidator(c);
  //      //return true;
  //    }
  //  }
  //  catch {
  //    return Validators.nullValidator(c);
  //  }
  //}


  ngOnInit() {
    const { name, idNumber } = this.formFilter.controls;
    ////subscribe name change
    this.subRef$ = name.valueChanges.subscribe(
      (_name) => {
        if (_name.trim() == "" && idNumber.value == "") {
          this.formFilter.controls['validator'].setValue("");
        }
        else {
          this.formFilter.controls['validator'].setValue("x");
        }
      }
    );
    //subscribe idnumber change
    this.subRef2$ = idNumber.valueChanges.subscribe(
      (_idNumber) => {
        if (_idNumber.trim() == "" && name.value == "") {
          this.formFilter.controls['validator'].setValue("");
        }
        else {
          this.formFilter.controls['validator'].setValue("x");
        }
      });

    this.routeparent.putRouter01("company");
    this.mensaje = "";
    //permisos 
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    //permisos para usuario
    this.permissionService.getPermission("USER").subscribe(results => {
      for (const entry of results.body) {
        if (entry.code == "E") //cualquier otro permiso
        {
          this.addPermission({
            id: entry.id, function_code: entry.function_code, code: entry.code,
            description: "USUARIOS", type: 'R', icon: entry.icon, link: entry.link
          });
          break;
        }
      }
    },
      (e) => {
        if (e.status != 404) {
          this.mensaje = e.error;
          alert(e.error);
          console.log(e);
        }
      });
    //permisos para cc
    this.permissionService.getPermission("CC").subscribe(results => {
      for (const entry of results.body) {
        if (entry.code == "E") //cualquier otro permiso
        {
          this.addPermission({
            id: entry.id, function_code: entry.function_code, code: entry.code,
            description: "CENTROS DE COSTO", type: 'R', icon: entry.icon, link: entry.link
          });
          break;
        }
      }
    },
      (e) => {
        if (e.status != 404) {
          this.mensaje = e.error;
          alert(e.error);
          console.log(e);
        }
      });
    //permisos para compmore (more options)
    this.permissionService.getPermission("COMPMORE").subscribe(results => {
      for (const entry of results.body) {
        if (entry.code == "E") 
        {
          this.addPermission({
            id: entry.id, function_code: entry.function_code, code: entry.code,
            description: "MAS OPCIONES", type: 'R', icon: entry.icon, link: entry.link
          });
          break;
        }
      }
    },
      (e) => {
        if (e.status != 404) {
          this.mensaje = e.error;
          alert(e.error);
          console.log(e);
        }
      });
    //permisos para company
    this.permissionService.getPermission("COMPANY").subscribe(results => {
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
      this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
    },
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
        console.log(e);
      });
    if (this.formFilter.value.name != "" || this.formFilter.value.idNumber != "") {// || this.formFilter.value.status!="") {
      this.Filter();
    }


  }

  Filter() {
    const _filt: IGetCompaniesList = {
      name: this.formFilter.value.name,
      idNumber: this.formFilter.get("idNumber").value,
    };
    this.Companies = [];
    this.r_companiesregs = 0;
    this.companyservice.GetMyCompanies(_filt).subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.addCompany({ id: entry.id, name: entry.name, tele1: entry.tele1, email1: entry.email1, idtypename: entry.idtypename, idnumber: entry.idnumber, address1: entry.address1, cityaddress: entry.cityaddress, countryaddress: entry.countryaddress });
        }
        this.dataList = new MatTableDataSource<IcompanyResponse>(this.Companies);
        //this.dataList.paginator = this.paginator;
        this.r_companiesregs = this.dataList.data.length;
        if (this.companyservice.currentPage > 0) {
          this.paginator.pageIndex = this.companyservice.currentPage;
        }
        this.dataList.paginator = this.paginator;
        this.mensaje = "";
      }
      else {
        if (results.body.error.code == 204) {
          this.mensaje = results.body.error.description;
        }
        else {
          alert(results.body.error.description);
        }
      }
    },
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
        console.log(e);
      });
  }

  addCompany(company) {
      this.Companies.push(company);
  }

  MyCompaniesGraph() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    //dialogConfig.data = PId;
    const dialogRef = this.dialog.open(CompanyGraphComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
      }, (e) => console.log(e)
    );
  }

  b_more(id, name) {
    this.companyservice.putValues(id, name, true);
    this.router.navigate(['compmore']);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
            this.menuPop.push(permission);
            break;
    }
  }

  b_CurrentId(PId: number, PName:string) {
    this._CurrentId = PId;
    this.companyservice.putValues(PId, PName, true);
  }

  //execute menu options
  b_execute(PFunctionCode: string, PPrmCode: string, PLink: string) {
    this.companyservice.putFilterValues(this.formFilter.get("name").value, this.formFilter.get("idNumber").value, this.paginator.pageIndex);
    //this.routeparent.putRouter("company");
    switch (PPrmCode) {
      default:
        this.router.navigate([PLink]);
        break;
    }
  }


  b_gen(link, id) {
    this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate(['home']);
  }

  ngOnDestroy(): void {
    this.subRef$.unsubscribe();
    this.subRef2$.unsubscribe();

  }

}
