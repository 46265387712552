import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGRThemes } from '../app/Interfaces/ithemes';
import { iGetTransactionList, iRTransactionList } from '../app/Interfaces/itransaction';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class TransactionService {
  private storage: any;

  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  GetTransactions(GTL:iGetTransactionList) {
    try {
      let url = this.baseUrl + 'api/transaction/v1/list/';
      return this.dataService.post<iRTransactionList>(url, GTL).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

}
