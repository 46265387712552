import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
//import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataTableService } from '../../../services/dataTable.service';
import { PasService } from '../../../services/pas.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IdatatableResponse } from '../../Interfaces/idatatable';
//import { CurrencyMaskModule } from "..ng2-currency-mask";

@Component({
  selector: 'app-priceedit',
  templateUrl: './priceedit.component.html'
})

export class PriceEditComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
customerId:string=""
  persontypelist: IdatatableResponse[];
  departmentlist: IdatatableResponse[];
  citylist: IdatatableResponse[];
  citylistfilter: IdatatableResponse[];
  taxresponsibilitieslist: IdatatableResponse[];
  taxschemelist: IdatatableResponse[]

  constructor(
    private formBuilder: FormBuilder, 
    private securityService: SecurityService,
    private router: Router,
    private pasService:PasService,
    private datatableservice: DataTableService,
    private dialogRef: MatDialogRef<PriceEditComponent>,
    private _snackBar: MatSnackBar
  ) {
    this.formedit = formBuilder.group({
      minPrice: [{ value: (this.pasService.cost+this.pasService.taxes)*(this.pasService.minPercentage>0?1+(this.pasService.minPercentage/100):1), disabled: true }],
      price: [{ value: this.pasService.price, disabled: true }],
      newPrice: [this.pasService.price, [Validators.required]]
    })
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    this.pasService.getMinPrice(this.pasService.pasID, "N").subscribe(_Res => {
      if (_Res.body.isValid) {
        let _MinPrice = _Res.body.resultData;
        this.formedit.controls['minPrice'].setValue(_MinPrice);
      }
    });
    //this.formedit.controls['newPrice'].setValue(_MinPrice);

  //  this.customerservice.get(this.customerservice.customerID).subscribe(results => {
  //    if (results.body.isValid) {
  //      this.customerId = results.body.resultData.idTypeName + " " + results.body.resultData.idnumber;
  //      this.formedit.controls['name'].setValue(results.body.resultData.name);
  //      this.formedit.controls['lastname'].setValue(results.body.resultData.lastName);
  //      this.formedit.controls['address1'].setValue(results.body.resultData.address1);
  //    }
  //    else { this.mensaje = results.body.error.description; }
  //  },
  //    (e) => {
  //      this.mensaje = e.error;
  //      console.log(e);
  //    });
  }


  Cancel() {
    this.dialogRef.close();
  }

  Accept()
  {
    if (this.formedit.controls["newPrice"].value < this.formedit.controls["minPrice"].value) {
      alert("El precio es menor al estipulado");
return 
    }
          this.dialogRef.close(this.formedit.controls["newPrice"].value);
  }



  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }
 

}
