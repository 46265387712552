import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGRAllPASTaxes } from '../app/Interfaces/ipas';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class TaxesService {
  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public getPASTaxes() {
    let url = environment.urlAPI + 'api/taxes/v1/pas/';
    try {
      return this.dataService.get<IGRAllPASTaxes>(url).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

}
