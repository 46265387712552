import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataService } from '../../../services/data.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { Icc } from '../../Interfaces/icc';
import { IcustomerlistResponse } from '../../Interfaces/icustomer';
import { Icxc01, Icxc01Response } from '../../Interfaces/icxc';

@Component({
  selector: 'app-cxc01',
  templateUrl: './cxc01.component.html',
    styleUrls: ['./cxc01.component.css']
})

export class cxc01Component implements OnInit {

  formReport: FormGroup;
  subRef$: Subscription;

  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  transactionscxc: Icxc01Response[] = [];
  dataSourcecxc = null;

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private router: Router,
    private securityService: SecurityService
  ) {
    this.formReport = formBuilder.group({
      cc_id: [null, Validators.required],
      customer_id: [null, Validators.required]
    });
    this.Columns = ['ccname','customername', 'customeridname', 'transaction_id','transaction_prefix','docnumber','date', 'value','duedate','paymentdate','c30','c60','c90','cmore','debtbalance'];
  }
  cc: Icc[];
  customer: IcustomerlistResponse[];
  selectedvalue: number; selectedview: string;
  r_cxc: 0;

  ngOnInit() {
    const url = environment.urlAPI + 'api/cc/ccuser/';
    this.cc = []; 
    this.dataService.get<Icc[]>(url + "0").subscribe(results => {
      for (const entry of results.body) {
        this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
      }
    }, (e) => {
      console.log(e);
    });
    this.customer = []; //{ cc_id: 0, cc_name: 'Todos' }
    this.addCustomer({ customer_id: 0, customer_name: "Todos" });

    this.dataService.get<IcustomerlistResponse[]>(environment.urlAPI + 'api/customer/list/' + '0').subscribe(results => {
      for (const entry of results.body) {
        this.addCustomer({customer_id: entry.id, customer_name: entry.name, branchoffice:entry.branchoffice });
      }
    }, (e) => {
      console.log(e);
    });

  }

  Generar() {
    const infcxc: Icxc01 = {
      cc_id: this.formReport.value.cc_id,
      customer_id:this.formReport.value.customer_id
    };
    this.mensaje = "";
    const url = environment.urlAPI + 'api/informes/cxc01/';
    this.transactionscxc = [];
    this.r_cxc = 0;
    this.dataService.post<Icxc01Response[]>(url, infcxc).subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No hay datos";
      for (const entry of results.body) {
        this.addTransaction({
          id: entry.id, cc_id: entry.cc_id, ccname: entry.ccname, customer_id: entry.customer_id,
          customername: entry.customername, customeridname: entry.customeridname, branchoffice: entry.branchoffice,
          docnumber: entry.docnumber, transaction_id: entry.transaction_id, transaction_prefix: entry.transaction_prefix,
          paymmeth_id: entry.paymmeth_id, paymmeth_support: entry.paymmeth_support, type: entry.type, date: entry.date.substring(0, 10).trim(),
          value: entry.value, duedate: entry.duedate.substring(0, 10).trim(), observation: entry.observation, paymentdate: entry.paymentdate.substring(0, 10),
          paidvalue:entry.paidvalue, status:entry.status,c30:entry.c30, c60:entry.c60,c90:entry.c90,cmore:entry.cmore ,debtbalance:entry.debtbalance});
      }
      this.dataSourcecxc = new MatTableDataSource<Icxc01Response>(this.transactionscxc);
      this.r_cxc = this.dataSourcecxc.data.length;
    },
      (e) => {
        this.mensaje =e.error;
        console.log(e);
      });
  }

  addTransaction(transaction) {
      this.transactionscxc.push(transaction);
  }

  addCc(V_cc) {
    try {
      this.cc.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }

  addCustomer(v_customer) {
    try {
      this.customer.push(v_customer);
    }
    catch (e) {
      console.log(e);
    }
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formReport.get(nombreControl);
    return control.hasError(validacion);
  }

  getTotalvaluecxc() {
    return this.dataSourcecxc.data.map(t => t.debtbalance).reduce((acc,debtbalance) => acc +debtbalance, 0);
  }
  getTotalvaluec30() {
    return this.dataSourcecxc.data.map(t => t.c30).reduce((acc,c30) => acc +c30, 0);
  }
  getTotalvaluec60() {
    return this.dataSourcecxc.data.map(t => t.c60).reduce((acc, c60) => acc + c60, 0);
  }
  getTotalvaluec90() {
    return this.dataSourcecxc.data.map(t => t.c90).reduce((acc, c90) => acc + c90, 0);
  }
  getTotalvaluecmore() {
    return this.dataSourcecxc.data.map(t => t.cmore).reduce((acc, cmore) => acc + cmore, 0);
  }

  b_back() {
    this.router.navigate(['informes']);
  }



}
