/// <reference path="../../../../services/data.service.ts" />
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CompanyService } from '../../../../services/company.service';
import { CustomerService } from '../../../../services/customer.service';
import { DataService } from '../../../../services/data.service';
import { RegistryService } from '../../../../services/registry.service';
import { RouterService } from '../../../../services/router.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { IlicenseslistResponse } from '../../../Interfaces/icustomer';
import { IuserPermissionsResponse } from '../../../Interfaces/iuser';
import { ActivateComponent } from '../activate/activate.component';
import { LicenseAddComponent } from '../add/licenseadd.component';
import { LicenseDetailComponent } from '../detail/licensedetail.component';
import { StatusComponent } from '../status/status.component';

@Component({
  selector: 'app-licenseslist',
  templateUrl: './licenseslist.component.html'
})

export class LicensesListComponent implements OnInit {
  subRef$: Subscription;
  r_gridregs: 0;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  DataGrid: IlicenseslistResponse[] = [];
  dataList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  url: string;
  _currentId: number;

  constructor(
    //private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private companyservice: CompanyService,
    private routeparent: RouterService,
    private customerservice: CustomerService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog, private registryService: RegistryService
  ) {
    this.Columns = ['license', 'statusname','key','idinstallation','noveltyDate','b_menu']
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    //permisos
    this.url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    this.dataService.get<IuserPermissionsResponse[]>(this.url + "LICENSES").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
      this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    this.loadData();
  }

  loadData()
  {
    //licenses
    this.DataGrid= [];
    this.r_gridregs = 0;
    this.registryService.ListFromCustomer(this.customerservice.customerID).subscribe(results => {
      if (results.body.length == 0) {
        this.mensaje = "No hay datos";
        this.openSB(this.mensaje);
      }
      for (const entry of results.body) {
        this.addData({
          id: entry.id, license: entry.license, status: entry.status, statusname: entry.statusname,
          key: entry.key, idinstallation: entry.idinstallation, invoiceHId: entry.invoiceHId, providerId: entry.providerId, noveltyDate: entry.noveltyDate.toString().substring(0,10)
        });
      }
      this.dataList = new MatTableDataSource<IlicenseslistResponse>(this.DataGrid);
      this.r_gridregs = this.dataList.data.length;
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        {
          //switch (permission.code) {
          //  case "S": {
              this.menuPop.push(permission);
          //    break;
          //  }
          //}
          break;
        }
    }
  }

  b_gen(PPrmCode, link, id) {
    switch (PPrmCode) {
      case "N":
        this.b_add();
        break;
      default:
        this.router.navigate([link]);
        break;
    }
  }

  b_back(link, id) {
    this.router.navigate([this.routeparent.routerParent]);
  }

  b_CurrentId(p_Id: number) {
    this._currentId = p_Id;
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  b_execute(prmCode: string, pLink: string) {
    var _Reg = this.DataGrid.find(x => x.id == this._currentId);
    this.registryService.putValues(_Reg.id, _Reg.license, _Reg.invoiceHId, _Reg.providerId, _Reg.status);
    switch (prmCode) {
      case "A":
        try {
          switch (_Reg.status) {
            case "A":
            case "S":
              this.openSB("La licencia ya fue concedida");
              break;
            case "R":
              this.b_activate();
              break;
            default:
              this.openSB("Solo se puede activar una licencia solicitada");
          }
        }
        catch (e) {
          alert(e.message)
        }
        break;
      case "S":
        try {
          this.b_status();
          }
        catch (e) {
          alert(e.message)
        }
        break;
      case "D":
        this.b_detail();
        break;
      case "L":
        if (_Reg.status == "R") {
          var _Delete = confirm("Desea eliminar la solicitud?");
          if (_Delete) {
            this.registryService.Delete(this._currentId).subscribe(_result => {
              if (_result.body.isValid) {
                this.openSB("Solicitud eliminada");
                this.loadData();
              }
              else {
                alert(_result.body.error.description);
              }
            })
          };
        }
        else {
          this.openSB("Solo se pueden eliminar una licencia solicitada");
        }
    }
  }

  b_detail() {
    //id,license,invoice,provider
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'my-class';
    const dialogRef = this.dialog.open(LicenseDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
        } else {
        }
      }, (e) => console.log(e)
    )
  }

  b_add() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'my-class';
    const dialogRef = this.dialog.open(LicenseAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.loadData();
        }
        else {
          console.log('Undefined data. No will insert');
        }
      }, (e) => console.log(e)
    )
  }

  b_activate() {
    //id,license,invoice,provider
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'my-class';
    const dialogRef = this.dialog.open(ActivateComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data)
        {
          //data.providerId = parseInt(data.providerId, 10);
          //data.invoiceNumber = data.invoiceNumber.trim();
          //this.registryService.putUpdate(data).subscribe(response => {
          //  if (!response.body.isValid) {
          //    console.log(response.body.error.description);
          //  }
          //  else {
              this.loadData();
              //this.openSB(response.body.resultData.description);
          //  }
          //}, (e) => {
          //  console.log(e);
          //});
        }
        else
        {
          console.log('Undefined data. No will insert');
        }
      }, (e) => console.log(e)
    )
  }

 b_status() {
    //id,license,invoice,provider
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
   dialogConfig.autoFocus = true;
   dialogConfig.panelClass = 'my-class';
    const dialogRef = this.dialog.open(StatusComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          data.providerId = parseInt(data.providerId,10);
          this.registryService.putUpdate(data).subscribe(response => {
            if (!response.body.isValid) {
              console.log(response.body.error.description);
            }
            else {
              this.loadData();
              this.openSB(response.body.resultData.description);
            }
          }, (e) => {
            console.log(e);
          
          });
        } else {
          console.log('Undefined data. No will insert');
        }
      }, (e) => console.log(e)
    )
  }

  //private saveNewCourse(courseToInsert: Course) {
    //this.apiService.addCourse(courseToInsert).subscribe();
  //}

  ngOnDestroy(): void {
    //this.sub.unsubscribe();
  }
}
