import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataService } from '../../../services/data.service';
import { PasService } from '../../../services/pas.service';
import { UploadFilesService } from '../../../services/upload-files.service';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';

@Component({
  selector: 'pasStatus-data',
  templateUrl: './pasStatus.component.html'
})

export class PASStatusComponent {
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;

  selectedFiles: FileList;
  //Es el array que contiene los items para mostrar el progreso de subida de cada archivo
  progressInfo = []
  message = '';
  fileName = '';
  fileInfos: Observable<any>;

  constructor(private uploadFilesService: UploadFilesService, private dataService: DataService,
    private _snackBar: MatSnackBar,
    private PASService: PasService,
    private router: Router) { }

  async ngOnInit() {
    this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
    this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
    this.fileInfos = this.uploadFilesService.getFiles();
  }

  selectFiles(event) {
    this.progressInfo = [];
    event.target.files.length == 1 ? this.fileName = event.target.files[0].name : this.fileName = event.target.files.length + " archivos";
    this.selectedFiles = event.target.files;
  }

  uploadFiles() {
    this.message = '';
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i]);
    }
  }

  async upload(index, file) {
    this.progressInfo[index] = { value: 0, fileName: file.name };
    //const url = environment.urlAPI;
    const formData: FormData = new FormData();
    formData.append('files', file);
    await this.PASService.UpFileStatus(formData).subscribe(results => {
      if (results.body.isValid) {
        this.openSB(results.body.resultData.description);
        this.progressInfo[index].value = 100;
        this.message = results.body.resultData.description;
      }
      else {
        alert("Error");
        this.message = results.body.error.description;
      }
    },
      (e) => {
        alert(e.error)
        this.message = e.error + '. En ' + file.name;
        console.log(e);
      });
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  //addPermission(permission) {
  //  this.Permissions.push(permission);
  //  if (permission.type == "G") {
  //    this.MenuG.push(permission);
  //  }
  //}

  b_gen(link, id) {
    this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate(['home']);
  }

}
