/// <reference path="../../../services/data.service.ts" />
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CcService } from '../../../services/cc.service';
import { PermissionsService } from '../../../services/permissions.service';
import { RouterService } from '../../../services/router.service';
import { TransferService } from '../../../services/transfer.service';
import { UserService } from '../../../services/user.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { TransferDetail } from '../../Interfaces/itransfer';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';

@Component({
  selector: 'app-transferDetail',
  templateUrl: './transferDetail.component.html'
})

export class TransferDetailComponent implements OnInit {
  filterForm: FormGroup;
  subRef$: Subscription;
  r_gridregs: 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  Columns: string[] = [];
  DataGrid:TransferDetail[] = [];
  dataList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  transferNumber: number = this.transferService.docnumber;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  ngAfterViewInit() {
    //this.dataList.paginator = this.paginator;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    //private dataService: DataService,
    //private securityService: SecurityService,
    //private companyservice: CompanyService,
    private routeparent: RouterService,
    //private passervice: PasService,
    private _snackBar: MatSnackBar,
    private ccService: CcService,
    private userService: UserService,
    private transferService: TransferService,
    private permissionservice: PermissionsService,
  ) {
    this.Columns = ['pasName', 'pasUM', 'averageCost', 'price', 'quantity'];
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    //this.transferNumber = this.transferService.docnumber;
    //permisos
    this.Permissions = [];
    this.MenuG = [];
    //this.permissionservice.getPermission("TRANSFERDETAIL").subscribe(_Result => {
    //  if (_Result.body.length == 0)
    //    this.mensaje = "No tiene permisos";
    //  for (const entry of _Result.body) {
    //    this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
    //  }
    //  this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
    //  this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    //},
    //  (e) => {
    //    this.mensaje  =  e.error;
    //    console.log(e);
    //  });
    this.Filter();
  }

  Filter() {
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.transferService.getTransferDetail(this.transferService.id).subscribe(_Result => {
      if (_Result.body.isValid) {
        for (const entry of _Result.body.resultData) {
          this.addData(entry);
        }
        this.dataList = new MatTableDataSource<TransferDetail>(this.DataGrid);
        this.dataList.paginator = this.paginator;
        this.r_gridregs = this.dataList.data.length;
      }
      else {
        this.openSB("No hay datos");
        this.mensaje = "No hay datos";
      }
    },
      (e) => {
        this.openSB(e.message);
        this.mensaje =e.message;
        console.log(e);
      });
  }


  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
          this.MenuG.push(permission);
    }
    else {
      switch (permission.code) {
        case "M": {
          this.Columns.push("b_mod");
          break;
        }
      }
    }
  }

  b_back() {
    this.router.navigate([this.routeparent.routerParent]);
  }


  b_gen(link, id) {
        this.router.navigate([link]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  getTotalAverageCost() {
    return this.dataList.data.map(t => t.averageCost).reduce((acc,averageCost) => acc +averageCost, 0);
    //let total = 0;
    //for (let i = 0; i < this.dataSourceVBFPG.length; i++) {
    //  total = total + parseInt(this.dataSourceVBFPG[i].value);
    //}
    //return total;
  }

  getTotalPrice() {
    return this.dataList.data.map(t => t.price).reduce((acc, price) => acc +price, 0);
  }

  getTotalQuantity() {
    return this.dataList.data.map(t => t.quantity).reduce((acc,quantity) => acc +quantity, 0);
  }

}
