import { Injectable } from '@angular/core';

@Injectable()
export class CourseService {
  private storage: any;

  id: number = 0;
  name: string = "";
  contentid: number = 0;
  parentId: number = 0;


  constructor() { }

  public putValues(courseid:number, coursename:string, contentid:number, parentId:number) {
    this.id = courseid;
    this.name = coursename;
    this.contentid = contentid;
    this.parentId =parentId;
  }
}

