import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { getPasGroupAssigned, GRPASGroupAssigned, IGetPASList, putUserPASGroup } from '../app/Interfaces/igrouppas';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class PasGroupService {
  private storage: any;
  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public GetGroups(pPASId:number) {
    try {
      let url = this.baseUrl + 'api/pasgroup/list/';
      return this.dataService.get<IGetPASList>(url +pPASId.toString())
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  public GetAssigned(p_LL: getPasGroupAssigned) {
    try {
      let url = this.baseUrl + 'api/pasgroup/v1/assigned/';
      return this.dataService.post< GRPASGroupAssigned>(url, p_LL)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("error");
    }
  }

  public PutUserPASGroup(p_UPG: putUserPASGroup[]) {
    try {
      let url = this.baseUrl + 'api/pasgroup/v1/userpasgroup/';
      return this.dataService.post<IgeneralResponse03>(url, p_UPG)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("error");
    }
  }


}
