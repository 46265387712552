import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse } from '../app/Interfaces/igeneralresponse';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { GetProviderList, GRProviderDetail, GRProviderList, IProviderDetail, IProviderRegistry, ProviderUpdate } from '../app/Interfaces/iprovider';
import { environment } from '../environments/environment';
import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class ProviderService {

  id: number = 0;
  name: string = "";
  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(PId, PName) {
    this.id =PId;
    this.name =PName;
  }

  Registry(PReg:IProviderRegistry) {
    try {
      let url = environment.urlAPI + 'api/provider/v2';
      return this.dataService.post<IgeneralResponse03>(url, PReg).pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  //check( PIdType: string, PIdNumber: string, branchOffice: number) {
  //  try {
  //    const Iget: customerdocid = {
  //      branchOffice:0, idnumber:PIdNumber, idtype:PIdType
  //    };
  //    let url = environment.urlAPI + 'api/customer/docid/';
  //    return  this.dataService.post<IgeneralResponse02>(url, Iget).
  //      pipe(res => { return res });
  //  }
  //  catch (e) {
  //    console.log("Error");
  //  }
  //}

  get(pId:number) {
    try {
      let url = environment.urlAPI + 'api/provider/v1/';
      return this.dataService.get<GRProviderDetail>(url + pId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  list( PFilter:GetProviderList) {
    try {
      let url = this.baseUrl + 'api/provider/v1/list/';
      return this.dataService.put<GRProviderList>(url,PFilter)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  putUpdate(PPr:ProviderUpdate) {
    try {
      let url = environment.urlAPI + 'api/provider/v1';
      return this.dataService.put<IgeneralResponse03>(url,PPr).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("error");
    }
  }
}
