import { Component, NgModule, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomerService } from '../../../services/customer.service';
import { DataService } from '../../../services/data.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IcustomerdetailResponse } from '../../Interfaces/icustomer';
import { IdatatableResponse } from '../../Interfaces/idatatable';
//import { MatCurrencyFormatModule } from 'mat-currency-format';
import { CommonModule, CurrencyPipe } from '@angular/common'
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { MatDialogRef } from '@angular/material';
//import { NgxCurrencyModule } from "ngx-currency";

@Component({
  selector: 'app-customerdetail',
  templateUrl: './customerdetail.component.html'

})

export class CustomerdetailComponent implements OnInit {

  formCustomerdetail: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  customeridname: string = "";
  r_lenlastname: 0;
  formattedAmount;


  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private customerservice: CustomerService,
    private routeparent: RouterService,
    private currencyPipe: CurrencyPipe,
    private dialogRef: MatDialogRef<CustomerdetailComponent>,

  ) {
    this.formCustomerdetail = formBuilder.group({
      name: { value: '' },
      lastname:  [''], 
      branchoffice: [0],
      address1: [''],
      telefono1: [''],
      email1: [''],
      web1: [''],
      creditlimit: [0],
      deadlinedays: [''],
    });
  }
  selectedvalue: number; selectedview: string;
  idtypelist: IdatatableResponse[];

  ngOnInit() {
    this.mensaje = "";
    this.idtypelist = [];
    //consultar el cliente
    let url = environment.urlAPI + 'api/customer/';
    this.dataService.get<IcustomerdetailResponse>(url +this.customerservice.customerID).subscribe(results => {
      if (results.ok) {
        this.r_lenlastname = results.body[0].lastName.length;
        this.customeridname = results.body[0].idtypename + " " + results.body[0].idnumber;
        this.formCustomerdetail.controls['name'].setValue(results.body[0].name);
        //if (empty(results.body[0].lastName)) {
        //this.formCustomerdetail.controls['lastname'].
        this.formCustomerdetail.controls['lastname'].setValue(results.body[0].lastName);
        this.formCustomerdetail.controls['branchoffice'].setValue(results.body[0].branchOffice);
        this.formCustomerdetail.controls['address1'].setValue(results.body[0].address1);
        this.formCustomerdetail.controls['telefono1'].setValue(results.body[0].telephone1);
        this.formCustomerdetail.controls['email1'].setValue(results.body[0].email1);
        this.formCustomerdetail.controls['web1'].setValue(results.body[0].web1);
        const v_cl =Number( results.body[0].creditLimit);
        this.formCustomerdetail.controls['creditlimit'].setValue(this.currencyPipe.transform(Number(results.body[0].creditLimit),'$'));
        this.formCustomerdetail.controls['deadlinedays'].setValue(results.body[0].deadLineDays);
        this.formCustomerdetail.disable();
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  transformAmount(element) {
    this.formattedAmount = this.currencyPipe.transform(this.formattedAmount, '$');
    element.target.value = this.formattedAmount;
  }

  b_back() {
    this.dialogRef.close();
    //this.router.navigate([this.routeparent.routerParent]);
  }



}
