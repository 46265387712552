import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PaymMethService } from '../../../services/paymmeth.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { PaymMethClass, PaymMethId } from '../../Interfaces/ipaymmeth';

@Component({
  selector: 'app-paymmethadd',
  templateUrl: './paymmethadd.component.html'
})

export class PaymMethAddComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  paymMethClasses: PaymMethClass[] = [];

  constructor(
    private formBuilder: FormBuilder, 
    private securityService: SecurityService,
    private router: Router,
    private paymmethservice: PaymMethService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<PaymMethAddComponent>,
  ) {
    this.formedit = formBuilder.group({
      code: [{value:'',disabled: true }, Validators.required],
      name: ['', Validators.required],
      class: [0, Validators.required],
      pmStatus: '',
      initials: ['', Validators.required],
      commissionpercentage: [0]
    })
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    //this.formedit.controls['code'].disable();
    this.mensaje = "";
    this.paymmethservice.getClass().subscribe(result => {
      if (result.body.isValid) {
        for (const entry of result.body.resultData) {
          this.addPaymMethClass(entry);
        }
      }
    });
    this.paymmethservice.getAvailableCode().subscribe(results => {
      if (results.body.isValid) {
        this.formedit.controls['code'].setValue(results.body.resultData.code);
      }
      else {
        this.mensaje = results.body.error.description;
        alert(this.mensaje);
        this.Cancel();
      }
    },
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
        this.Cancel();
      });
  }


  Cancel() {
    this.dialogRef.close();
    //this.router.navigate(['paymmethlist']);
  }

  Save()
  {
    try {
      this.formedit.controls['code'].enable();
      const pm:PaymMethId = {
        id: 0,
        name: this.formedit.value.name,
        class: this.formedit.value.class,
        status: (this.formedit.value.pmStatus ? "A" : "I"),
        code: this.formedit.value.code,
        commissionPercentage: this.formedit.value.commissionpercentage,
        initials: this.formedit.value.initials 
      };
      this.paymmethservice.add(pm).subscribe(results => {
        if (results.ok) {
          this.openSB(results.body.resultData.description);
          this.dialogRef.close(true);
          //this.router.navigate(['paymmethlist']);
        }
        else {
          alert("Error");
        }
      },
        (e) => {
          this.mensaje = e.error;
          console.log(e);
        }

      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
    this.formedit.controls['code'].disable();
  }

  addPaymMethClass(pmc) {
    this.paymMethClasses.push(pmc);
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  seleccionar(item: { id: string })
  {
    //if (item["selected"] == true)  item["selected"] = false; 
    //else item["selected"] =true; 
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
