import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { AdService } from '../../../services/ad.service';
import { DataService } from '../../../services/data.service';
import { IAddAd } from '../../Interfaces/iad';

@Component({
  selector: 'app-addad',
  templateUrl: './addad.component.html'
})
export class AddAdComponent implements OnInit {

  private sub: Subscription;
  private _data: IAddAd;
  private dataService: DataService;
  private mensaje: string="";
  url: string;
  //@Input() _providerid: number;

  constructor(private dialogRef: MatDialogRef<AddAdComponent>, private adService:AdService) {
      }

  ngOnInit(): void { this.loadContent(); }

  loadContent() {
    this._data = { name: "", url: "",status:"A", condition:"", dueDate:new Date(), app:"KERXV", ccId:0,type:"P" };
  }

  addAd(p_Ad) {
    try {
      this._data=p_Ad;
    } catch (e) { console.log(e); }
    }

  
  closeWithoutSave() {
    this.dialogRef.close();
  }

}
