import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';

@Component({
  selector: 'app-keypsw',
  templateUrl: './keypsw.component.html'
})

export class KeyPswComponent implements OnInit {
  IsAuthenticated = false;
  formKeyPsw: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  msgtype: string = "";
  //key: string = "";

  constructor(
    private formBuilder: FormBuilder, 
    private router: Router,
    private userservice: UserService,
    private _snackBar: MatSnackBar
  ) {
    this.formKeyPsw = formBuilder.group({
      key: [null, [Validators.required]]
    });
  }


  ngOnInit() {
    this.mensaje = "Revise su correo e ingrese el código enviado.";
    this.msgtype = "msg-notify"
  }

  Send()
  {
    this.userservice.ValidateKeyToRestorePswV2(this.formKeyPsw.value.key.trim()).subscribe(_result => {
      if (_result.body.isValid) {
        this.mensaje =_result.body.resultData.description;
        this.msgtype = "msg-notify";
this.userservice.putValues(_result.body.resultData.id,"")
        this.router.navigate(["restorepsw2"]);
      }
      else {
        this.mensaje =_result.body.error.description;
        this.msgtype = "msg-notify";
      }
    },
      (e) => {
        alert(e.error);
      });
  }

  Cancel()
  {
    this.router.navigate(["login"]);
  }



  hasError(nombreControl: string, validacion: string) {
    const control = this.formKeyPsw.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


}
