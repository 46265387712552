import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PasService } from '../../../services/pas.service';
import { PermissionsService } from '../../../services/permissions.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { pasPrice, putPrices } from '../../Interfaces/ipas';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';
import { PriceEditComponent } from './priceedit.component';

@Component({
  selector: 'app-pricemodify',
  templateUrl: './pricemodify.component.html'
})

export class PriceModifyComponent implements OnInit, AfterViewInit {
  formFilter: FormGroup;
  subRef$: Subscription;
  r_gridregs:number= 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  DataGrid:pasPrice[] = [];
  dataList = new MatTableDataSource;
  Columns: string[] = [];
  Permissions: IuserPermissionsResponse[] = [];
  //pasdataResponse: pasdataResponse;
  //pasdata: pasdata;
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  name: string = "";
  code: string = "";
  listIndex: number = 0;


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private securityService: SecurityService,
    private routeparent: RouterService,
    private pasService: PasService,
    private permissionsService: PermissionsService,
    private _snackBar: MatSnackBar,
private dialog: MatDialog
  ) {
    this.formFilter = formBuilder.group({
      code: [null, { validators: [Validators.required] }] , newPrice:[0] //,asyncValidators: [this.codeexist.bind(this)], updateOn:'blur'
    });

    this.Columns = ['code', 'name', 'ccName', 'cost', 'priceListName', 'price', 'newPrice','b_mod'];//'ccName',
  }
    changes: Subject<void>;
    itemsPerPageLabel: string;
    nextPageLabel: string;
    previousPageLabel: string;
    firstPageLabel: string;
    lastPageLabel: string;
    getRangeLabel: (page: number, pageSize: number, length: number) => string;


  selectedvalue: number; selectedview: string;

  ngOnInit() {
    //permisos
    this.Permissions = [];
    this.MenuG = [];
    this.permissionsService.getPermission("PASPRICEMOD").subscribe(results => {
      if (results.body.length == 0) {
        this.mensaje = "No tiene permisos";
      }
      for (const entry of results.body) {
        this.addPermission(entry);
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    //cc
  }


  quantityvalue(control: AbstractControl): Observable<ValidationErrors|null>{
    if (control.value == 0) {
      this.mensaje = "Debe digitar cantidad";
        map((data: any) => { return true 
      })
    }
    else {
      this.mensaje = "";
      return of(null);
    }
  }

  b_Cancel()
  {
    if (this.r_gridregs > 0)
    {
      var _confirm = confirm("Desea cancelar la operación?");
      if (_confirm)
        this.router.navigate(['pasmenu']);
    }
    else {
      this.router.navigate(['pasmenu']);
    }
  }

  Save()
  {
    let regProccesed: number = 0;
    let regSaved: number = 0;
    for (const entry of this.dataList.data)
    {
      if (entry["newPrice"]>0 && entry["price"] != entry["newPrice"] )
      {
        regProccesed++;
        const _price: putPrices={
          pasId: entry["id"], ccId: entry["ccId"], priceListId: entry["priceListId"], newPrice: entry["newPrice"]
        };
        this.pasService.savePrices(_price).subscribe(result => {
          if (result.body.isValid) {
            regSaved++;
          }
          else {
            alert("Error al modificar el PAS: " + entry["code"]);
          }
        }
          , (e) => console.log(e)
        );
      }
    }
    if (regProccesed == 0) {
      this.openSB("No hay cambios");
    }
    else {
      //if (regProccesed > regSaved) {
      //  return;
      //}
      //else {
        this.openSB("Precios actualizados");
      //}
    }
    this.router.navigate(['pasmenu']);
  }

  codeexist(control: AbstractControl): Observable<ValidationErrors | null> {
    try {
      var x=this.dataList.data.find(x => x["code"] == control.value);
    }
    catch (e) {
      this.mensaje =e.message+ " No existe";
      return null; // { of: null }; // validcode: true };
    }
  }

  b_Mod(pIndex: number) {
    this.listIndex = pIndex;
    this.pasService.putValues(this.dataList.data[pIndex]["id"], this.dataList.data[pIndex]["name"]);
    this.pasService.putPrices(this.dataList.data[pIndex]["cost"], this.dataList.data[pIndex]["price"], this.dataList.data[pIndex]["newPrice"], 0, 0);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "my-class";
      //dialogConfig.width= "100%!important";
      const dialogRef = this.dialog.open(PriceEditComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data) {
            this.dataList.data[pIndex]["newPrice"] =data; //..data.map(t => t.value).find.reduce((acc, value) => acc + value, 0);
            this.mensaje = "";
            console.log("Modificado");
          }
          else {
            console.log('Undefined data.');
          }
        }, (e) => console.log(e)
      );
  }

//  mod(pIndex: number, pValue: number) {
//    //pValue = this.formReport.controls["newPrice"].value;
//    try {
//      if (Number(pValue) > 0) {
//        this.dataList.data[pIndex]["newPrice"] = pValue; //..data.map(t => t.value).find.reduce((acc, value) => acc + value, 0);
//        this.mensaje = "";
//      }
//      else {
//        this.mensaje = "Error";
//        this.dataList.data[pIndex]["newPrice"] = 0;
//        //this.DataGrid.sh.indexOf(pIndex)
////this.DataGrid.
////..data.map(t => t.value).find.reduce((acc, value) => acc + value, 0);

//      }
//    }
//    catch (e) {
//      console.log(e.message);
//    }
//    return;

////this.dataList[]
//  }

  get() {
    this.mensaje = "Consultando...";
    try {
      var x = this.dataList.data.find(x => x["code"] == this.formFilter.controls['code'].value.trim());
      if (x != null) {
        this.mensaje = "Código ya relacionado "+this.formFilter.controls['code'].value.trim();

      }
      else {
        this.pasService.getBasePrices(this.formFilter.controls['code'].value.trim()).subscribe(result => {
          if (result.body.isValid) {
            for (const entry of result.body.resultData) {
              this.DataGrid.unshift({
                id: entry.id, code: entry.code, name: entry.name, presentation: entry.presentation, perishable: entry.perishable,
                type: entry.type, ccId: entry.ccId, ccName: entry.ccName,
                cost: entry.cost, priceListName: '', priceListId: 0, price: entry.price, newPrice: 0
              });
            }
            this.dataList = new MatTableDataSource<pasPrice>(this.DataGrid);
            this.dataList.paginator = this.paginator;
            this.r_gridregs = this.dataList.data.length;
            this.formFilter.controls['code'].reset();
            this.DataGrid.slice(0);
            this.mensaje = "";
          }
          else {
            this.mensaje = result.body.error.description; // "Código no existe";
          }
        },
          (e) => {
            console.log(e);
            return true;
          });
      }
    }
    catch (e) {
      this.formFilter.value.name = e.message + " No existe";
      return { validcode: true };
    }
    //document.getElementById("code").focus();
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
          this.MenuG.push(permission);
    }
    else {
    }
  }

  b_gen(link, id) {
        this.router.navigate([link]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formFilter.get(nombreControl);
    return control.hasError(validacion);
  }

}
