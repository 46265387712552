///<reference path="../../../services/data.service.ts" />
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdService } from '../../../services/ad.service';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { PermissionsService } from '../../../services/permissions.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IAd } from '../../Interfaces/iad';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';
import { AddAdComponent } from '../Add/addad.component';

@Component({
  selector: 'app-adlist',
  templateUrl: './adlist.component.html'
})

export class AdListComponent implements OnInit {
  subRef$: Subscription;
  r_regs: 0;
  public companyId: string;
  companyname: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  ad:IAd[] = [];
  adList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  menuPop: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;
  menuPopList = new MatTableDataSource;
  filtermenu = { type: 'G' };
  appName: string = "";
  _currentId: number = 0;

  constructor(
    //private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private companyservice: CompanyService,
    private permissionsService: PermissionsService,
    private routeparent: RouterService,
    private adService: AdService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.Columns = ['name','url','statusName','condition','b_menu'];
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    this.companyname = this.companyservice.companyName;
    //permisos
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    this.permissionsService.getPermission("ADS").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      else {
        for (const entry of results.body) {
          this.addPermission(entry);
        }
        this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
        console.log(this.MenuG);
        this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
        this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
        this.loadData();
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  loadData()
  {
    //ad
    this.ad= [];
    this.r_regs = 0;
    //temporal, this component must be used for any ad type, in this case just for keruxv  //0,"KERXV" have to change for = this.adService.CCId, this.adService.App
    this.appName="Verificador de precios"
    this.adService.getAdList(0, "KERXV").subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.addAd( entry );
        }
        this.adList = new MatTableDataSource<IAd>(this.ad);
        this.r_regs = this.adList.data.length;
      }
      else {
        this.mensaje =results.body.error.description;
      }
    },
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
        console.log(e);
      });
  }

  addAd(cc) {
      this.ad.push(cc);
  }

  addPermission(permission) {
    switch (permission.type) {
      case "G": {
        switch (permission.code) {
          case "A": {
              this.MenuG.push(permission);
            break;
          }
          default: {
            this.MenuG.push(permission);
            break;
          }
        }
        break;
      }
      case "R": {
        switch (permission.code) {
          case "M": { //modify account
            this.menuPop.push(permission);
            break;
          }
          case "D": {  //delete
            this.menuPop.push(permission);
            break;
          }
        }
        break;
      }
    }
  }

  b_CurrentId(p_Id: number) {
    this._currentId = p_Id;
  }

  b_reg(prmCode: string, p_Id: number) {
    switch (prmCode) {
      case "D":
        var _Delete = confirm("Eliminar el registro?");
        if (_Delete) {
          this.adService.DeleteAd(p_Id).subscribe(result => {
            if (result.body.isValid) {
              this.openSB(result.body.resultData.description);
              this.loadData();
            }
            else {
              this.openSB(result.body.error.description);
            }
          }
            , (e) => {
              console.log(e);
            });
        }
        break;
    }
  }

  b_gen(prmlink, prmcode) {
    switch (prmcode) {
      case "A":
        this.adService.putValues(0, this.adService.CCId, this.adService.App);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "my-class";
        const dialogRef = this.dialog.open(AddAdComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
            if (data) {
              if (data.name.trim() != "" && data.url.trim() != "") {
                this.adService.AddAd(data).subscribe(response => {
                  if (!response.body.isValid) {
                    console.log(response.body.error.description);
                  }
                  else {
                    this.loadData();
                    this.openSB(response.body.resultData.description);
                  }
                }, (e) => {
                  console.log(e);
                });
              }
              else {
                this.openSB("Error en los datos ingresados");
              }
            } else {
              console.log('Undefined data. No will insert');
            }
          }, (e) => console.log(e)
        );
        break
      default:
        this.router.navigate([prmlink]);
    }
  }

  b_back() {
    this.router.navigate(['home']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
