import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CcService } from '../../../../services/cc.service';
import { CountService } from '../../../../services/count.service';
import { DataService } from '../../../../services/data.service';
import { LocationService } from '../../../../services/location.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { count } from '../../../Interfaces/icount';
import { IgeneralResponse02 } from '../../../Interfaces/igeneralresponse02';

@Component({
  selector: 'app-cancelcount',
  templateUrl: './cancelcount.component.html'
})

export class CancelcountComponent implements OnInit {
  formadd: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  msgtype: string;
  v_ccid:number;
  r_ccregs: 0;

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private countService: CountService,
    private locationService: LocationService,
    private ccService: CcService,
    private securityService: SecurityService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.formadd = formBuilder.group({
      type: [null, Validators.required],
    });
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    if (this.countService.countingID == 0) this.Abort();
    this.mensaje = "Al confirmar la cancelación del conteo las cantidades ingresadas serán eliminadas y el inventario actual no se afectará.";

    this.msgtype = "msg-notify"
  }


  Accept()
  {
    try {
      const icc:count = {
        countingId: this.countService.countingID,
        ccId:this.ccService.ccID
      };
      const url = environment.urlAPI + 'api/count/tocancel/';
      this.dataService.put<IgeneralResponse02>(url,icc).subscribe(results => {
        if (results.ok) {
          this.locationService.locationID = 0;
          this.locationService.locationName = "";
          this.countService.countingID = 0;
          this.ccService.cccode = "";
          this.ccService.ccID = 0;
          this.ccService.ccName = "";
          this.openSB(results.body.resultData.description);
          this.router.navigate(['/ccselect']);
        }
        else { alert("Error"); }
      },
        (e) => {
          alert(e.error);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  Cancel()
  {
          this.router.navigate(['/locationselect']);
  }

  Abort() {
    this.router.navigate(['ccselect']);
  }


  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


}
