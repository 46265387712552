import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { IResponse } from '../app/Interfaces/iresponse';
import { Ipassword, IuserAdd, IUserFromCompanyV1 } from '../app/Interfaces/iuser';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class UserService {
  private storage: any;

  userID: number = 0;
  userName: string = "";
  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(userid:number,username) {
    this.userID = userid;
    this.userName = username;
  }

  PutPasswordV2(PData: Ipassword) {
    try {
      let url = this.baseUrl + 'api/user/passwordV2'
      return this.dataService.put<IResponse>(url, PData).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  PutPassword(PData:Ipassword) {
    try {
      let url = this.baseUrl + 'api/user/password'
      return this.dataService.put<IResponse>(url, PData).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  ValidateKeyToRestorePswV2(PKey: string) {
    try {
      let url = this.baseUrl + 'api/user/v2/restoremypsw/';
      return this.dataService.get<IgeneralResponse03>(url + PKey + "/").
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  ValidateKeyToRestorePsw(PUserId:string, PKey: string) {
    try {
      let url = this.baseUrl + 'api/user/v1/restoremypsw/';
      return this.dataService.get<IgeneralResponse03>(url + PUserId + "/" + PKey + "/").
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  SendEmailRestorePswV2(PUserId: number, PEmail: string) {
    try {
      let url = this.baseUrl + 'api/user/v2/sendrestorepsw/';
      return this.dataService.get<IgeneralResponse03>(url + PUserId + "/" + PEmail).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  SendEmailRestorePsw(PUserId:number,PEmail: string) {
    try {
      let url = this.baseUrl + 'api/user/v1/sendrestorepsw/';
      return this.dataService.get<IgeneralResponse03>(url+PUserId+"/"+PEmail).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  ValidateEmail(PEmail: string) {
    try {
      let url = this.baseUrl + 'api/user/v1/validateemail/';
      return this.dataService.get<IgeneralResponse03>(url + PEmail).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  AddFromCompany(PUser: IUserFromCompanyV1) {
    try {
      let url = this.baseUrl + 'api/user/v1/fromcompany/';
      return this.dataService.post<IgeneralResponse03>(url, PUser).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  AddV2(PUser: IuserAdd) {
    try {
      let url = this.baseUrl + 'api/user/v2/registry/';
      return this.dataService.post<IgeneralResponse03>(url, PUser).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  Add(PUser: IuserAdd) {
    try {
      let url = this.baseUrl + 'api/user/registry/';
      return this.dataService.post<IResponse>(url, PUser).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  //getPermissions(p_Function: string) {
  //  try {
  //    let url = this.baseUrl + 'api/user/permission/';
  //    return this.dataService.get<IuserPermissionsResponse[]>(url +p_Function)
  //      .pipe(res => { return res });
  //  }
  //  catch (e) {
  //    console.log("Error");
  //  }
 // }

}
