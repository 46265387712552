import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISaveCompModu } from '../app/Interfaces/icompmodu';
import { GRDataTable } from '../app/Interfaces/idatatable';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { GRModulesV2 } from '../app/Interfaces/imodules';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class ModuleService {

  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  SaveCompanyModulesV1(PSaveCompany: ISaveCompModu) {
    try {
      let url = this.baseUrl + 'api/module/v1/companymodules/';
      return this.dataService.post<IgeneralResponse03>(url, PSaveCompany)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }

  }

  GetTableModulesV1() {
    try {
      let url = this.baseUrl + 'api/module/v1/tablemodules/';
      return this.dataService.get<GRDataTable>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  GetModulesAvailablesV2(PCompanyId:number) {
    try {
      let url = this.baseUrl + 'api/module/v2/';
      return this.dataService.get<GRModulesV2>(url+PCompanyId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }
  
}
