import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../../../services/data.service';
import { LocationService } from '../../../services/location.service';
import { PasGroupService } from '../../../services/pasGroup.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { UserService } from '../../../services/user.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { getPasGroupAssigned, PASGroupAssigned, putUserPASGroup } from '../../Interfaces/igrouppas';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';

@Component({
  selector: 'app-pasGroupTiqueAssign',
  templateUrl: './pasGroupTiqueAssign.component.html'
})

export class PASGroupTiqueAssignComponent implements OnInit {

  formReport: FormGroup;
  subRef$: Subscription;
  r_gridregs: 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  DataGrid: PASGroupAssigned[] = [];
  dataList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;
  moduleId: number= 6;
  filtermenu = { type: 'G' };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private routerparent: RouterService,
    private pasGroupService: PasGroupService,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private locationService:LocationService 
  )
  {    
    this.Columns = ['code','longName','selected' ];
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    //pasgroups
    const _PGA: getPasGroupAssigned = {
      userId: this.userService.userID,
      moduleId: this.moduleId
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.dataList = [];
    this.r_gridregs = 0;
    this.pasGroupService.GetAssigned(_PGA).subscribe(results => {
      if (!results.body.isValid)
        this.openSB(results.body.error.description);
      else
      {
        for (const entry of results.body.resultData) {
          this.addData({ id: entry.id,code: entry.code, name: entry.name, longName: entry.longName, selected: entry.selected });
        }
        this.dataList = new MatTableDataSource<PASGroupAssigned>(this.DataGrid);
        this.r_gridregs = this.dataList.data.length;
      }
    },
      (e) => {
        this.openSB(e.error);
        console.log(e);
      });
  }

  addData(data) {
      this.DataGrid.push(data);
  }


  b_gen(link, id) {
        this.router.navigate([link]);
  }

  cancel() {
    this.router.navigate([this.routerparent.routerParent]);
  }

  save() {
    const putUPG: putUserPASGroup[] = [];
    for (const entry of this.dataList.data) {
      putUPG.push({userId:this.userService.userID,pasGroupId:entry.id, selected:entry.selected, moduleId:this.moduleId})
    }
    this.pasGroupService.PutUserPASGroup(putUPG).subscribe(_result => {
      if (_result.body.isValid) {
        this.openSB(_result.body.resultData.description);
        this.router.navigate([this.routerparent.routerParent]);
      }
      else {
        alert(_result.body.error.description);       
      }
    },
      (e) => {
      alert(e.error);
      console.log(e);
    });
  }

  select(item: { id: string }) {
    if (item["selected"] == true) item["selected"] = false;
    else item["selected"] = true;
  }

  allSelect(selected: boolean) {
      for (const entry of this.dataList.data) {
        entry.selected =selected;
      }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
