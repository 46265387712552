import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IlicenseslistResponse } from '../app/Interfaces/icustomer';
import { IgeneralResponse01 } from '../app/Interfaces/igeneralresponse';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { GRActivateLAC, GRRegistryDetail, iRegistryActivate, iRegistryAdd, iRegistryStatus, iRegistryUpdate } from '../app/Interfaces/iregistry';
import { environment } from '../environments/environment';
import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class RegistryService {
  id: number;
  license: string;
  invoiceHId: number;
  providerId: number;
  baseUrl = environment.urlAPI;
  status: string;

  constructor(private http: HttpClient, private dataService: DataService) { }

  GetStatusList(PCurrentStatus: string) {
    var _LStatus: iRegistryStatus[]=[];
    try {
      switch (PCurrentStatus) {
        case "R":
          _LStatus.push({ name: "SOLICITADA", code: "R" });
          break;
        case "A":
          _LStatus.push({ name: "ACTIVA", code: "A" });
          _LStatus.push({name:"SUSPENDIDA",code: "S"});
          _LStatus.push({ name: "INACTIVA", code: "I" });
          break;
        case "S":
          _LStatus.push({ name: "SUSPENDIDA", code: "S" });
          _LStatus.push({ name: "ACTIVA", code: "A" });
          break;
        case "I":
          _LStatus.push({ name: "INACTIVA", code: "I" });
          break;
        default:
          _LStatus.push({ name: "NO HAY ESTADOS", code: "" });
          break;
      }
    }
    catch (e) {
      console.log(e);
    }
    return _LStatus;
  }

  ListFromCustomer(PCustomerId:number) {
    try {
      let url = environment.urlAPI + 'api/registry/v1/licenses/'; //+ this.companyservice.companyID;
      return this.dataService.get<IlicenseslistResponse[]>(url + PCustomerId).pipe(res => {
        return res
      })
    }
    catch (e) {
      console.log(e);
    }
  }

  ValidateFormat(PLicense: string) {
    try {
      let url = this.baseUrl + "api/registry/v1/validateformat/";
      return this.dataService.get<IgeneralResponse03>(url + PLicense).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  Add(_reg: iRegistryAdd) {
    try {
      let url = this.baseUrl + "api/registry/v1/registry/";
      return this.dataService.post<IgeneralResponse01>(url, _reg).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //id,license,invoice,provider
  putValues(p_id,p_license,p_invoiceHId,p_providerId, PStatus) {
    this.id = p_id;
    this.license = p_license;
    this.invoiceHId = p_invoiceHId;
    this.providerId = p_providerId;
    this.status = PStatus;
  }

  getRegistryDetails(PLicId:number) {
    try {
      let url = this.baseUrl + "api/registry/v1/detail/";
      return this.dataService.get<GRRegistryDetail>(url + PLicId.toString()).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  Delete(PId:number) {
    try {
      let url = this.baseUrl + "api/registry/v1/";
      return this.dataService.delete<IgeneralResponse03>(url+PId).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  putUpdate(_reg:iRegistryUpdate) {
    try {
      //const _regt: iRegistryUpdate = { id:_reg.id, providerId:_reg.providerId,idInstallation:"",status:"",  invoiceHId:0, key:"",license:"" };
      let url = this.baseUrl + "api/registry/v1/update/";
      return this.dataService.post<IgeneralResponse03>(url,_reg).pipe(res => { return res });
      //return this.dataService.get<grcustomerlist>(url + "0").pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  Activate(_reg: iRegistryActivate) {
    try {
      let url = this.baseUrl + "api/registry/v1/activate/";
      return this.dataService.post<GRActivateLAC>(url, _reg).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

}
