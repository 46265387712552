import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { DataTableService } from '../../../services/dataTable.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IcompanymoduleResponse, IcompanyUpdate, ICUmodules, IPutFiscalData } from '../../Interfaces/icompany';
import { IdatatableResponse } from '../../Interfaces/idatatable';


@Component({
  selector: 'app-companyedit',
  templateUrl: './companyedit.component.html',
    styleUrls: ['./companyedit.component.css']
})



export class CompanyeditComponent implements OnInit {
  formCompanyedit: FormGroup;
  //@Input() companyId: number;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  companyname: string = "";
  companyidn: string = "";
  Columns: string[] = [];
  Modules: IcompanymoduleResponse[] = [];
  modulesList = null;
  fiscaltypelist: IdatatableResponse[];
  taxresponsibilitieslist: IdatatableResponse[];
  taxschemelist: IdatatableResponse[];
  nIdEntityTaxes: number = -1;



  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private companyservice: CompanyService,
    private datatableservice: DataTableService,
    private _snackBar: MatSnackBar,
    private location: Location
    //private route: ActivatedRoute
  ) {
    this.formCompanyedit = formBuilder.group({
      idtypename: ['', Validators.required],
      telefono1: ['', Validators.required],
      address1: ['', Validators.required],
      email1: ['', Validators.required],
      alternatecode01: [''],
      sysstatus: [false],
      fiscalType: [0, Validators.required],
      taxResponsibilities: ['', Validators.required],
      taxScheme: ['', Validators.required],
    })
    this.Columns = ['description', 'selected'];
  }
  selectedvalue: number; selectedview: string;
  r_companiesregs: 0;
  //companyname: string="";

  ngOnInit() {
    //para usar con router
    //let companyId = this.route.snapshot.paramMap.get("id");
    let companyId = this.companyservice.companyID;
    this.mensaje = "";
    //person types
    this.fiscaltypelist = [];
    this.datatableservice.getDataList("PRTYP").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.fiscaltypelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //tax responsbilities
    this.taxresponsibilitieslist = [];
    this.datatableservice.getDataList("TAXRS").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.taxresponsibilitieslist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //tax schemes
    this.taxschemelist = [];
    this.datatableservice.getDataList("TAXSC").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.taxschemelist.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });

    this.r_companiesregs = 0;
    this.companyservice.GetMyCompany(companyId).subscribe(results => {
      if (results.body.isValid) {
        this.companyname = results.body.resultData.name;
        this.companyidn = results.body.resultData.idtypename;
        this.formCompanyedit.controls['idtypename'].setValue(results.body.resultData.idtypename);
        this.formCompanyedit.controls['telefono1'].setValue(results.body.resultData.tele1);
        this.formCompanyedit.controls['email1'].setValue(results.body.resultData.email1);
        this.formCompanyedit.controls['address1'].setValue(results.body.resultData.address1);
        this.formCompanyedit.controls['alternatecode01'].setValue(results.body.resultData.alternateCode01);
        this.formCompanyedit.controls['sysstatus'].setValue(results.body.resultData.sysstatus == "A" ? true : false);
        this.companyservice.GetFiscalData(companyId).subscribe(resultfd => {
          if (resultfd.body.isValid) {
            this.formCompanyedit.controls['fiscalType'].setValue(resultfd.body.resultData.fiscalType);
            this.formCompanyedit.controls['taxScheme'].setValue(resultfd.body.resultData.taxScheme);
            this.formCompanyedit.controls['taxResponsibilities'].setValue(resultfd.body.resultData.taxResponsibilities);
            this.nIdEntityTaxes = resultfd.body.resultData.idEntityTaxes;
          }
          else {
            if (resultfd.body.error.code == 204) {
              this.nIdEntityTaxes = 0;
            }
          }
        });
      }
      else {
        alert("No hay datos");
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    //modulos
    this.Modules = [];
  }


  Cancelar() {
    this.location.back();
    //this.router.navigate(['/company']);
  }

  Grabar()
  {
    try {
      const activemodules: ICUmodules[] = [];
      //for (const entry of this.modulesList.data) {
      //  //if (entry.selected)
      //    activemodules.push({ code: entry.code, selected: entry.selected });
      //}
      const icompanyupdate: IcompanyUpdate = {
        id: this.companyservice.companyID,
        tele1: this.formCompanyedit.value.telefono1,
        tele2: '',
        email1: this.formCompanyedit.value.email1,
        address1: this.formCompanyedit.value.address1,
        cityaddress: '',
        countryaddress: '',
        alternatecode01:this.formCompanyedit.value.alternatecode01,
        sysstatus: this.formCompanyedit.value.sysstatus? "A" : "I",
        modules: activemodules
      };
      const url = environment.urlAPI + 'api/company/id/';
      this.dataService.put<IcompanymoduleResponse[]>(url, icompanyupdate).subscribe(results => {
        if (results.ok) {
          const oFiscalData: IPutFiscalData = {
            companyId:this.companyservice.companyID,
            fiscalType: this.formCompanyedit.value.fiscalType,
            taxResponsibilities: this.formCompanyedit.value.taxResponsibilities,
            taxScheme: this.formCompanyedit.value.taxScheme
          }
          if (this.nIdEntityTaxes == 0) {
            this.companyservice.PutFiscalData(oFiscalData).subscribe(results => {
              if (results.body.isValid) {
                this.openSB();
                this.location.back();
              }
              else {
                alert(results.body.error.description);
              }
            });
          }
          else {
            this.companyservice.UpdateFiscalData(oFiscalData).subscribe(results => {
              if (results.body.isValid) {
                this.openSB();
                this.location.back();
              }
              else {
                alert(results.body.error.description);
              }
            });
          }
        }
        else { alert("Error"); }
      },
        (e) => {
          this.mensaje = e.error;
          console.log(e);
        }

      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  addModule(module) {
    this.Modules.push(module);
  }


  hasError(nombreControl: string, validacion: string) {
    const control = this.formCompanyedit.get(nombreControl);
    return control.hasError(validacion);
  }

  seleccionar(item: { id: string })
  {
    //if (item.selected) {-		item	Object {code: "INVE", description: "INVENTARIO", selected: false, …}	Object

    //}		selected	false	boolean

    if (item["selected"] == true)  item["selected"] = false; 
    else item["selected"] =true; 

  }

  openSB() {
    this._snackBar.open('Grabado','', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg'] 
    });
  }
  //updateCheck() {
  //    this.modulesList.map((pizza) => {
  //      pizza.checked = true;
  //    });

//    } else {
 //     this.pizzaIng.map((pizza) => {
 //       pizza.checked = false;
  //    });
   // }
  //}

}
