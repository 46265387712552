import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DataTableService } from '../../../services/dataTable.service';
import { PasService } from '../../../services/pas.service';
import { PasGroupService } from '../../../services/pasGroup.service';
import { TaxesService } from '../../../services/taxes.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IdatatableResponse } from '../../Interfaces/idatatable';
import { IgrouppaslistResponse } from '../../Interfaces/igrouppas';
import { IPutCCPAS, PASTaxes, putPASTaxes } from '../../Interfaces/ipas';

@Component({
  selector: 'app-pasadd',
  templateUrl: './pasadd.component.html'
})

export class PASAddComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  groups: IgrouppaslistResponse[];
  measurementUnits: IdatatableResponse[];
  compositionClasses: IdatatableResponse[];
  //taxPercentageTypes: IdatatableResponse[];
  //taxValueTypes: IdatatableResponse[];
  statuses: IdatatableResponse[];
  element: HTMLElement;
  Columns: string[] = [];
  DataGrid: PASTaxes[] = [];
  taxList = new MatTableDataSource;
  _TaxRegs: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private datatableservice: DataTableService,
    private passervice: PasService,
    private pasgroupservice: PasGroupService,
    private dialogRef: MatDialogRef<PASAddComponent>,
    private _snackBar: MatSnackBar,
    private taxesservice: TaxesService

  ) {
    this.formedit = formBuilder.group({
      code: ['', [Validators.required, Validators.maxLength(20)]],
      name: ['', [Validators.required, Validators.maxLength(80)]],
      measurementUnit: ['UN', Validators.required],
      measurementQuantity: [1, Validators.required],
      presentation: ['', Validators.maxLength(50)],
      brand: ['', Validators.maxLength(80)],
      group: [-1, [Validators.required]],
      cost: [0, [Validators.required, Validators.min(1)]],
      price: [0, [Validators.required]],
      observation: ['', [Validators.maxLength(180)]],
      compositionClass: ['R', [Validators.required]],
      status: ['A', Validators.required],
      minProfitPercent: [0, [Validators.required, Validators.min(1), Validators.max(99)]],
      profitPercent: [0, [Validators.required, Validators.min(1), Validators.max(99)]]
    })
    this.Columns = ['name', 'selected', 'value'];
  }
  selectedvalue: number; selectedview: string;
  r_companiesregs: 0;
  codeSelect: string;

  ngOnInit() {
    //const { taxPercentageType, taxPercentage, taxValueType,taxValue } = this.formedit.controls;
    ////subscribe taxpercentagetype change
    //this.subRef$ = taxPercentageType.valueChanges.subscribe(
    //  (codeSelect) => {
    //    if (codeSelect == "N") {
    //      taxPercentage.disable();
    //      this.formedit.controls['taxPercentage'].setValue(0);
    //      this.formedit.controls['taxPercentage'].setValidators([Validators.min(0)]);
    //    }
    //    else {
    //      taxPercentage.enable();
    //      this.formedit.controls['taxPercentage'].setValidators([Validators.min(1)]);
    //    }
    //    this.formedit.controls["taxPercentage"].updateValueAndValidity();
    //  }
    //);
    ////
    ////subscribe taxvaluetype change
    //this.subRef$ = taxValueType.valueChanges.subscribe(
    //  (codeSelect) => {
    //    if (codeSelect == "N") {
    //      taxValue.disable();
    //      this.formedit.controls['taxValue'].setValue(0);
    //      this.formedit.controls['taxValue'].setValidators([Validators.min(0)]);
    //    }
    //    else {
    //      taxValue.enable();
    //      this.formedit.controls['taxValue'].setValidators([Validators.min(1)]);
    //    }
    //    this.formedit.controls["taxValue"].updateValueAndValidity();
    //  }
    //);
    ////
    //this.formedit.controls['taxPercentageType'].setValue("N");
    //this.formedit.controls['taxValueType'].setValue("N");
    this.measurementUnits = [];
    this.datatableservice.getDataList("MEASU").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.measurementUnits.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //group
    this.groups = [];
    this.pasgroupservice.GetGroups(0).subscribe(results => {
      for (const entry of results.body.resultData) {
        this.groups.push(entry);
      }
    }, (e) => {
      console.log(e);
    });
    //composition classes
    this.compositionClasses = [];
    this.datatableservice.getDataList("CMPCL").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.compositionClasses.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    ////tax percentage classes
    //this.taxPercentageTypes = [];
    //this.datatableservice.getDataList("TXPRC").subscribe(results => {
    //  if (results.ok) {
    //    for (const entry of results.body) {
    //      this.taxPercentageTypes.push(entry);
    //    }
    //  }
    //}, (e) => {
    //  console.log(e);
    //});
    ////tax percentage values
    //this.taxValueTypes = [];
    //this.datatableservice.getDataList("TXVAL").subscribe(results => {
    //  if (results.ok) {
    //    for (const entry of results.body) {
    //      this.taxValueTypes.push(entry);
    //    }
    //  }
    //}, (e) => {
    //  console.log(e);
    //});
    //PAS statuses
    this.statuses = [];
    this.datatableservice.getDataList("PASST").subscribe(results => {
      if (results.ok) {
        for (const entry of results.body) {
          this.statuses.push(entry);
        }
      }
    }, (e) => {
      console.log(e);
    });
    //PAS Taxes
    this.DataGrid = [];
    this.taxesservice.getPASTaxes().subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.DataGrid.push(entry);
        }
        this.taxList = new MatTableDataSource<PASTaxes>(this.DataGrid);
        this._TaxRegs = this.taxList.data.length;
      }
    }, (e) => {
      console.log(e);
    });
    //
    this.element = document.getElementById("code") as HTMLElement;
    this.mensaje = "";
  }


  Cancel() {
    this.dialogRef.close();
  }

  Save() {
    try {
      let _MinPrice: Number = this.formedit.value.cost + (this.formedit.value.cost*(this.formedit.value.profitPercent/100));
      const _Taxes: putPASTaxes[] = [];
      for (const entry of this.taxList.data) {
        if (entry["selected"] == true) {
          if (entry["valuetype"] == "V" && entry["value"] <= 0) {
            this.openSB("Error en el impuesto " + entry["name"]);
            return;
          }
          if (entry["tovalue"] == "P" || entry["tovalue"]=="A") {
            _MinPrice = _MinPrice + (entry["valuetype"] == "V" ? entry["value"] : (this.formedit.value.cost * (entry["value"] / 100)));
          }
        }
      }
      if (_MinPrice > this.formedit.value.price) {
        this.openSB("El precio mínimo debe ser " +_MinPrice.toString());
        return;
      }
      const IReg: IPutCCPAS =
      {
        code: this.formedit.value.code.trim(),
        name: this.formedit.value.name.trim(),
        type: "P",
        measurementUnit: this.formedit.value.measurementUnit,
        measurementQuantity: this.formedit.value.measurementQuantity,
        presentation: "",
        brand: this.formedit.value.brand.trim(),
        groupId: this.formedit.value.group,
        observation: "",
        perishable: false,
        compositionClass: this.formedit.value.compositionClass,
        supervisedBilling: false,
        order: 0,
        taxPercentageType: "",
        taxPercentage:0,
        taxValueType: "",
        taxValue:0,
        cost: this.formedit.value.cost,
        price: this.formedit.value.price,
        status: this.formedit.value.status,
        stock: 0,
        profitPercent: this.formedit.value.profitPercent,
minProfitPercent:this.formedit.value.minProfitPercent
      };
      this.passervice.addPAS(IReg).subscribe(result => {
        if (result.body.isValid) {
          for (const entry of this.taxList.data) {
            if (entry["selected"] == true) {
              _Taxes.push({
                id: entry["id"], pasId: result.body.resultData.id, taxesId: entry["id"], value: entry["value"]
              });
            }
          }
          if (_Taxes != null) {
            this.passervice.putTaxes(_Taxes).subscribe(_restax => {
              if (!_restax.body.isValid) {
                alert(_restax.body.error.description);
                return;
              }
            });
          }
          this.openSB("PAS grabado");
          this.dialogRef.close(true);
        }
        else {
          alert(result.body.error.description);
        }
      },
        (e) => {
          this.mensaje = e.error;
          console.log(e);
        }

      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  onToggle(event,pIndex) {
    this.taxList.data[pIndex]["selected"] = event.checked; //..data.map(t => t.value).find.reduce((acc, value) => acc + value, 0);
  }

  onValueChange(event, pIndex) {
    try {
      this.taxList.data[pIndex]["value"] =parseFloat( event.target.value); //..data.map(t => t.value).find.reduce((acc, value) => acc + value, 0);
    }
    catch (e) {
      alert("Error al modificar el PAS: ");
    }
    return;
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  ngOnDestroy(): void {
    this.subRef$.unsubscribe();
  }
}
