import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GRAd, GRAdList, IAddAd } from '../app/Interfaces/iad';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class AdService {
  Id: number;
  CCId: number;
  App: string;
  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  putValues(p_Id:number,p_CCId: number, p_App: string) {
    this.Id = p_Id;
    this.CCId = p_CCId;
    this.App = p_App;
  }

  getAdList(p_CCId: number, p_App: string) {
    try {
      let url = this.baseUrl + 'api/advertising/v1/list/';
      return this.dataService.get<GRAdList>(url + p_CCId + "/" + p_App )
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  getAd(p_Id: number) {
    try {
      let url = this.baseUrl + 'api/advertising/v1/';
      return this.dataService.get<GRAd>(url + p_Id )
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  AddAd(p_ad:IAddAd) {
    try {
      let url = this.baseUrl + 'api/advertising/v1/';
      return this.dataService.post<IgeneralResponse03>(url, p_ad)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  DeleteAd(p_Id: number) {
    try {
      let url = this.baseUrl + 'api/advertising/v1/';
      return this.dataService.delete<IgeneralResponse03>(url+p_Id)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }
}
