import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CcService } from '../../../../services/cc.service';
import { DataService } from '../../../../services/data.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { PrdLocationService } from '../../../../services/prdLocation.service';
import { RouterService } from '../../../../services/router.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { Icc } from '../../../Interfaces/icc';
import { IlocationslistResponse, locationslist } from '../../../Interfaces/ilocation';
import { IuserPermissionsResponse } from '../../../Interfaces/iuser';
import { AddLocationProductionComponent } from '../Production/addlocationproduction.component';
import { EditLocationProductionComponent } from '../Production/editlocationproduction.component';

@Component({
  selector: 'app-prdlocationlist',
  templateUrl: './prdlocationlist.component.html'
})

export class PrdLocationsListComponent implements OnInit {

  formReport: FormGroup;
  subRef$: Subscription;
  r_gridregs: 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  Columns: string[] = [];
  DataGrid: IlocationslistResponse[] = [];
  dataList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  menuGList = new MatTableDataSource;
  filtermenu = { type: 'G' };
  _currentId: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private routeparent: RouterService,
    //private locationservice: LocationService,
private prdLocationService: PrdLocationService,
    private permissionsService: PermissionsService,
    private ccService: CcService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.formReport = formBuilder.group({
      cc_id: [null, Validators.required]
    });
    //this.Columns = ['code', 'name', 'statusname', 'cccode', 'b_menu'];
    this.Columns = ['code', 'name', 'statusname',  'b_menu'];
  }
  selectedvalue: number; selectedview: string;
  cc: Icc[];


  ngOnInit() {
    this.mensaje = "";
    //permisos
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    this.permissionsService.getPermission("PRDLOCATIONS").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
    this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
    this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
    //cc
    this.cc = [];
    this.ccService.getCCList().subscribe(results => {
      for (const entry of results.body) {
        this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
      }
    }, (e) => {
      console.log(e);
    });
  }


  filter() {
    //location
    const localist: locationslist = {
      cc_id: this.formReport.value.cc_id,
      type: "L"
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    //const url = environment.urlAPI + 'api/location/list/';
    this.prdLocationService.List(localist).subscribe(results => {
      if (! results.body.isValid)
        this.openSB(results.body.error.description);
      for (const entry of results.body.resultData) {
        //this.addData({ id: entry.id, code: entry.code, name: entry.name, status: entry.status, statusname: entry.statusname, cccode: entry.cccode });
        this.addData({ id: entry.id, code: entry.code, name: entry.name, status: entry.status, statusname: entry.statusname});
      }
      this.dataList = new MatTableDataSource<IlocationslistResponse>(this.DataGrid);
      this.r_gridregs = this.dataList.data.length;
    },
      (e) => {
        this.openSB(e.error);
        console.log(e);
      });
  }


  addData(data) {
    this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        this.menuPop.push(permission);
        break;
    }
  }

  addCc(V_cc) {
    try {
      this.cc.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }

  b_CurrentId(p_Id: number) {
    this._currentId = p_Id;
    //    this.appcustomerlistservice.putValues(this.formFilter.value.name, this.formFilter.value.idNumber, this.formFilter.value.status, this.paginator.pageIndex);
  }


  b_Production() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    let dialogRef = null;
    this.prdLocationService.IsProduction(this.prdLocationService.locationID).subscribe(result => {
      if (result.body.isValid) {
        dialogRef = this.dialog.open(EditLocationProductionComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
            if (data) {
              console.log("grabado");
            }
            else {
              console.log('Undefined data.');
            }
          }, (e) => console.log(e)
        );
      }
      else {
        dialogRef = this.dialog.open(AddLocationProductionComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
            if (data) {
              console.log("grabado");
            }
            else {
              console.log('Undefined data.');
            }
          }, (e) => console.log(e)
        );
      }
    })
  }

  //execute menu options
  b_ExecutePop(PFunctionCode: string, PPrmCode: string, PLink: string) {
    //this.routeparent.putRouter("customerlist");
    var _Location = this.DataGrid.find(x => x.id == this._currentId);
    this.prdLocationService.putValues(this._currentId, _Location.name);
    switch (PPrmCode) {
      case "P":
        this.b_Production();
        break;
    }
  }

  b_add(id, name) {
    this.routeparent.putRouter("locationslist");
    this.prdLocationService.putValues(id, name)
    this.router.navigate(['locationadd']);

  }



  b_gen(link, id) {
    this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate(['data']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
