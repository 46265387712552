/// <reference path="../../../services/data.service.ts" />
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { PasService } from '../../../services/pas.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { Icc } from '../../Interfaces/icc';
import { grOrders } from '../../Interfaces/igeneralresponse02';
import { getOrder, iOrderCC } from '../../Interfaces/iorder';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html'
})

export class OrdersComponent implements OnInit {

  filterForm: FormGroup;
  subRef$: Subscription;

  r_gridregs: 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  vp_OrderDate :string = "";
  Columns: string[] = [];
  DataGrid:iOrderCC[] = [];
  dataList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private companyservice: CompanyService,
    private routeparent: RouterService,
    private passervice: PasService,
    private _snackBar: MatSnackBar
  ) {
    this.filterForm = formBuilder.group({
      cc_id: [null, Validators.required],
      name: [''],
      stockstatus:[1]
    });
    this.Columns = ['groupName', 'pasCode', 'pasNameUm', 'stock', 'providerName', 'cost', 'taxes', 'orderQuantity', 'subTotal'];
    //'pasId', 'taxPercent', 'taxValue', 'stockMin', 'stockMax', 'supplierId', 'deliveryTerm'];
  }
  selectedvalue: number; selectedview: string;
  cc: Icc[];


  ngOnInit() {
    this.mensaje = "";
    //permisos
    let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.MenuG = [];
    this.dataService.get<IuserPermissionsResponse[]>(url + "ORDERS").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    },
      (e) => {
        this.mensaje  =  e.error;
        console.log(e);
      });
    //cc
    url = environment.urlAPI + 'api/cc/ccuser/';
    this.cc = []; 
    this.dataService.get<Icc[]>(url + "0").subscribe(results => {
      for (const entry of results.body) {
        this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
      }
    }, (e) => {
      console.log(e);
    });
  }


  Filter() {
    const sl: getOrder = {
      ccId: this.filterForm.value.cc_id,
      date: new Date(),
      pasId:0
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    const url = environment.urlAPI + 'api/inventory/v1/orderscc/';
    this.dataService.post<grOrders>(url,sl).subscribe(results => {
      if (!results.ok) {
        this.openSB("No hay datos");
        this.mensaje = "No hay datos";
      }
      if (results.body.isValid) {
        this.vp_OrderDate = results.body.resultData[0].date.toString().substr(0,10);
        for (const entry of results.body.resultData) {
          this.addData(entry);
          //{
          //  id=entry.id, groupName=entry.groupName, pasId=entry.pasId, pasCode=entry.pasCode, pasNameUm=entry.pasNameUm,
          //  cost:entry.cost, taxpercecc_id: entry.cc_id, code: entry.code, name: entry.name, presentation: entry.presentation, groupname: entry.groupname, stock: entry.stock, cccode: entry.cccode
          //});
        }
        this.dataList = new MatTableDataSource<iOrderCC>(this.DataGrid);
        this.dataList.paginator = this.paginator;
        this.r_gridregs = this.dataList.data.length;
      }
      else {
        this.openSB(results.body.error.description);
        this.mensaje = results.body.error.description;
      }
    },
      (e) => {
        this.openSB(e.error);
        this.mensaje = "No hay datos";
        console.log(e);
      });
  }


  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
          this.MenuG.push(permission);
    }
    else {
      switch (permission.code) {
        case "M": {
          this.Columns.push("b_mod");
          break;
        }
      }
    }
  }

  addCc(V_cc) {
    try {
      this.cc.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }

  b_mod(pasId, pasNameUm, taxPercent, taxValue, stock, stockMin, stockMax, orderQuantity,supplierId,providerName,deliveryTerm) {
    this.routeparent.putRouter("paslist");
    this.passervice.putValues(pasId, pasNameUm)
    this.router.navigate(['/pasdetail']);
  }

  b_back() {
    this.router.navigate(['inventory']);
  }


  b_gen(link, id) {
        this.router.navigate([link]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }
  getTotalOrder() {
    return this.dataList.data.map(t => t.subTotal).reduce((acc, subTotal) => acc +subTotal, 0);
    //let total = 0;
    //for (let i = 0; i < this.dataSourceVBFPG.length; i++) {
    //  total = total + parseInt(this.dataSourceVBFPG[i].value);
    //}
    //return total;
  }

}
