/// <reference path="../../../services/data.service.ts" />
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { UserService } from '../../../services/user.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IcompanyusersResponse } from '../../Interfaces/icompany';
import { IResponse } from '../../Interfaces/iresponse';
import { Ipassword, IuserPermissionsResponse } from '../../Interfaces/iuser';

@Component({
  selector: 'app-myuser',
  templateUrl: './myuser.component.html',
  styleUrls: ['./myuser.component.css']
})

export class MyUserComponent implements OnInit {


  subRef$: Subscription;
  r_usersregs: 0;
  public companyId: string;
  companyname: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  Users: IcompanyusersResponse[] = [];
  usersList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;
  filtermenu = { type: 'G' };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private companyservice: CompanyService,
    private routeparent: RouterService,
    private userservice: UserService,
    private _snackBar: MatSnackBar
  ) {
    this.Columns = ['name','username', 'email1','sysstatusname'];
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    this.companyname = this.companyservice.companyName;
    //permisos
    let url = environment.urlAPI + 'api/user/permission/';
    this.routeparent.putRouter("myuser")
    this.Permissions = [];
    this.MenuG = [];
    this.dataService.get<IuserPermissionsResponse[]>(url + "USER").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      //this.addPermission({ id: -1, function_code: "ATRAS", code: "ATRAS", description: "ATRAS", type: "G", icon: "arrow_back_ios", link: this.routeparent.routerParent });
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    },
      (e) => {
        this.mensaje  =  e.error;
        console.log(e);
      });
    //usuario
    this.Users= [];
    this.r_usersregs = 0;
    url = environment.urlAPI + 'api/company/myuser/';
    this.dataService.get<IcompanyusersResponse[]>(url).subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No hay datos";
      for (const entry of results.body) {
        this.addUser({ id: entry.id,idparent:entry.idparent, name: entry.name, idtypename: entry.idtypename, email1: entry.email1,sysstatus:entry.sysstatus, sysstatusname:entry.sysstatusname, myuser:entry.myuser,username:entry.username});
      }
      this.usersList = new MatTableDataSource<IcompanyusersResponse>(this.Users);
      this.r_usersregs = this.usersList.data.length;
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  addUser(user) {
      this.Users.push(user);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
      //switch (permission.code) {
      //  case "A": {
      //    if (this.companyservice.userList)
      //      this.MenuG.push(permission);
      //    break;
      //  }
      //  default: {
      //    this.MenuG.push(permission);
      //    break;
      //  }
      //}
    }
    else {
      switch (permission.code) {
        case "M": {
          this.Columns.push("b_mod");
          break;
        }
        case "S": 
        case "O": {
          var v_mod = this.Columns.find(x => x == "b_psw");
          if (v_mod == null)
            this.Columns.push("b_psw");
          break;
        }
        case "P": {
           this.Columns.push("b_prm");
           break;
        }

      }
    }
  }

  b_mod(id,name) {
    this.userservice.putValues(id,name)
    this.router.navigate(['/useredit']);
  }

  b_prm(id, name) {
    this.userservice.putValues(id, name)
    this.router.navigate(['/prmedit']);
  }

  b_psw(id, mysuer, name,username) {
    try {
      if (mysuer) {
        this.userservice.putValues(id,name);
        this.router.navigate(["/pswedit"]);
        //alert("modificar pssw");
      }
      else {
        var v_mod = this.Permissions.find(x => x.code == "O");
        if (v_mod != null) {
          var restaurar = confirm("Restaurar la contraseña para " + name + " ?");
          if (restaurar) {
            const ip: Ipassword = {
              id: id,
              password: username
            };
            let url = environment.urlAPI + 'api/user/password';
            this.dataService.put<IResponse>(url, ip).subscribe(results => {
              if (results.ok) {
                this.openSB(results.body.response);
              }
              else {
                alert(results.body.response);
              }
            },
              (e) => {
                alert(e.error);
              });
          }
        }
        else
          alert("No tiene permitido la restauracion de contraseñas")
      }
    }
    catch (e) {
      alert (e.message)
    }
  }

  b_gen(link, id) {
    //switch (link)
    //{
    //  case "useradd":
        this.router.navigate([link]);
    //    break;
   // }
  }

  b_back() {
    this.router.navigate(['home']);
  }


  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
