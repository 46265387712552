import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CcService } from '../../../../services/cc.service';
import { CountService } from '../../../../services/count.service';
import { DataService } from '../../../../services/data.service';
import { RouterService } from '../../../../services/router.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { countpending, differences, differencesResponse } from '../../../Interfaces/icount';
import { IgeneralResponse02, IgeneralResponse03 } from '../../../Interfaces/igeneralresponse02';
import { IuserPermissionsResponse } from '../../../Interfaces/iuser';


@Component({
  selector: 'app-diffreport',
  templateUrl: './diffreport.component.html',
  styleUrls: ['./diffreport.component.css']
})

export class DiffreportComponent implements OnInit, AfterViewInit {
  formReport: FormGroup;
  subRef$: Subscription;
  r_gridregs:number= 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  mensaje_info: string = "";
  DataGrid:differencesResponse[] = [];
  dataList = new MatTableDataSource;
  Columns: string[] = [];
  Permissions: IuserPermissionsResponse[] = [];
  differences: differences;
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  countid: number = 0;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private ccService: CcService,
    private countService: CountService,
    private securityService: SecurityService,
    private routeparent: RouterService,
    private _snackBar: MatSnackBar
  ) {
    this.formReport = formBuilder.group({
      code: [""]
    });
    this.Columns = ['code','nameunit','price','cost','averagecost','stock','quantity','difference','pricediff','costdiff','averagecostdiff'];
  }
    changes: Subject<void>;
    itemsPerPageLabel: string;
    nextPageLabel: string;
    previousPageLabel: string;
    firstPageLabel: string;
    lastPageLabel: string;
    getRangeLabel: (page: number, pageSize: number, length: number) => string;

  selectedvalue: number; selectedview: string;

  ngOnInit() {
    //permisos
    this.countid = this.countService.countingID;
    let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.MenuG = [];
    this.dataService.get<IuserPermissionsResponse[]>(url + "COUNT-SC").subscribe(results => {
      if (results.body.length == 0) {
        this.mensaje = "No tiene permisos";
        this.mensaje_info = "";
      }
      for (const entry of results.body) {
        //this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    },
      (e) => {
        this.mensaje = e.error;
        this.mensaje_info = "";
        console.log(e);
      });
    //to save pending count
    const cp: countpending = {
      cc_id: this.ccService.ccID,
      counting_id: this.countService.countingID,
      user_id:0
    };
    url = environment.urlAPI + 'api/count/pending/';
    this.dataService.post<IgeneralResponse03>(url ,cp).subscribe(results => {
      if ( results.body.isValid) {
        this.mensaje = "Conteo actualizado";
      }
    },
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
        console.log(e);
      });

  }

   

  filter() {
    const diff:differences = {
      pascode: this.formReport.value.code,
      counting_id: this.countService.countingID
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.dataList.paginator = this.paginator;
    this.dataList = null;

    let url = environment.urlAPI + 'api/count/differences/';
    this.dataService.post<differencesResponse[]>(url,diff).subscribe(results => {
      if (results.body.length == 0)
        this.openSB("No hay datos");
      for (const entry of results.body) {
        this.addData({ id: entry.id, pas_id:entry.pas_id,code: entry.code.trim(), nameunit: entry.nameunit, price: entry.price, cost: entry.cost,averagecost:entry.averagecost, stock:entry.stock,quantity: entry.quantity,difference:entry.difference,pricediff: entry.pricediff,costdiff:entry.costdiff,averagecostdiff:entry.averagecostdiff });
      }
      this.dataList = new MatTableDataSource<differencesResponse>(this.DataGrid);
      this.r_gridregs = this.DataGrid.length;

    },
      (e) => {
        this.openSB(e.error);
        console.log(e);
      });

  }

  addData(data) {
    this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
          this.MenuG.push(permission);
    }
    else {
    }
  }



  b_gen(link, id) {
        this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate(['locationselect']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formReport.get(nombreControl);
    return control.hasError(validacion);
  }

  getTotalstock() {
    return this.DataGrid.map(t => t.stock).reduce((acc, stock) => acc + stock, 0);
  }

  getTotalquantity() {
    //return this.dataList.data.map(t => t.quantity).reduce((acc, quantity) => acc + quantity, 0);
    return this.DataGrid.map(t => t.quantity).reduce((acc, quantity) => acc + quantity, 0);

  }

  getTotaldifference() {
    return this.DataGrid.map(t => t.difference).reduce((acc,difference) => acc +difference, 0);
  }

  getTotalpricediff() {
    return this.DataGrid.map(t => t.pricediff).reduce((acc, pricediff) => acc +pricediff, 0);
  }

  getTotalcostdiff() {
    return this.DataGrid.map(t => t.costdiff).reduce((acc, costdiff) => acc + costdiff, 0);
  }
  getTotalaveragecostdiff() {
    //return this.DataGrid.data.map(t => t.averagecostdiff).reduce((acc, averagecostdiff) => acc + averagecostdiff, 0);

    return this.DataGrid.map(t => t.averagecostdiff).reduce((acc,averagecostdiff) => acc +averagecostdiff, 0);
  }


}
