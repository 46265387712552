import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../../../services/company.service';
import { CustomerService } from '../../../../services/customer.service';
import { DataService } from '../../../../services/data.service';
import { RegistryService } from '../../../../services/registry.service';
import { copyCustomer, IcustomerlistResponse } from '../../../Interfaces/icustomer';
import { iRegistryActivate } from '../../../Interfaces/iregistry';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html'
})
export class ActivateComponent implements OnInit {
  private sub: Subscription;
  formActivate: FormGroup;
  private _registryActivate: iRegistryActivate;
  private dataService: DataService;
  private mensaje: string="";
  protected providers: IcustomerlistResponse[];

  constructor(private dialogRef: MatDialogRef<ActivateComponent>,
    private customerService: CustomerService,
    private registryService: RegistryService,
    private companyService:CompanyService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
)
  {
    this.formActivate = formBuilder.group({
      invoiceNumber: [0, Validators.required],
      providerId: [0, Validators.required],
    });
  }

  ngOnInit(): void { this.loadContent(); }

loadContent(){
  this.providers = [];
  this._registryActivate = { id: this.registryService.id, invoiceNumber:0,customerId:this.customerService.customerID, providerId: this.registryService.providerId };
  this.formActivate.controls['invoiceNumber'].setValue("");
  this.formActivate.controls['providerId'].setValue(this.registryService.providerId);
  this.customerService.getCustomerList().subscribe(response => {
    if (!response.body.isValid)
      console.log( response.body.error.description);
    else {
      for (const entry of response.body.resultData) {
        this.addProvider({ id: entry.id, idparent: entry.idparent, name: entry.name, idtypename: entry.idtypename, email1: entry.email1, branchoffice: entry.branchoffice });
      }
    }
  },
    (e) => {
      console.log(e);
    });
  }

  addProvider(vp_provider) {
    try {
      this.providers.push(vp_provider);
    } catch (e) { console.log(e); }
    }

  closeWithoutSave() {
    this.dialogRef.close();
  }

  Save() {
    try {
      const _RA:iRegistryActivate = {
        id: this.registryService.id,
        invoiceNumber: this.formActivate.value.invoiceNumber,
customerId:this.customerService.customerID,
        providerId: this.formActivate.value.providerId
      };
      this.registryService.Activate(_RA).subscribe(results => {
        if (results.body.isValid) {
          //check if the provider have company profile
          this.companyService.CheckbyID(this.formActivate.value.providerid).subscribe(results => {
            if (results.body.isValid) {
              const _Copy: copyCustomer = {
                companyId: this.formActivate.value.providerId,
                customerId: this.customerService.customerID
              }
              this.customerService.Copy(_Copy).subscribe(results => {
                if (results.body.isValid) {

                }
              })
            }
          })
          this.openSB(results.body.resultData.description);
          this.dialogRef.close(true);
        }
        else { alert(results.body.error.description); }
      },
        (e) => {
          this.mensaje = e.error;
          alert(this.mensaje);
          console.log(e);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
