import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { putUserPermissions } from '../app/Interfaces/ifunction';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { AddUserPermissions, IuserPermissionsResponse } from '../app/Interfaces/iuser';
import { environment } from '../environments/environment';
import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  PutByUser(PPrm: putUserPermissions) {
    try {
      let url = this.baseUrl + 'api/user/v2/permissions';
      return this.dataService.put<IgeneralResponse03>(url, PPrm).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //PutBYUser2(PPrm: putUserPermissions, PReq: any) {
  //  PReq = function (PPrm: putUserPermissions) {
  //    try {
  //      let url = this.baseUrl + 'api/user/v2/permissions';
  //      return new Promise(async (resolve, reject) => {
  //        const result =await fetch(this.dataService.put<IgeneralResponse03>(url, PPrm));
  //      })
  //    }
  //    catch (e) {
  //      console.log(e);
  //    }
  //  }
  //}

  getPermission(p_Function: string) {
    try {
      let url = this.baseUrl + "api/user/permission/";
      return this.dataService.get<IuserPermissionsResponse[]>(url+p_Function).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  putByFunction(PPrm: AddUserPermissions) {
    try {
      //console.log(PPrm);
      let url = this.baseUrl + "api/permission/v1/byfunction/";
      return this.dataService.put<IgeneralResponse03>(url, PPrm).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  putByCode(PPrm: AddUserPermissions) {
    try {
      console.log(PPrm);
      let url = this.baseUrl + "api/permission/v1/bycode/";
      return this.dataService.put<IgeneralResponse03>(url, PPrm).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }





}
