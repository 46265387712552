import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Imenu01Response } from '../app/Interfaces/imenu01';
import { environment } from '../environments/environment';
import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class MenuService {
  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  getOptionsLevel2(pUserId:number, pFunction: string) {
    try {
      let url = this.baseUrl + "api/menu/n2/";
      return this.dataService.get<Imenu01Response[]>(url+pUserId+"/"+pFunction).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

}
